import { useState } from 'react';
import { FormControl, IconButton, Box } from '@mui/joy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import { ILoginForm } from '../../types/ProvideAuthType';
import { IOnboardingCreate } from '../../pages/OnboardingCreate';

interface PasswordInputProps {
  label: string;
  placeholder: string;
  register: any;
  name: keyof ILoginForm | keyof IOnboardingCreate;
  error?: string;
  dataTestId?: string;
}

const PasswordFormInput: React.FC<PasswordInputProps> = ({
  label,
  placeholder,
  register,
  name,
  error,
  dataTestId
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <FormTextInput
          dataTestId={dataTestId}
          label={label}
          placeholder={placeholder}
          register={register}
          name={name}
          error={error}
          type={showPassword ? 'text' : 'password'}
          sx={{
            '&.MuiInput-input, & .css-1gw9vc6-JoyInput-input': {
              outline: 'none',
              boxShadow: 'none !important'
            }
          }}
        />
        <IconButton
          onClick={handleClickShowPassword}
          sx={{
            position: 'absolute',
            right: 8,
            top: error ? '38%' : '47%',
            backgroundColor: 'transparent',
            color: 'inherit',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Box>
    </FormControl>
  );
};

export default PasswordFormInput;
