import { useMemo } from 'react';
import { CopyRightAction } from '../../../constants/common';
import { useGetLegalStatement } from '../../../hooks/useGetLegalStatement/useGetLegalStatment';
import { CircularProgress, Stack } from '@mui/joy';

const LegalStatement = ({ displayedPopup }: { displayedPopup: string }) => {
  const { data, isLoading } = useGetLegalStatement();

  const renderStatementHTML = useMemo(() => {
    switch (displayedPopup) {
      case CopyRightAction.Terms:
        return data?.SettingsPage?.TOS?.html;
      case CopyRightAction.PrivacyPolicy:
        return data?.SettingsPage?.PRIVACY?.html;
      default:
        return null;
    }
  }, [displayedPopup, data]);

  if (isLoading || !renderStatementHTML) {
    return (
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ minHeight: '100%', minWidth: '100%' }}
      >
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <div
      className="no-scrollbar mt-5 font-normal text-sm text-stone-850 text-justify px-5"
      dangerouslySetInnerHTML={{
        __html: renderStatementHTML as unknown as string
      }}
    />
  );
};

export default LegalStatement;
