import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Banner from '../../components/Banner';
import ProgressBanner from '../../components/ProgressBanner';
import { getProgressBanner } from '../../config/progressBanner';

const BusinessDescriptionLayout: React.FC = (): JSX.Element => {
  const { t } = useTranslation([
    'businessDescription',
    'businessInformation',
    'paymentInformation',
    'additionalInformation'
  ]);
  return (
    <div className="overflow-x-hidden">
      <Banner label={t('titleLabel')} detail={t('descriptionLabel')} />
      <div className="mx-5 lg:mx-20 xl:mx-38.5 h-max overflow-x-hidden">
        <div className="flex sm:flex-row sm:gap-5 gap-1 h-full w-full my-40">
          <ProgressBanner progressBanner={getProgressBanner(t)} />
          <div className="ml-71.5 mr-10 mb-20 fixed flex h-2/3  border border-solid border-gray-350 rounded-2.5xl overflow-y-auto overflow-x-hidden">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDescriptionLayout;
