import React, { FC } from 'react';
import classNames from 'classnames';
import { NavigationTab, NestedNavigationTab } from '../../types';
import { Link } from 'react-router-dom';

interface Props {
  allTabs: NestedNavigationTab[] | NavigationTab[];
  selectedTab: string;
  className?: string;
}

const VerticalNavBar: FC<Props> = ({ allTabs, selectedTab, className = '' }) => {
  return (
    <div
      className={classNames(
        'flex flex-col w-full pl-5 lg:pl-20 xl:pl-20 pr-2 lg:pr-10 xl:pr-30',
        className
      )}
    >
      {allTabs.map(({ label, route }, index) => (
        <Link
          to={route}
          key={label}
          className={classNames(
            'font-semibold text-1.8sm tracking-normal pl-0 text-left',
            index === 0 ? 'mt-12.5' : 'mt-5',
            label === selectedTab ? 'text-stone-850' : 'text-gray-650'
          )}
          data-testid={label}
        >
          {label}
        </Link>
      ))}
    </div>
  );
};

export default VerticalNavBar;
