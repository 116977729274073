import { get } from 'lodash';
import { SetStateAction } from 'react';
import { ZIP_CODE_SUFFIX_REGEX } from '../constants/regex';
import {
  AdditionalInformationType,
  BusinessInformationType,
  DropdownOption,
  EditAdditionalInformationType,
  HostAdditionalInformationType,
  HostPersonalInformationType,
  PaymentInformationType,
  State
} from '../types';
import { HostEditPersonalInformationType } from './../types/hostPersonalInformation';
import { getFormattedOffset } from './timezone';
import { getDefaultState } from '../config/dropdownOptions/dropdown';
import { TFunction } from 'i18next';

export const flipState = () => (prevState: boolean) => !prevState;

export const checkAnyEmptyFieldsPresent = (
  responses:
    | BusinessInformationType
    | PaymentInformationType
    | AdditionalInformationType
    | HostPersonalInformationType
    | HostAdditionalInformationType
    | EditAdditionalInformationType
    | HostEditPersonalInformationType,
  mandatoryFieldKeys?: string[]
) => {
  return Object.entries(responses).some((eachResponse) => {
    if (mandatoryFieldKeys && mandatoryFieldKeys.includes(eachResponse[0])) {
      return !eachResponse[1];
    }
    return false;
  });
};

export const getDefaultFieldDropdown = (options: DropdownOption[]) => {
  const defaultFieldItem = options.find((eachOption) => eachOption.isDefault === true);
  return defaultFieldItem ? defaultFieldItem : options[0];
};

export const updateSelectedKey =
  <T>(selectedKey: string, updatedValue: T) =>
  (prevState: SetStateAction<Record<string, T>>) => {
    const updatedState = { ...prevState };
    updatedState[selectedKey] = updatedValue;
    return updatedState;
  };

export const getDropdownOptionSelectedValue = (
  options: DropdownOption[],
  selectedValue: string
) => {
  const selectedFieldItem = options.find((eachOption) => eachOption.value === selectedValue);
  return selectedFieldItem ? selectedFieldItem : options[0];
};

export const getDropdownOptionSelectedName = (
  options: unknown[],
  selectedValue: string,
  key: string,
  prefixKey?: string
) => {
  const selectedFieldItem = options.find((eachOption) =>
    prefixKey
      ? `${getFormattedOffset(get(eachOption, prefixKey))} ${get(eachOption, key)}` ===
        selectedValue
      : get(eachOption, key) === selectedValue
  );
  return selectedFieldItem ? selectedFieldItem : options[0];
};

export const checkIsValidLongZipCode = (zipCodeValue: string) => {
  let suffixZipCodeValue = '';
  if (zipCodeValue.length !== 5) {
    suffixZipCodeValue = zipCodeValue.substring(5, 10);
  }
  if (suffixZipCodeValue !== '' && !ZIP_CODE_SUFFIX_REGEX.test(suffixZipCodeValue)) {
    return false;
  }
  return true;
};

export const checkIsZipCodeInValidRange = (
  zipCodeValue: string,
  selectedDropdownField: State,
  defaultStatName: string
) => {
  const stateName = selectedDropdownField.stateName;
  if (stateName !== defaultStatName) {
    if (zipCodeValue.length !== 5) {
      zipCodeValue = zipCodeValue.substring(0, 5);
    }
    const minZipCodeValue = selectedDropdownField.zipCodeRangeStart;
    const maxZipCodeValue = selectedDropdownField.zipCodeRangeEnd;
    const enteredZipCodeValue = parseInt(zipCodeValue, 10);
    if (
      minZipCodeValue &&
      maxZipCodeValue &&
      minZipCodeValue !== null &&
      maxZipCodeValue !== null &&
      (enteredZipCodeValue < minZipCodeValue || enteredZipCodeValue > maxZipCodeValue)
    ) {
      return false;
    }
  }
  return true;
};

export const getDropdownValue = <T>(
  selectedField: T,
  dropdownKey: string,
  dropdownLabelKey?: string
) => {
  if (dropdownLabelKey) {
    return get(selectedField, [dropdownLabelKey]);
  } else if (dropdownKey) {
    return get(selectedField, [dropdownKey]);
  }
  return selectedField;
};

export const getZipCodeError = (selectedState: State, zipCode: string, t: TFunction) => {
  let zipCodeError = '';

  if (!checkIsValidLongZipCode(zipCode)) {
    zipCodeError = t('invalidSuffixZipcodeError');
  } else if (!checkIsZipCodeInValidRange(zipCode, selectedState, getDefaultState(t).stateName)) {
    zipCodeError = t('invalidZipcodeError', {
      minValue: selectedState?.zipCodeRangeStart,
      maxValue: selectedState?.zipCodeRangeEnd,
      stateName: selectedState?.stateName
    });
  }
  return zipCodeError;
};
