import './index.css';
import classNames from 'classnames';
import { noop } from 'lodash';
import { FC, useContext, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SpinnerCircularFixed } from 'spinners-react';
import images from '../../assets';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import { ReactComponent as QuestionMark } from '../../assets/question.svg';
import { ReactComponent as SettingIcon } from '../../assets/setting.svg';
import { NAVIGATION_TABS_HEADER } from '../../config/navigationTabs';
import { DISABLE_HEADER_NAV_ROUTES, ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import { useFetchImage } from '../../hooks/useAsset';
import { ROLE_TYPE } from '../../types';
import Button from '../Button';
import DropdownMenu from '../DropdownMenu';
import Typography from '../Typography';
import { NAVIGATION_TABS } from '../../constants/navigationTabs';
import { useProvideAuth } from '../../hooks/useProvideAuth';
import AgoraRtcClass from '../../utils/AgoraClient';

interface Props {
  isUserLogin: boolean;
}

const infoPopup = (t: TFunction) => {
  return [
    {
      btnClick: noop,
      children: (
        <div className="text-left">
          <Typography variant="p" className="text-1.2md text-left">
            {t('helpLabel')}
          </Typography>
          <div className="text-1.1sm mt-2.5">{t('tellUsAbout')}</div>
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = `mailto:${t('supportEmail')}`;
              e.preventDefault();
            }}
            className="text-1.1sm underline decoration-1"
          >
            {t('supportEmail')}
          </Link>
        </div>
      ),
      disabled: false,
      testId: 'setting-btn'
    }
  ];
};

const accountOptions = (
  t: TFunction,
  buttonClickHandler: (route: string) => void,
  handleLogout: () => void,
  route: string
) => {
  return [
    {
      btnClick: () => buttonClickHandler(route),
      children: (
        <div className="flex flex-row items-center text-left text-sm p-0 pb-0">
          <SettingIcon className="mr-2.5" />
          {t('settingLabel')}
        </div>
      ),
      disabled: false,
      testId: 'setting-btn'
    },
    {
      btnClick: () => handleLogout(),
      children: (
        <div className="flex flex-row items-center text-left text-sm p-0 pt-2.5">
          <LogoutIcon className="mr-2.5" />
          {t('signOutButton')}
        </div>
      ),
      disabled: false,
      testId: 'logout-btn'
    }
  ];
};

const Header: FC<Props> = ({ isUserLogin }) => {
  const { t } = useTranslation(['header']);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { profileIconUrl, roleSpecificId, roleType, isAuthed } = useContext(AuthContext);
  const {
    imageInformationData,
    isFetching: isProfileImageFetching,
    refetch: refetchProfileImage
  } = useFetchImage({
    entityId: roleSpecificId,
    entityType: roleType
  });

  const buttonClickHandler = (selectedRoute: string) => {
    if (pathname !== selectedRoute) {
      navigate(selectedRoute);
    }
  };

  const [isShowNavigationBtn, setShowNavigationBtn] = useState(false);
  const [activeTabId, setActiveTabId] = useState(NAVIGATION_TABS_HEADER(t)[0].label);

  useEffect(() => {
    if (!DISABLE_HEADER_NAV_ROUTES.includes(pathname)) {
      setShowNavigationBtn(true);
    } else {
      setShowNavigationBtn(false);
    }
    if (pathname.includes(ROUTES.STUDIO) || pathname.includes(ROUTES.STORE)) {
      setActiveTabId(NAVIGATION_TABS_HEADER(t)[pathname.includes(ROUTES.STORE) ? 0 : 1].label);
    } else {
      setActiveTabId('');
    }
  }, [pathname]);

  useEffect(() => {
    if (
      roleType &&
      roleType === ROLE_TYPE.SELLER &&
      roleSpecificId &&
      isAuthed &&
      !pathname.includes(ROUTES.LOGIN_CREDENTIALS)
    ) {
      refetchProfileImage();
    }
  }, [profileIconUrl, roleType, roleSpecificId, pathname]);
  const { logout } = useProvideAuth({});
  const handleLogout = async () => {
    const client = AgoraRtcClass.getInstance().rtcClient;
    if (client?.connectionState === 'CONNECTED') {
      await AgoraRtcClass.getInstance().leave();
    }
    logout();
  };

  const getRedirectUrl = () => {
    if (isUserLogin && roleType === ROLE_TYPE.HOST) return ROUTES.LIVE;
    if (isUserLogin && roleType === ROLE_TYPE.SELLER) return ROUTES.PRODUCT;
    return ROUTES.SIGNUP;
  };
  return (
    <div
      className={classNames(
        'md:h-16 bg-stone-850 w-screen',
        isUserLogin && (pathname.includes(ROUTES.STORE) || pathname.includes(ROUTES.STUDIO))
          ? 'h-36'
          : 'h-16'
      )}
    >
      <div className="flex justify-between px-5 lg:px-20 xl:px-38.5 h-16">
        {isShowNavigationBtn ? (
          <Link
            to={getRedirectUrl()}
            className="my-5 mr-23.5 font-normal text-white text-3.2xl tracking-wider"
            data-testid="homeTitle"
            onClick={(e) => {
              if (pathname === getRedirectUrl()) {
                e.preventDefault();
              }
            }}
          >
            <img src={images.title} alt="title-script-mark" className="h-7 w-20"></img>
          </Link>
        ) : (
          <div className="my-5 mr-23.5 font-normal text-white text-3.2xl tracking-wider">
            <img src={images.title} alt="title-script-mark" className="h-7 w-20"></img>
          </div>
        )}
        {isUserLogin &&
          isShowNavigationBtn &&
          NAVIGATION_TABS_HEADER(t).map(({ label, route }, index) => (
            <Button
              key={label}
              data-testid={label}
              variant="text"
              className={classNames(
                'cursor-pointer font-semibold text-left h-5.75 pr-0 py-0 pl-0 my-5',
                index === 0 ? 'ml-6' : 'ml-2 lg:ml-8 xl:ml-10',
                label === activeTabId ? 'primary-underline text-white' : 'text-gray-450',
                roleType === ROLE_TYPE.HOST &&
                  (label === t(NAVIGATION_TABS.STORE) ? 'hidden' : 'xl:ml-2')
              )}
              btnClick={() => {
                buttonClickHandler(route);
              }}
            >
              {label}
            </Button>
          ))}
        <div className="flex ml-auto items-center gap-x-5">
          <DropdownMenu testId="info-btn" options={infoPopup(t)} className="pt-1">
            <QuestionMark />
          </DropdownMenu>
          {isUserLogin && isShowNavigationBtn && (
            <DropdownMenu
              testId="account-popup"
              options={accountOptions(
                t,
                buttonClickHandler,
                handleLogout,
                roleType === ROLE_TYPE.SELLER
                  ? ROUTES.SELLER_ACCOUNT_INFORMATION
                  : ROUTES.HOST_ACCOUNT_INFORMATION
              )}
              className="pt-1"
            >
              <div className="flex flex-col w-10 h-10">
                {isProfileImageFetching ? (
                  <SpinnerCircularFixed
                    size={40}
                    thickness={100}
                    speed={50}
                    color="#111827"
                    data-testid="loader"
                    secondaryColor="transparent"
                  />
                ) : (
                  <img
                    className="w-10 h-full object-cover rounded-full"
                    src={
                      imageInformationData && imageInformationData.length
                        ? imageInformationData[0]?.contentUrl
                        : images.avatar
                    }
                    alt="profile icon"
                    data-testid="profileIcon"
                  />
                )}
              </div>
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
