import React from 'react';
import classNames from 'classnames';
import {
  ELEMENTS_MAP,
  HEADING_SIZES_MAP,
  LABEL_SIZES_MAP,
  PARAGRAPH_SIZES_MAP,
  Size,
  Headings,
  Elements
} from '../../constants/typography';

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'label' | 'span' | 'div';
  children: React.ReactNode;
  size?: string;
  className?: string;
  testId?: string;
}

const Typography: React.FC<HeadingProps> = ({ variant, children, className, size, testId }) => {
  const fontStyle = () => {
    if (Object.values(Headings).includes(variant as unknown as Headings) && !size) {
      return ELEMENTS_MAP['headings'];
    }
    return ELEMENTS_MAP[variant as unknown as Elements];
  };

  const fontSize = () => {
    if (Object.values(Headings).includes(variant as unknown as Headings) && !size) {
      return HEADING_SIZES_MAP[variant as Headings];
    } else if (variant === 'label') {
      return LABEL_SIZES_MAP[(size as Size) || Size.MEDIUM]; // Note: fallback option to set the font size when 'size' option is omitted
    }
    return PARAGRAPH_SIZES_MAP[(size as Size) || Size.MEDIUM];
  };

  return (
    <Heading
      variant={variant}
      className={classNames(className, fontStyle(), fontSize())}
      data-testid={testId}
    >
      {children}
    </Heading>
  );
};

const Heading = ({ variant, children, ...props }: HeadingProps) =>
  React.createElement(variant, props, children);

export default Typography;
