import { format } from 'date-fns-tz';
import { DATE_FORMAT } from '../constants/common';

export const getClientTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatTime = (time: Date | string, timeFormat?: string) => {
  return format(time, timeFormat || DATE_FORMAT.DEFAULT_FORMAT, { timeZone: getClientTimezone() });
};

export const convertHour = (time: string | Date, is12HourFormat = false) => {
  const rawDate = typeof time === 'string' ? new Date(`01/01/1970 ${time}`) : time;
  return Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: is12HourFormat ? 'h12' : 'h23'
  }).format(rawDate);
};

export const checkIsDayLightSavingTime = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const januaryDate = new Date(currentYear, 0, 1);
  const julyDate = new Date(currentYear, 6, 1);
  const standardTimezoneOffset = Math.max(
    januaryDate.getTimezoneOffset(),
    julyDate.getTimezoneOffset()
  );
  return currentDate.getTimezoneOffset() < standardTimezoneOffset;
};

export const getFormattedOffset = (offset: number) => {
  const offsetSign = offset >= 0 ? '+' : '-';
  const formattedOffset = Math.abs(offset);
  const offsetNumberPrefix = formattedOffset >= 10 ? '' : '0';
  const offsetPrefix = `(UTC${offsetSign}${offsetNumberPrefix}`;
  const offsetSuffix = ':00)';
  return `${offsetPrefix}${formattedOffset}${offsetSuffix}`;
};
