import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SellerChannel, SellerType } from '../../../suneify/types/Seller';

export interface AdminDataState {
  value: {
    selectedSeller: SellerType | null;
    allSellersData: SellerType[];
    sellerChannels: SellerChannel[];
  };
}

const initialState: AdminDataState = {
  value: {
    selectedSeller: null,
    allSellersData: [],
    sellerChannels: []
  }
};

export const adminDataSlice = createSlice({
  name: 'sellerData',
  initialState,
  reducers: {
    storeSelectedSeller: (state, action: PayloadAction<SellerType>) => {
      const { payload } = action;

      state.value.selectedSeller = payload;
    },
    storeSellersData: (state, action: PayloadAction<SellerType[]>) => {
      const { payload } = action;

      state.value.allSellersData = payload;
    },
    storeSellersChannel: (state, action: PayloadAction<SellerChannel[]>) => {
      const { payload } = action;

      state.value.sellerChannels = payload;
    },
    clearSelectedSeller: (state) => {
      state.value.selectedSeller = null;
    },
    clearSellerChannel: (state) => {
      state.value.sellerChannels = [];
    }
  }
});

export const {
  storeSelectedSeller,
  clearSelectedSeller,
  storeSellersData,
  storeSellersChannel,
  clearSellerChannel
} = adminDataSlice.actions;

export default adminDataSlice.reducer;
