import { VariantType } from '../../types/VariantType';
import generatePermutations from './generatePermutations';

/* istanbul ignore next */
export function openSidebar() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    // eslint-disable-next-line sonarjs/no-duplicate-string
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

/* istanbul ignore next */
export function closeSidebar() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

/* istanbul ignore next */
export function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const generateNewVariants = (variantTypes: VariantType[]) => {
  if (variantTypes.length === 0) return [];

  const perms = generatePermutations(variantTypes);
  return perms.map((permutation) => ({
    options: { ...permutation }
  }));
};

export const createSlug = (text: string) => {
  return text.toLowerCase().trim().replace(/\s+/g, '-');
};

export const arrayToString = (array: Array<string>) => {
  return '[' + array.join(', ') + ']';
};

function dataURLtoBlob(dataUrl: any) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// Convert Blob to File
export const createFileFromBlob = (dataUrl: any, filename: any) => {
  const blob = dataURLtoBlob(dataUrl);
  return new File([blob], filename, { type: 'image/png' });
};

export const generateSKU = () => {
  const SOURCE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * SOURCE.length);
    result += SOURCE.charAt(randomIndex);
  }

  return result;
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...';
  }
  return str;
};

export function stringToInteger(str: string | undefined) {
  if (!str) {
    return undefined;
  }

  const num = parseInt(str, 10);

  if (!isNaN(num) && num.toString() === str.trim()) {
    return num;
  }

  return undefined;
}

/* istanbul ignore next */
// https://medium.com/@trmaphi/lodash-isempty-value-you-might-be-using-it-the-wrong-way-d83210d7decf
export function isEmptyValues(value: any) {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    Object.values(value).filter((singleVal) => singleVal).length === 0 ||
    (typeof value === 'string' && value?.trim()?.length === 0)
  );
}

const partiallyfulfilledText = 'partially fulfilled';

type ObjectKeyValType = {
  [key: string]: string;
};
export const statusPrettfier: ObjectKeyValType = {
  PartiallyFulfilled: partiallyfulfilledText,
  PartiallyShipped: partiallyfulfilledText,
  PartiallyDelieved: partiallyfulfilledText,
  confirmed: 'unfulfilled',
  UnFulfilled: 'unfulfilled',
  fulfilled: 'fulfilled',
  delieved: 'fulfilled',
  cancelled: 'cancelled',
  refunded: 'refunded'
};

export const ChipColorMap: ObjectKeyValType = {
  // seller color
  active: 'success',
  inactive: 'neutral',
  // product & video
  public: 'success',
  hidden: 'neutral',
  unlisted: 'neutral',
  true: 'success',
  false: 'neutral',
  completed: 'success'
};

export const getChipColor = (status?: string | boolean) => {
  return ChipColorMap[`${status?.toString()?.toLowerCase()}`] ?? 'neutral';
};
