import { useQuery } from '@tanstack/react-query';

import { API_ROUTES } from '../../constants/apiRoutes';
import { RouteConfig } from '../../types';
import { Response } from '../../types/APIResponseType';
import { IMetaTag } from '../../types/metaTag';
import { makeRequest } from '../../utils/axios';

export const useMetaTag = (includeInvisibleTags = false) => {
  const loadMetaTagConfig: RouteConfig = {
    path: `${API_ROUTES.META_TAG}${includeInvisibleTags ? '?includeInvisibleTags=true' : ''}`,
    method: 'GET'
  };

  const {
    data,
    isLoading,
    isError,
    refetch: loadMetaTags
  } = useQuery([API_ROUTES.META_TAG], () => makeRequest<Response<IMetaTag[]>>(loadMetaTagConfig), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    select: (response) => response.data
  });

  return {
    data,
    loadMetaTags,
    isLoading,
    isError
  };
};
