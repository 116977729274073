/* istanbul ignore file */
import { extendTheme } from '@mui/joy';
import { darken, lighten } from '@mui/material/styles';

export const variables = {
  buttonBorderRadius: '8px',
  boxBorderRadius: '4px'
};

export const grey = {
  level1: '#E2E2E2',
  level2: '#F6F6F6',
  level3: '#EBEBEB',
  level4: '#607266',
  level5: '#f9f9f9',
  level6: '#8b8b8b',
  level7: '#efefef',
  level8: '#919EAB',
  level9: '#FAFAFA',
  level10: '#D9D9D9'
};

export const green = {
  level1: '#219621'
};
export const colorVariables = {
  lightGrey: grey.level1,
  grey: grey.level2,
  darkGrey: grey.level3,
  black: '#18181C',
  borderColor: grey.level1
};

// https://www.color-hex.com/color/323297
export const colors = {
  primary: '#4646D0',
  success: '#219621',
  warning: '#FFAB00',
  danger: '#FF0000',
  neutral: '#EBEBEB',
  primary10: '#323297',
  dark: '#2F2B27',
  primaryLight: '#EBEBFA',
  grey: '#8B8B8D'
};

declare module '@mui/joy/styles' {
  interface Palette {
    theme: {
      primary: string;
    };
    neutralCustom: { [key: string]: string };
    background: {
      surface: string | undefined;
      primary: string;
      secondary: string;
    };
  }
}

const getColorPalette = (color: string) => {
  return {
    50: lighten(color, 0.95),
    100: lighten(color, 0.9),
    200: lighten(color, 0.7),
    300: lighten(color, 0.4),
    400: lighten(color, 0.2),
    500: color,
    600: darken(color, 0.2),
    700: darken(color, 0.4),
    800: darken(color, 0.6),
    900: darken(color, 0.8)
  };
};
export const customTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: getColorPalette(colors.primary),
        success: getColorPalette(colors.success),
        danger: getColorPalette(colors.danger),
        neutralCustom: getColorPalette(colors.neutral),
        background: {
          primary: colorVariables.grey,
          secondary: colorVariables.darkGrey
        }
      }
    },
    dark: {
      palette: {
        theme: {
          primary: colors.primary
        },

        background: {
          primary: colorVariables.black,
          secondary: colorVariables.darkGrey
        }
      }
    }
  },
  components: {
    JoyChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'success' && {
            backgroundColor: green.level1,
            color: 'white'
          }),
          ...(ownerState.color === 'neutral' && {
            backgroundColor: grey.level1
          })
        })
      }
    }
  },
  fontFamily: {
    display: 'Poppins', // applies to `h1`–`h4`
    body: 'Poppins' // applies to `title-*` and `body-*`
  }
});
