import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { AuthContext } from '../../context/AuthContext';
import { RouteConfig, SellerInformationType } from '../../types';
import { Response, ResponseError } from '../../types/APIResponseType';
import { IHostInfo } from '../../types/host';
import { makeRequest } from '../../utils';
import { useLocalStorage } from '../useLocalStorage';
import { useProvideAuth } from '../useProvideAuth';
import useSellerHook from '../../reduxStore/hooks/useSellerHook';
import { isEmpty } from 'lodash';

export const fetchSellerInformation = async (): Promise<Response<SellerInformationType>> => {
  const routeconfig: RouteConfig = {
    method: 'GET',
    path: `${API_ROUTES.SELLER}`
  };
  const repsonse = await makeRequest<Response<SellerInformationType>>(routeconfig);
  return repsonse;
};

export const useFetchSellerInformation = (isEnabled?: boolean) => {
  const { t } = useTranslation('httpMessage');
  const { storeSellerInfoToRedux, sellerInfo } = useSellerHook();
  const [accessToken] = useLocalStorage('accessToken', null);
  const {
    data: sellerInformationData,
    error: sellerInformationError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(['sellerInformation', isEnabled], fetchSellerInformation, {
    select: (repsonse) => {
      return repsonse.data;
    },
    onSuccess(data) {
      if (!isEmpty(data)) {
        storeSellerInfoToRedux(data);
      }
    },
    onError: () => {
      toast.error(t('accountInformationError'));
    },
    enabled: accessToken !== null && !isEnabled,
    refetchOnWindowFocus: false,
    staleTime: 1000
  });

  return {
    sellerInformationData,
    sellerInformationError,
    isLoading,
    isFetching,
    refetch
  };
};

export const fetchHostInformation = async (hostId: number): Promise<Response<IHostInfo>> => {
  const routeconfig: RouteConfig = {
    method: 'GET',
    path: `${API_ROUTES.HOST}/${hostId}`
  };
  return makeRequest<Response<IHostInfo>>(routeconfig);
};

export const useFetchHostInformation = () => {
  const { t } = useTranslation('httpMessage');
  const [accessToken] = useLocalStorage('accessToken', null);
  const { roleSpecificId } = useContext(AuthContext);
  const {
    data: hostInformationData,
    error: hostInformationError,
    isLoading,
    isFetching,
    refetch
  } = useQuery([API_ROUTES.HOST, roleSpecificId], () => fetchHostInformation(roleSpecificId), {
    select: (hostData) => {
      return hostData.data;
    },
    onError: () => {
      toast.error(t('accountInformationError'));
    },
    enabled: accessToken !== null,
    refetchOnWindowFocus: false
  });

  return {
    hostInformationData,
    hostInformationError,
    isLoading,
    isFetching,
    refetch
  };
};

export const deactivateAccount = async (isSellerAccount: boolean): Promise<Response<string>> =>
  makeRequest({
    path: `${isSellerAccount ? API_ROUTES.SELLER : API_ROUTES.HOST}/deactivate`,
    method: 'PUT'
  });

export const useDeactivateAccount = (isSellerAccount: boolean) => {
  const { t } = useTranslation('httpMessage');
  const { logout, isLogoutLoading } = useProvideAuth({});
  const { mutate: deactivateUserAccount, isLoading: isDeactivateLoading } = useMutation<
    Response<string>,
    ResponseError
  >(
    ['deactivate account'],
    () => {
      return deactivateAccount(isSellerAccount);
    },
    {
      onSuccess: () => {
        logout();
      },
      onError: () => {
        toast.error(t('deactivateErrorMessage'));
      }
    }
  );
  return {
    deactivateUserAccount,
    isDeactivateAccountLoading: isDeactivateLoading || isLogoutLoading
  };
};
