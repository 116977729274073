export enum Size {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
  EXTRA_SMALL = 'xs'
}

export enum Headings {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  p = 'p'
}
export enum Elements {
  headings = 'headings',
  label = 'label',
  p = 'p',
  div = 'div',
  span = 'span'
}

export const PARAGRAPH_SIZES_MAP: Record<Size, string> = {
  [Size.LARGE]: 'text-1.2lg',
  [Size.MEDIUM]: 'text-1.2md',
  [Size.SMALL]: 'text-1.1xs',
  [Size.EXTRA_SMALL]: 'text-1.2xs'
};
export const LABEL_SIZES_MAP: Record<Size, string> = {
  [Size.LARGE]: 'text-1.1lg',
  [Size.MEDIUM]: 'text-1.8sm',
  [Size.SMALL]: 'text-1.2xs',
  [Size.EXTRA_SMALL]: 'text-1.6xs'
};

export const HEADING_SIZES_MAP: Record<Headings, string> = {
  [Headings.h1]: 'text-4.5xl',
  [Headings.h2]: 'text-4xl',
  [Headings.h3]: 'text-3.2xl',
  [Headings.h4]: 'text-2.5xl',
  [Headings.h5]: 'text-2xl',
  [Headings.h6]: 'text-xl',
  [Headings.p]: 'text-base'
};

export const ELEMENTS_MAP: Record<Elements, string> = {
  [Elements.headings]: 'font-work-sans font-medium tracking-tighter',
  [Elements.label]: 'font-semibold tracking-normal',
  [Elements.p]: 'font-normal tracking-tight',
  [Elements.div]: 'font-normal tracking-tight',
  [Elements.span]: 'font-normal tracking-tight'
};
