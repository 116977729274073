export const PROGRESS_BANNER_STATES = {
  BUSINESS_INFORMATION: 'businessInformation',
  PAYMENT_INFORMATION: 'paymentInformation',
  ADDITIONAL_INFORMATION: 'additionalInformation'
};

export const PROGRESS_BANNER_STATES_HOST = {
  PERSONAL_INFORMATION: 'personalInformation',
  ADDITIONAL_INFORMATION: 'additionalInformation'
};
