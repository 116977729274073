import '../../index.css';
import { useTranslation } from 'react-i18next';
import ShimmerLoader from '../../components/Shimmer';
import Typography from '../../components/Typography';
import { CommunityGuidelinesShimmerConfig } from '../../config/shimmerSetup';
import { useGetLegalStatement } from '../../hooks/useGetLegalStatement/useGetLegalStatment';

const CommunityGuidelines: React.FC = () => {
  const { t } = useTranslation('form');
  const { data, isLoading } = useGetLegalStatement();
  return (
    <div className="flex flex-col ml-38.5 community-guidelines-custom-class">
      <Typography variant="h3" className="mt-15">
        {t('communityGuidelinesLabel')}
      </Typography>
      {isLoading ? (
        <div className="w-205">
          <ShimmerLoader shimmerConfig={CommunityGuidelinesShimmerConfig} />
        </div>
      ) : (
        <div
          className="mt-5 mb-10 w-163 text-justify gap-y-2.5"
          dangerouslySetInnerHTML={{ __html: data?.SettingsPage?.CG?.html as unknown as string }}
        />
      )}
    </div>
  );
};

export default CommunityGuidelines;
