import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import { isMobileOnly, isMobile, isTablet } from 'react-device-detect';
import { TypographyCore } from '../Core';

const ModalWrapper = ({
  isModalOpen,
  onClose,
  children,
  minWidth = '60%',
  minHeight = '75dvh',
  modalTitle,
  showCloseIcon
}: {
  isModalOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  minWidth?: string;
  minHeight?: string;
  modalTitle?: string;
  showCloseIcon?: boolean;
}) => {
  return (
    <Modal open={isModalOpen} onClose={onClose} sx={{ width: '100vw', overflowY: 'hidden' }}>
      <ModalDialog
        layout={isMobileOnly || isTablet || isMobile ? 'fullscreen' : 'center'}
        sx={{
          minWidth: minWidth,
          minHeight: minHeight,
          backgroundColor: 'background.body',
          px: { xs: 2, md: 6 },
          pt: {
            xs: 'calc(12px + var(--Header-height))',
            sm: 'calc(12px + var(--Header-height))',
            md: 3
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          overflow: 'auto'
        }}
      >
        {showCloseIcon ? <ModalClose /> : null}
        {modalTitle ? (
          <TypographyCore fontSize={20} bold>
            {modalTitle}
          </TypographyCore>
        ) : null}

        {children}
      </ModalDialog>
    </Modal>
  );
};

export default ModalWrapper;
