import { TFunction } from 'react-i18next';
import { PROGRESS_BANNER_STATES, PROGRESS_BANNER_STATES_HOST } from '../constants/progressBanner';
import { ROUTES } from '../constants/routes';
import { ProgressBannerState } from '../types';

export const getProgressBanner = (t: TFunction): ProgressBannerState[] => {
  return [
    {
      key: 'sellerBusinessInformationLabel',
      label: t('formTitleLabel', { ns: PROGRESS_BANNER_STATES.BUSINESS_INFORMATION }),
      route: ROUTES.BUSINESS_INFORMATION
    },
    {
      key: 'sellerAdditionalInformationLabel',
      label: t('formTitleLabel', { ns: PROGRESS_BANNER_STATES.ADDITIONAL_INFORMATION }),
      route: ROUTES.ADDITIONAL_INFORMATION
    }
  ];
};

export const getProgressBannerHost = (t: TFunction): ProgressBannerState[] => {
  return [
    {
      key: 'hostPersonalInformationLabel',
      label: t('formTitleLabel', { ns: PROGRESS_BANNER_STATES_HOST.PERSONAL_INFORMATION }),
      route: ROUTES.HOST_PERSONAL_INFORMATION
    },
    {
      key: 'hostAdditionalInformationLabel',
      label: t('formTitleLabel', { ns: PROGRESS_BANNER_STATES_HOST.ADDITIONAL_INFORMATION }),
      route: ROUTES.HOST_ADDITIONAL_INFORMATION
    }
  ];
};
