import * as Sentry from '@sentry/react';

export function initializeSentry() {
  const environment = process.env.REACT_APP_ENV;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment,
    enabled: environment !== 'dev'
  });
}
