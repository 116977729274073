const CURRENCY_SIGNS: { [key: string]: string } = {
  USD: '$',
  EUR: '€',
  LBP: '£'
};
export const numberWithCommas = (x: number) => {
  return x.toLocaleString();
};

export const formatWithCurrency = (x: number, currencyCode: string) => {
  return `${CURRENCY_SIGNS[currencyCode?.toUpperCase()]}${numberWithCommas(x)}`;
};
