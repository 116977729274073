import React, { useEffect, useState } from 'react';
import '../../../index.css';
import { toast } from 'react-toastify';
import useOnboardSeller from '../../../hooks/useOnboardSeller';
import Button from '@mui/joy/Button';
import { useRemoteConfig } from '../../../context/RemoteConfigProvider';

const SellerOnboardForm = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    brandName: '',
    email: '',
    cortinaId: '',
    phone: '',
    password: '',
    json: 'none',
    status: false,
    firstName: '',
    lastName: '',
    dob: ''
  });

  const { handleOnboardSubmit, responseDetails, error, loading } = useOnboardSeller();
  const [isFormValid, setIsFormValid] = useState(false);

  const { isOneAccountEnabled } = useRemoteConfig();

  useEffect(() => {
    const { businessName, brandName, email, firstName, lastName, phone } = formData;
    if (isOneAccountEnabled) {
      setIsFormValid(
        businessName.trim() !== '' &&
          brandName.trim() !== '' &&
          email.trim() !== '' &&
          firstName.trim() !== '' &&
          lastName.trim() !== '' &&
          phone.trim() !== ''
      );
    } else {
      setIsFormValid(businessName.trim() !== '' && brandName.trim() !== '' && email.trim() !== '');
    }
  }, [formData, isOneAccountEnabled]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === 'phone') {
      formattedValue = value
        .replace(/^(\+1)?/, '')
        .replace(/[^0-9]/g, '')
        .slice(0, 10);
      if (formattedValue.length === 10) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6)}`;
      }
    } else if (name === 'dob') {
      formattedValue = value.replace(/[^0-9]/g, '').slice(0, 8);
      if (formattedValue.length >= 3 && formattedValue.length <= 4) {
        formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
      } else if (formattedValue.length >= 5) {
        formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
          2,
          4
        )}/${formattedValue.slice(4, 8)}`;
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const dataToSubmit = {
      ...formData,
      dob: formData.dob || '01/01/2001'
    };
    handleOnboardSubmit(dataToSubmit);
  };

  const copyToClipboard = (text: any, type: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`${type} copied to clipboard`);
      })
      .catch((err) => {
        toast.error('Failed to copy text: ', err);
      });
  };

  const generateRandomString = (length: any) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const fillTestData = () => {
    const randomString = generateRandomString(5);
    setFormData({
      businessName: `test${randomString}`,
      brandName: `test${randomString}`,
      email: `test${randomString}@test.com`,
      cortinaId: '',
      phone: '999-999-9999',
      password: '',
      json: 'none',
      status: false,
      firstName: 'test',
      lastName: 'test',
      dob: '01/01/2001'
    });
  };

  const buttonStyles = {
    backgroundColor: isFormValid ? '#4CAF50' : '#ccc',
    color: 'white',
    padding: 10,
    margin: 20,
    cursor: isFormValid ? 'pointer' : 'not-allowed'
  };

  return (
    <div style={{ margin: '50px' }}>
      <h1>Onboard Sellers</h1>
      <form onSubmit={handleSubmit}>
        {isOneAccountEnabled && (
          <>
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              style={{ margin: 5, marginTop: 40 }}
              onChange={handleChange}
            />
            <br />
            <br />
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              style={{ margin: 5 }}
              onChange={handleChange}
            />
            <br />
            <br />
            <label htmlFor="dob">Date of Birth (DOB):</label>
            <input
              type="text"
              id="dob"
              name="dob"
              value={formData.dob}
              placeholder="MM/DD/YYYY"
              style={{ margin: 5 }}
              onChange={handleChange}
            />
            <br />
            <br />
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              style={{ margin: 5 }}
              onChange={handleChange}
            />
            <br />
            <br />
          </>
        )}
        <label htmlFor="businessName">Business Name:</label>
        <input
          type="text"
          id="businessName"
          name="businessName"
          value={formData.businessName}
          style={{ margin: 5 }}
          onChange={handleChange}
        />
        <br />
        <br />
        <label htmlFor="brandName">Brand Name:</label>
        <input
          type="text"
          id="brandName"
          name="brandName"
          value={formData.brandName}
          style={{ margin: 5 }}
          onChange={handleChange}
        />
        <br />
        <br />
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          style={{ margin: 5 }}
          onChange={handleChange}
        />
        <br />
        <br />
        <Button
          type="submit"
          data-testid="submit-brand-info"
          style={buttonStyles}
          disabled={!isFormValid}
        >
          Submit
        </Button>
        {process.env.REACT_APP_ENV === 'dev' ? (
          <Button
            type="button"
            onClick={fillTestData}
            style={{ backgroundColor: '#4CAF50', color: 'white', padding: 10, margin: 20 }}
            data-testid="test-fill-copy"
          >
            Test Fill
          </Button>
        ) : null}
      </form>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        responseDetails && (
          <div>
            <h1 style={{ marginTop: 50 }}>Response Details:</h1>
            <p>
              <strong>Login Email:</strong>{' '}
              {responseDetails.data.supplierLoginEmail || 'Not available'}
              <Button
                onClick={() => copyToClipboard(responseDetails.data.supplierLoginEmail, 'Email')}
                style={{ backgroundColor: '#4CAF50', color: 'white', padding: 10, margin: 10 }}
                data-testid="login-email-copy"
              >
                Copy
              </Button>
            </p>
            <p>
              <strong>Temporary Password:</strong>{' '}
              {responseDetails.data.supplierTemporaryPassword || 'Not available'}
              <Button
                onClick={() =>
                  copyToClipboard(responseDetails.data.supplierTemporaryPassword, 'Password')
                }
                style={{ backgroundColor: '#4CAF50', color: 'white', padding: 10, margin: 10 }}
                data-testid="temoporary-pass-copy"
              >
                Copy
              </Button>
            </p>
          </div>
        )
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default SellerOnboardForm;
