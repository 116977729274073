import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import additionalInformation from './en/additionalInformation.json';
import basicInformationStorefront from './en/basicInformationStorefront.json';
import businessDescription from './en/businessDescription.json';
import businessInformation from './en/businessInformation.json';
import form from './en/form.json';
import header from './en/header.json';
import home from './en/home.json';
import hostAccountInformation from './en/hostAccountInformation.json';
import hostEditAdditionalInformation from './en/hostEditAdditionalInformation.json';
import hostEditPersonalInformation from './en/hostEditPersonalInformation.json';
import hostInformation from './en/hostInformation.json';
import httpMessage from './en/httpMessage.json';
import live from './en/live.json';
import liveScheduler from './en/liveScheduler.json';
import livestream from './en/livestream.json';
import order from './en/order.json';
import paymentInformation from './en/paymentInformation.json';
import personalInformation from './en/personalInformation.json';
import product from './en/product.json';
import saveChanges from './en/saveChanges.json';
import sellerAccountInformation from './en/sellerAccountInformation.json';
import sellerAdditionalInformation from './en/sellerAdditionalInformation.json';
import sellerBusinessInformation from './en/sellerBusinessInformation.json';
import sellerInformation from './en/sellerInformation.json';
import sellerPaymentInformation from './en/sellerPaymentInformation.json';
import signupHost from './en/signupHost.json';
import signupSeller from './en/signupSeller.json';
import forgotPassword from './en/forgotPassword.json';
import store from './en/store.json';
import storefront from './en/storefront.json';
import studio from './en/studio.json';
import videoForm from './en/videoForm.json';
import videos from './en/videos.json';
import copyRight from './en/copyRight.json';
import otpInput from './en/components/otpInput.json';
import mediaUpload from './en/components/mediaUpload.json';

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      form,
      home,
      signupHost,
      signupSeller,
      forgotPassword,
      businessDescription,
      header,
      order,
      product,
      storefront,
      live,
      videos,
      basicInformationStorefront,
      store,
      studio,
      liveScheduler,
      businessInformation,
      paymentInformation,
      additionalInformation,
      videoForm,
      saveChanges,
      sellerInformation,
      sellerAccountInformation,
      sellerBusinessInformation,
      sellerPaymentInformation,
      sellerAdditionalInformation,
      httpMessage,
      hostInformation,
      personalInformation,
      livestream,
      hostAccountInformation,
      hostEditAdditionalInformation,
      hostEditPersonalInformation,
      copyRight,
      otpInput,
      mediaUpload
    }
  },
  react: {
    useSuspense: false
  }
});

export default i18next;
