import { Box, Chip as ChipMUI, ColorPaletteProp, ChipProps } from '@mui/joy';
import { useMemo } from 'react';
import { statusPrettfier } from '../../../utils/utils';

const partiallyFulfilledColor = 'linear-gradient(to left, #FFAB00 50%, #EDDAB4 50%)';

interface ChipCoreProps {
  status?: string;
  statusQty?: number;
  chipColor?: string;
}
const ChipCore = ({ status, statusQty, chipColor, sx }: ChipCoreProps & ChipProps) => {
  const statusDisplayText = useMemo(() => {
    if (statusQty) {
      const displayStatus = statusPrettfier[`${status}`]?.toLowerCase() ?? status?.toLowerCase();
      return `${displayStatus}: ${statusQty}`;
    }
    return statusPrettfier[`${status}`]?.toLowerCase() ?? status?.toLowerCase();
  }, [status, statusQty]);

  if (!status) {
    return null;
  }

  return (
    <ChipMUI
      variant="soft"
      startDecorator={
        {
          confirmed: <OrderStatusIcon color={partiallyFulfilledColor} />,
          partiallyfulfilled: <OrderStatusIcon color={partiallyFulfilledColor} />,
          partiallyshipped: <OrderStatusIcon color={partiallyFulfilledColor} />,
          unfulfilled: <OrderStatusIcon color={'#FFAB00'} />,
          fulfilled: <OrderStatusIcon color={'#22C55E'} />,
          cancelled: <OrderStatusIcon color={'grey'} />,
          refunded: <OrderStatusIcon color={'orange'} />
        }[status?.toString()?.toLowerCase()]
      }
      color={(chipColor as ColorPaletteProp) ?? ('neutral' as ColorPaletteProp)}
      sx={{
        borderRadius: 4,
        textTransform: 'capitalize',
        ...sx
      }}
    >
      {statusDisplayText}
    </ChipMUI>
  );
};

const OrderStatusIcon = ({ color }: { color: string }) => {
  return (
    <Box
      style={{
        width: 10,
        height: 10,
        background: color,
        borderRadius: 50
      }}
    />
  );
};
export default ChipCore;
