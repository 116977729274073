import classNames from 'classnames';
import noop from 'lodash/noop';
import { FC, useCallback, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import images from '../../assets';
import {
  DEFAULT_ERROR,
  FILE_TO_LARGE_ERROR,
  INVALID_FILE_NAME_ERROR,
  INVALID_FILE_TYPE_ERROR
} from '../../constants/images';
import Button from '../Button';
import Typography from '../Typography';
import { VALID_MEDIA_FILE_NAME_REGEX } from '../../constants/regex';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadIcon } from '../../assets/uploadIconCobalt.svg';
import { v4 } from 'uuid';

interface Iprops {
  title?: string;
  buttonText?: string;
  textMessage1?: string;
  textMessage2?: string;
  textMessage3?: string;
  textMessage4?: string;
  titleStyle?: string;
  containerStyle?: string;
  maxSize?: number;
  acceptedFormat?: Accept;
  maxSizeError?: string;
  invalidFileTypeError?: string;
  invalidFileNameError?: string;
  genericError?: string;
  onAcceptedFiles: (file: File[]) => void;
  setDuration?: React.Dispatch<React.SetStateAction<number>>;
  onFileRejections?: (file: FileRejection[]) => void;
  testid?: string;
  imageIcon?: string;
  imageStyle?: string;
  imageButtonStyle?: string;
  isButtonNeeded?: boolean;
  isImageLoading?: boolean;
  setIsImageLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  errorClassName?: string;
  variant?: 'transparent' | 'contained';
  buttonStyle?: string;
  isContainerClickable?: boolean;
  uploadIconText?: string;
}

const processFiles = (acceptedFiles: File[]): File[] => {
  const transformedFiles = acceptedFiles.map((singleFile) => {
    if (!VALID_MEDIA_FILE_NAME_REGEX.test(singleFile.name)) {
      return new File([singleFile], `${singleFile.name}`, { type: singleFile.type });
    }
    return singleFile;
  });
  return transformedFiles;
};
const MediaUpload: FC<Iprops> = ({
  title,
  buttonText,
  textMessage1,
  textMessage2,
  textMessage3,
  textMessage4,
  titleStyle,
  containerStyle,
  maxSizeError,
  invalidFileTypeError,
  invalidFileNameError,
  genericError = 'File reading has failed',
  maxSize,
  acceptedFormat,
  onAcceptedFiles,
  setDuration,
  onFileRejections,
  testid,
  imageIcon = images.videoUpload,
  imageStyle = '',
  imageButtonStyle = '',
  isButtonNeeded = true,
  isImageLoading = false,
  setIsImageLoading,
  errorClassName = '',
  variant = 'contained',
  buttonStyle,
  uploadIconText,
  isContainerClickable = true
}) => {
  const { t } = useTranslation('mediaUpload');
  const [error, setError] = useState('');
  const updateImageLoadingState = (updatedLoadingState: boolean) => {
    if (setIsImageLoading) {
      setIsImageLoading(updatedLoadingState);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    updateImageLoadingState(true);
    if (fileRejections.length) {
      fileRejections.forEach((e) => {
        updateImageLoadingState(false);
        if (e.errors[0].code === FILE_TO_LARGE_ERROR) {
          setError(maxSizeError || genericError);
        } else if (e.errors[0].code === INVALID_FILE_TYPE_ERROR) {
          setError(invalidFileTypeError || genericError);
        } else if (e.errors[0].code === INVALID_FILE_NAME_ERROR) {
          setError(invalidFileNameError || e.errors[0].message || genericError);
        } else {
          setError(genericError);
        }
      });
      onFileRejections && onFileRejections(fileRejections);
    } else {
      const vid = document.createElement('video');
      const fileURL = URL.createObjectURL(acceptedFiles[0]);
      vid.src = fileURL;
      // wait for duration to change from NaN to the actual duration
      vid.ondurationchange = function () {
        const duration = vid.duration;
        duration && setDuration && setDuration(duration);
      };
      setError('');

      const transformedFiles = processFiles(acceptedFiles);
      onAcceptedFiles(transformedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled: false,
    maxSize: maxSize,
    accept: acceptedFormat,
    onFileDialogCancel: () => {
      if (genericError && genericError !== DEFAULT_ERROR) {
        setError(genericError);
      }
    },
    onDrop
  });

  return (
    <>
      <div
        {...getRootProps({
          className: 'dropzone',
          style: { cursor: isContainerClickable ? 'pointer' : 'auto' }
        })}
        className={classNames(
          'w-full border border-solid  border-gray-650 flex flex-col justify-center items-center',
          containerStyle,
          error && 'border-solid border-error'
        )}
        role="presentation"
        onClick={isContainerClickable ? open : noop}
      >
        {title && <p className={titleStyle}>{title}</p>}
        <div className={'flex flex-col justify-center items-center py-5'}>
          {!isButtonNeeded ? (
            !isImageLoading && (
              <Button
                variant="image"
                btnClick={(e) => {
                  e.stopPropagation();
                  open();
                }}
                className={imageButtonStyle}
              >
                <img className={imageStyle} src={imageIcon} alt="profile upload" />
              </Button>
            )
          ) : (
            <></>
          )}
          <input
            data-testid={testid}
            {...getInputProps()}
            onLoad={() => {
              updateImageLoadingState(false);
            }}
          />
          {textMessage1 && (
            <p className="my-2 text-1.1lg tracking-normal text-black font-medium">{textMessage1}</p>
          )}
          {textMessage2 && (
            <p className="my-2 tracking-tight text-slate-550 text-1.8xs font-medium">
              {textMessage2}
            </p>
          )}
          <br />
          {isButtonNeeded && (
            <Button variant={variant} className={buttonStyle} disabled={!isContainerClickable}>
              <UploadIcon />
              <Typography
                variant="span"
                size="xs"
                className="text-[#4646D0] !text-1.2md line-clamp-3"
              >
                {buttonText}
              </Typography>
            </Button>
          )}

          {uploadIconText && (
            <p className="my-1.5 text-1.8xs font-medium tracking-tight text-[#18181C]">
              {uploadIconText}
            </p>
          )}
          <br />
        </div>
      </div>
      {error && <div className={classNames(errorClassName, 'text-sm text-error')}>{error}</div>}
    </>
  );
};

MediaUpload.defaultProps = {
  containerStyle: 'flex flex-col items-center bg-white',
  titleStyle:
    'flex flex-wrap mx-auto mt-7.5 text-3.2xl xl:ml-24 text-black tracking-tight font-semibold'
};

export default MediaUpload;
