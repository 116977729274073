import { useAppDispatch, useAppSelector } from '..';
import { SellerInformationType } from '../../types';
import { clearSellerInfo, storeSellerInfo } from '../slices/sellerDataSlice/sellerDataSlice';

interface SellerHook {
  storeSellerInfoToRedux: (userInfo: SellerInformationType) => void;
  removeSellerFromRedux: () => void;
  sellerAvatorUrl: string | undefined;
  sellerInfo: SellerInformationType;
}
const useSellerHook = (): SellerHook => {
  const dispatch = useAppDispatch();
  const removeSellerFromRedux = () => dispatch(clearSellerInfo());
  const sellerInfo = useAppSelector((state) => state?.sellerData?.value);

  const sellerAvatorUrl = useAppSelector(
    (state) => state?.sellerData?.value.assets?.[0]?.contentUrl
  );
  const storeSellerInfoToRedux = (userInfo: SellerInformationType) =>
    dispatch(storeSellerInfo(userInfo));
  return {
    storeSellerInfoToRedux,
    removeSellerFromRedux,
    sellerInfo,
    sellerAvatorUrl
  };
};

export default useSellerHook;
