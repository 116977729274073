import './index.css';

import { ModifierPhases, Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, { LegacyRef, ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import { ReactComponent as KebabIcon } from '../../assets/kebab.svg';
import Button from '../Button';
import { Box } from '@mui/joy';

interface DropdownOption {
  testId?: string;
  btnClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}
interface IDropdown {
  testId?: string;
  className?: string;
  options?: DropdownOption[];
  children?: ReactNode;
  placement?: Placement;
}

const DropdownMenu = ({ testId, className, options, children, placement }: IDropdown) => {
  const [visible, setVisibility] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);

  const ref = useRef<HTMLDivElement>(null);
  const wrapperElement = window && window.document.getElementById('table-wrapper');
  const rect = wrapperElement?.getBoundingClientRect();
  const wrapperOffset = {
    top: rect?.top,
    bottom: rect?.bottom,
    left: rect?.left,
    right: rect?.right
  };
  const hide = {
    name: 'hide',
    enabled: true,
    phase: 'main' as ModifierPhases,
    data: {
      isReferenceHidden: true,
      hasPopperEscaped: true,
      referenceClippingOffsets: wrapperOffset,
      popperEscapeOffsets: wrapperOffset
    }
  };

  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: placement || 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 10]
        }
      },
      hide
    ]
  });

  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (ref.current && !ref.current.contains(target as Node)) {
        setVisibility(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  function handleDropdownClick() {
    setVisibility(!visible);
  }

  return (
    <div ref={ref}>
      <button
        ref={setReferenceRef as LegacyRef<HTMLButtonElement>}
        onClick={handleDropdownClick}
        aria-label="help-button"
        className={className}
        data-testid={testId}
      >
        {children ? children : <KebabIcon />}
      </button>
      {ReactDOM.createPortal(
        <React.Fragment>
          {visible ? (
            <Box
              ref={setPopperRef}
              style={styles.popper}
              {...attributes.popper}
              className="popper z-50"
            >
              <Box
                className={classNames(
                  'text-base float-left list-none text-left shadow-default flex-col bg-gray-55 rounded-2.5xl p-5',
                  visible && 'flex'
                )}
                style={styles.offset}
              >
                {options &&
                  options.map((option: DropdownOption) => (
                    <Box
                      className="first:rounded-tl-2.5xl first:rounded-tr-2.5xl last:rounded-bl-2.5xl last:rounded-br-2.5xl last:pb-0 pb-2.5"
                      key={option.testId}
                    >
                      <Button
                        variant="text"
                        testId={option.testId}
                        className="block text-gray-850 text-sm font-medium font-inter items-center pr-0 pl-0 pt-0 pb-0 my-0 mx-0"
                        btnClick={(e) => {
                          e.preventDefault();
                          setVisibility(false);
                          option.btnClick && option.btnClick();
                        }}
                        disabled={option.disabled}
                      >
                        {option.children}
                      </Button>
                    </Box>
                  ))}
              </Box>
            </Box>
          ) : (
            <Box />
          )}
        </React.Fragment>,
        document.getElementById('portals-root') as HTMLElement
      )}
    </div>
  );
};

export default DropdownMenu;
