import classNames from 'classnames';
import { FC } from 'react';
import Typography from '../Typography/index';

interface Props {
  label: string;
  detail?: string;
  classNameContainer?: string;
  classNameLabel?: string;
  classNameDetail?: string;
}

const Placeholder: FC<Props> = ({
  label,
  detail,
  classNameContainer = '',
  classNameLabel,
  classNameDetail
}) => {
  return (
    <div className={classNames('flex flex-col', classNameContainer)}>
      <Typography variant="h6" className={classNames(classNameLabel, 'text-stone-850')}>
        {label}
      </Typography>
      {detail && (
        <p
          className={classNames(
            classNameDetail
              ? classNameDetail
              : 'text-sm tracking-normal font-normal text-stone-850 mt-2.5'
          )}
        >
          {detail}
        </p>
      )}
    </div>
  );
};

export default Placeholder;
