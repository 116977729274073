import '../../index.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LandingPageLayout from '../../suneify/components/LandingPageLayout';
import { Box, FormControl, FormLabel, Input, Radio, Stack } from '@mui/joy';
import { ButtonCore, TypographyCore } from '../../suneify/components/Core';
import { ROUTES } from '../../constants/routes';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateSellerOutreachInfo } from '../../hooks/useSuneifyOnboarding/useCreateSellerOutreachInfo';
import { toast } from 'react-toastify';

interface ISellerOutreachInfo {
  primaryContactName: string;
  emailAddress: string;
}

const Onboarding = () => {
  const [hasExistingWebsite, setHasExistingWebsite] = useState(false);
  const [showConnectToWebsiteForm, setShowConnectToWebsiteForm] = useState(false);
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    setHasExistingWebsite((prevState) => !prevState);
  };

  const schema = z.object({
    primaryContactName: z.string().min(1, 'Primary contact name is required'),
    emailAddress: z.string().email()
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      primaryContactName: '',
      emailAddress: ''
    }
  });

  const { createSellerOutreachInfoAsync } = useCreateSellerOutreachInfo();
  const onSubmit = async (data: ISellerOutreachInfo) => {
    try {
      const response = await createSellerOutreachInfoAsync({
        sellerFullName: data.primaryContactName,
        email: data.emailAddress
      });
      if (response.status == 200) {
        setShowSubmissionSuccess(true);
        setShowConnectToWebsiteForm(false);
      } else {
        toast.error('Something went wrong. Please try again later');
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error('Something went wrong. Please try again later. ' + error.message);
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <LandingPageLayout>
      {!showConnectToWebsiteForm && !showSubmissionSuccess && (
        <Stack gap={2}>
          <Box sx={{ width: '120%' }}>
            <TypographyCore level="h4">Welcome to Sune!</TypographyCore>
            <TypographyCore level="h4">We are so happy you are here.</TypographyCore>
            <TypographyCore sx={{ mt: 2 }}>
              To start selling your handmade products on sune, you can create your own shop using
              sune’s seller portal or connect an existing website.
            </TypographyCore>
            <br />
            <TypographyCore>
              {' '}
              Do you have a shop on Shopify, Wix, squarespace or Woocomerce that you would like to
              connect to sune?
            </TypographyCore>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Radio
              checked={hasExistingWebsite}
              onChange={handleChange}
              label="Yes"
              name="radio-buttons"
              slotProps={{ input: { 'aria-label': 'A' } }}
            />
            <Radio
              checked={!hasExistingWebsite}
              onChange={handleChange}
              label="No"
              name="radio-buttons"
              slotProps={{ input: { 'aria-label': 'B' } }}
            />
          </Box>
          {!hasExistingWebsite && (
            <Box sx={{ backgroundColor: '#F5F5F5', padding: 2, width: '120%' }}>
              <TypographyCore>
                By selecting this option you will be creating a Sune seller shop where you can
                create and manage all your product listings{' '}
              </TypographyCore>
            </Box>
          )}
          <ButtonCore
            variant="solid"
            color="primary"
            onClick={() => {
              if (!hasExistingWebsite) {
                navigate(ROUTES.SELLER_ONBOARDING_CREATE);
                return;
              }

              setShowConnectToWebsiteForm(true);
            }}
            sx={{ height: 40, width: 70, marginLeft: '103%' }}
          >
            Next
          </ButtonCore>
        </Stack>
      )}
      {showConnectToWebsiteForm && (
        <Stack gap={2} sx={{ width: '120%' }}>
          <TypographyCore level="title-md" fontSize={22}>
            Connecting your website
          </TypographyCore>
          <TypographyCore>
            Please provide your contact information so our team can reach out to you. We will guide
            you through the process of syncing your website so you can start selling on sune.{' '}
          </TypographyCore>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <FormControl>
                <FormLabel>Primary Contact Name*</FormLabel>
                <Input
                  placeholder="Full name"
                  variant="plain"
                  {...register('primaryContactName')}
                  sx={{
                    backgroundColor: 'white',
                    borderWidth: '1px',
                    borderColor: 'black',
                    height: 50
                  }}
                />
                {errors?.primaryContactName && (
                  <TypographyCore color="danger">
                    {errors?.primaryContactName?.message}
                  </TypographyCore>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Email Address*</FormLabel>
                <Input
                  placeholder="Email"
                  variant="plain"
                  {...register('emailAddress')}
                  sx={{
                    backgroundColor: 'white',
                    borderWidth: '1px',
                    borderColor: 'black',
                    height: 50
                  }}
                />
                {errors?.emailAddress && (
                  <TypographyCore color="danger">{errors?.emailAddress?.message}</TypographyCore>
                )}
              </FormControl>
              <Box sx={{ backgroundColor: '#F5F5F5', padding: 2, width: '100%', borderRadius: 4 }}>
                <TypographyCore>
                  By providing your contact information, our sune team will reach out to you over
                  email with instructions to connect your website to sune’s seller portal.
                </TypographyCore>
              </Box>
              <ButtonCore
                variant="solid"
                color="primary"
                type="submit"
                sx={{ height: 40, width: 70, marginLeft: '86%' }}
              >
                Next
              </ButtonCore>
            </Stack>
          </form>
        </Stack>
      )}
      {showSubmissionSuccess && (
        <Stack gap={2} sx={{ width: '120%' }}>
          <TypographyCore level="title-md" fontSize={22}>
            You're all set!
            <br />
            We will be in touch <span style={{ fontStyle: 'italic' }}>sune</span>!
          </TypographyCore>
          <TypographyCore>
            A sune team member will reach out to you over email within the next 48 hours with
            information on how to connect your existing shop to our sune seller portal.
          </TypographyCore>
        </Stack>
      )}
    </LandingPageLayout>
  );
};

export default Onboarding;
