import '../../index.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LandingPageLayout from '../../suneify/components/LandingPageLayout';
import { Checkbox, DialogContent, Stack } from '@mui/joy';
import { ButtonCore, TypographyCore } from '../../suneify/components/Core';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ROUTES } from '../../constants/routes';
import { useCreateSuneifyAccount } from '../../hooks/useCreateSuneifyAccount/useCreateSuneifyAccount';
import { toast } from 'react-toastify';
import { ROLE_TYPE } from '../../types';
import { CopyRightAction, USER_CHANNEL } from '../../constants/common';
import ReCAPTCHA from 'react-google-recaptcha';
import { useVerifyCaptchaToken } from '../../hooks/useCreateSuneifyAccount/useVerifyCaptchaToken';
import ModalWrapper from '../../suneify/components/ModalWrapper/ModalWrapper';
import LegalStatement from '../../suneify/components/LegalStatement';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import PasswordFormInput from '../../components/PasswordFormInput/PasswordFormInput';

export interface IOnboardingCreate {
  email: string;
  password: string;
  confirmPassword: string;
  userChannel: string;
  roleType: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  brandName: string;
  businessName: string;
  termsAccepted: boolean;
}

const displayText: { [key: string]: string } = {
  Terms: 'Terms and Service',
  Privacy: 'Privacy Statement',
  HelpSupport: ' Help Support'
};

const OnboardingCreate = () => {
  const [isPopUpOpen, setDialogOpen] = useState(false);
  const [displayedPopup, setDisplayedPopup] = useState('');

  const setDisplayInnerHTML = (page: string) => {
    setDialogOpen(true);
    setDisplayedPopup(page);
  };
  const handleCloseModal = () => {
    setDialogOpen(false);
  };
  const schema = z
    .object({
      firstName: z.string().min(1, 'First name is required'),
      lastName: z.string().min(1, 'Last name is required'),
      dateOfBirth: z.string().refine((date) => {
        const parsedDate = new Date(date);
        return !isNaN(parsedDate.getTime());
      }, 'Invalid date of birth'),
      businessName: z.string().min(1, 'Business name is required'),
      brandName: z.string().min(1, 'Brand name is required'),
      email: z.string().email('Invalid email address'),
      password: z.string().min(8, 'Password must be at least 8 characters'),
      confirmPassword: z.string(),
      termsAccepted: z.boolean().refine((val) => val === true, {
        message: 'You must accept the terms and conditions'
      })
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ['confirmPassword']
    });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IOnboardingCreate>({
    resolver: zodResolver(schema),
    defaultValues: {
      termsAccepted: false,
      email: '',
      dateOfBirth: '',
      firstName: '',
      lastName: '',
      brandName: '',
      businessName: '',
      password: '',
      confirmPassword: ''
    }
  });

  const { createSuneifyAccountAsync, isDataUploading } = useCreateSuneifyAccount();
  const { verifyCaptchaTokenAsync } = useVerifyCaptchaToken();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaChange = async (value: string | null) => {
    try {
      if (value != null) {
        const response = await verifyCaptchaTokenAsync(value);
        if (response.data.success) {
          setIsCaptchaVerified(true);
          return;
        }
        toast.error('Captcha verification failed. ' + response.data.message);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error('Something went wrong with captcha verification. ' + error.message);
      } else {
        toast.error('Something went wrong with captcha verification.');
      }
    }
  };

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = async (data: IOnboardingCreate) => {
    if (!isCaptchaVerified) {
      toast.error('Please verify that you are not a robot with captcha.');
      return;
    }

    if (!data.termsAccepted) {
      toast.error('Please agree to terms and conditions.');
      return;
    }

    try {
      await createSuneifyAccountAsync({
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        userChannel: USER_CHANNEL,
        roleType: ROLE_TYPE.SELLER,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dateOfBirth.split('-').reverse().join('/'),
        brandName: data.brandName,
        businessName: data.businessName
      });
      navigate(ROUTES.LOGIN);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error('Failed to create account. ' + error.message);
      }
    }
  };

  return (
    <LandingPageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} sx={{ width: { xs: '90dvw', sm: '100%' }, padding: 2 }}>
          <TypographyCore level="title-lg" fontSize={25}>
            Create your account
          </TypographyCore>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 1, sm: 2 }}>
            <FormTextInput
              label="First Name*"
              placeholder="Ex. John"
              register={register}
              name="firstName"
              error={errors?.firstName?.message}
              sx={{
                width: { xs: '100%', sm: 190 }
              }}
            />
            <FormTextInput
              label="Last Name*"
              placeholder="Ex. Doe"
              register={register}
              name="lastName"
              error={errors?.lastName?.message}
              sx={{
                width: { xs: '100%', sm: 190 }
              }}
            />
          </Stack>

          <FormTextInput
            type="date"
            label="Date of Birth*"
            placeholder="mm/dd/yyyy"
            register={register}
            name="dateOfBirth"
            error={errors?.dateOfBirth?.message}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
            <FormTextInput
              label="Business Name*"
              placeholder="Ex. Sune LLC"
              register={register}
              name="businessName"
              error={errors?.businessName?.message}
              sx={{
                width: { xs: '100%', sm: 190 }
              }}
            />
            <FormTextInput
              label="Brand Name*"
              placeholder="Ex. Sune"
              register={register}
              name="brandName"
              error={errors?.brandName?.message}
              sx={{
                width: { xs: '100%', sm: 190 }
              }}
            />
          </Stack>
          <FormTextInput
            label="Email address*"
            placeholder="Ex. johndoe@email.com"
            register={register}
            name="email"
            error={errors?.email?.message}
          />
          <PasswordFormInput
            label="Password"
            placeholder="Enter password"
            register={register}
            name="password"
            error={errors?.password?.message}
          />
          <PasswordFormInput
            label="Confirm Password"
            placeholder="Confirm password"
            register={register}
            name="confirmPassword"
            error={errors?.confirmPassword?.message}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={1}>
            <Checkbox sx={{ fontSize: 14, paddingTop: 0.5 }} {...register('termsAccepted')} />
            <TypographyCore>
              By creating the account, you agree to Sune's{' '}
              <span
                style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDisplayInnerHTML('Terms');
                }}
              >
                Terms of Service
              </span>{' '}
              and{' '}
              <span
                style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDisplayInnerHTML('Privacy');
                }}
              >
                Privacy Statement
              </span>
            </TypographyCore>
          </Stack>

          {errors?.termsAccepted && (
            <TypographyCore color="danger">{errors?.termsAccepted?.message}</TypographyCore>
          )}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            onChange={handleCaptchaChange}
            onExpired={() => {
              setIsCaptchaVerified(false);
              toast.warning('Captcha expired, please verify again.');
            }}
          />
          <ButtonCore
            variant="solid"
            color="primary"
            type="submit"
            sx={{ height: 40 }}
            loading={isDataUploading}
          >
            Create Account
          </ButtonCore>
        </Stack>
      </form>
      <ModalWrapper
        isModalOpen={isPopUpOpen}
        onClose={handleCloseModal}
        minWidth="30%"
        minHeight="0%"
      >
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ position: 'relative', my: 3 }}
        >
          <TypographyCore level="h3">{displayText[`${displayedPopup}`]}</TypographyCore>
          <ButtonCore
            onClick={handleCloseModal}
            variant="plain"
            sx={{ position: 'absolute', top: -5, right: 0 }}
          >
            <CloseRoundedIcon sx={{ width: 30, height: 30, color: 'common.black' }} />
          </ButtonCore>
        </Stack>
        {displayedPopup === CopyRightAction.HelpSupport ? (
          <DialogContent>
            <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
              <TypographyCore> Email Sune</TypographyCore>
              <TypographyCore sx={{ textDecoration: 'underline' }}>
                <a href="mailto:  onboarding@sune.com">onboarding@sune.com</a>
              </TypographyCore>
            </Stack>
            <TypographyCore level="body-xs"> 9:00 AM - 5:00 PM E.T Monday - Friday</TypographyCore>
          </DialogContent>
        ) : (
          <LegalStatement displayedPopup={displayedPopup} />
        )}
      </ModalWrapper>
    </LandingPageLayout>
  );
};

export default OnboardingCreate;
