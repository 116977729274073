import uniqueId from 'lodash/uniqueId';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const DEFAULT_BLOCK_CLASSES = 'mt-5 block';

export type BlockConfig = {
  count?: number;
  height?: number | string;
  width?: number | string;
  containerClassName?: string;
  className?: string;
  circle?: boolean;
};

type Props = {
  shimmerConfig: BlockConfig[];
  testId?: string;
};

const ShimmerLoader: FC<Props> = ({ shimmerConfig, testId }) => {
  return (
    <div className="w-full">
      {shimmerConfig &&
        shimmerConfig.map((block) => (
          <Skeleton
            count={block.count || 1}
            height={block.height}
            width={block.width}
            containerClassName={block.containerClassName || DEFAULT_BLOCK_CLASSES}
            className={block.className}
            key={uniqueId()}
            circle={block.circle}
            containerTestId={testId}
          />
        ))}
    </div>
  );
};

export default ShimmerLoader;
