import '../../index.css';
import { useTranslation } from 'react-i18next';
import ShimmerLoader from '../../components/Shimmer';
import Typography from '../../components/Typography';
import { TermsOfServiceShimmerConfig } from '../../config/shimmerSetup';
import classNames from 'classnames';
import { useGetLegalStatement } from '../../hooks/useGetLegalStatement/useGetLegalStatment';

const TermsOfServicePage = () => {
  const { t } = useTranslation('form');
  const { data, isLoading } = useGetLegalStatement();

  return (
    <div className={classNames('flex flex-col mx-21 tnc-custom-class')}>
      <Typography variant="h3" className="mt-15">
        {t('termServiceLabel')}
      </Typography>
      {isLoading ? (
        <div className="w-205">
          <ShimmerLoader shimmerConfig={TermsOfServiceShimmerConfig} />
        </div>
      ) : (
        <div
          className="mt-5 mb-10 max-w-2xl text-justify gap-y-2.5"
          dangerouslySetInnerHTML={{ __html: data?.SettingsPage?.TOS?.html as unknown as string }}
        />
      )}
    </div>
  );
};

export default TermsOfServicePage;
