import { Typography as TypographyMUI, TypographyProps } from '@mui/joy';

interface AdditionalTypographyProps {
  bold?: boolean;
  center?: boolean;
  isPlaceholder?: boolean;
}

const TypographyCore = ({
  bold,
  center,
  isPlaceholder = false,
  sx,
  level,
  ...props
}: TypographyProps & AdditionalTypographyProps) => {
  const additionalStyles: Record<string, any> = {};
  if (bold) {
    additionalStyles.fontWeight = '600';
  }
  if (center) {
    additionalStyles.textAlign = 'center';
  }
  if (isPlaceholder) {
    additionalStyles.opacity = 0.5;
  }

  return (
    <TypographyMUI
      level={level}
      sx={{
        ...additionalStyles,
        ...(typeof sx === 'object' ? sx : {})
      }}
      {...props}
    />
  );
};

export default TypographyCore;
