import { Analytics } from 'firebase/analytics';
import { createContext, useContext } from 'react';
import { analytics } from '../firebase';

export const AnalyticsContext = createContext({} as Analytics);
export const AnalyticsProvider = ({ children }: { children: JSX.Element }) => {
  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error(`analytics not found`);
  }
  return context;
};
