import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';

const postCaptchaToken = async (captchaToken: string): Promise<Response<any>> => {
  return await makeRequest({
    path: API_ROUTES.VERIFY_CAPTCHA_TOKEN,
    method: 'POST',
    data: {
      captchaResponse: captchaToken
    }
  });
};

export const useVerifyCaptchaToken = () => {
  const {
    mutateAsync: verifyCaptchaTokenAsync,
    isLoading: isDataUploading,
    isSuccess: isDataSuccessfullyUploaded
  } = useMutation({
    mutationFn: postCaptchaToken,
    onError: (error: unknown) => {
      console.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  });
  return {
    verifyCaptchaTokenAsync,
    isDataUploading,
    isDataSuccessfullyUploaded
  };
};
