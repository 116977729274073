import { TFunction } from 'i18next';
import { z } from 'zod';
import { EMPLOYER_IDENTIFICATION_NUMBER_VALID_CHARACTERS_REGEX } from '../../constants/regex';

export const getOnboardingValidationSchema = (t: TFunction) => {
  return z.object({
    legalBusinessName: z.string().min(1, 'Legal business name is required'),
    employeeIdentificationNumber: z
      .string()
      .regex(
        EMPLOYER_IDENTIFICATION_NUMBER_VALID_CHARACTERS_REGEX,
        t('employeeIdentificationNumberErrorMessage', {
          ns: 'form'
        })
      )
      .or(z.literal(''))
      .optional(),
    businessCategory: z.string().min(1, {
      message: 'Please select a category option'
    }),
    taxIdentificationNumber: z
      .string()
      .regex(
        EMPLOYER_IDENTIFICATION_NUMBER_VALID_CHARACTERS_REGEX,
        t('employeeIdentificationNumberErrorMessage', {
          ns: 'form'
        })
      )
      .or(z.literal(''))
      .optional(),
    shopName: z.string().min(1, { message: 'Shop name is required' }),
    streetAddress: z.string().min(1, { message: 'Street address is required' }),
    apartmentOrSuite: z.string().min(1, { message: 'Apartment or suite is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    state: z.string().min(1, { message: 'State is required' }),
    zipCode: z.string().min(1, { message: 'Zip code is required' }),
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    preferredContactEmail: z.string().min(1, { message: 'Contact email is required' }).email(),
    cellPhoneNumber: z
      .string()
      .regex(/^\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format 123-456-7890')
      .min(1, 'Phone number is required')
  });
};
