import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const firebaseInitialize = (data: FirebaseOptions) => {
  const app = initializeApp(data);
  const analytics = getAnalytics(app);
  const performance = getPerformance(app);
  if (localStorage.getItem('userId') && localStorage.getItem('roleType')) {
    setUserId(analytics, `${localStorage.getItem('roleType')}_${localStorage.getItem('userId')}`);
  }
  return { analytics, performance };
};
export default firebaseInitialize;

export const extractErrorMessage = (err?: Record<string, string>) => {
  if (err) return err.message;
};
