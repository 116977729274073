import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';
import { AxiosError } from 'axios';

interface ICreateBusinessInfo {
  legalBusinessName: string;
  taxIdentificationNumber: string;
  shopName: string;
  businessCategory: string;
  streetAddress: string;
  apartmentOrSuite: string;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  preferredContactEmail: string;
  cellPhoneNumber: string;
}

const updateBusinessInfo = async (
  businessInfoData: Partial<ICreateBusinessInfo>
): Promise<Response<any>> => {
  return await makeRequest({
    path: API_ROUTES.UPDATE_BUSINESS_INFO,
    method: 'PUT',
    data: businessInfoData
  });
};

export const useUpdateBusinessInfo = () => {
  const {
    mutateAsync: updateBusinessInfoAsync,
    isLoading: isDataUploading,
    isSuccess: isDataSuccessfullyUploaded
  } = useMutation({
    mutationFn: updateBusinessInfo,
    onError: (error: AxiosError) => {
      console.error(error);
      if (error.code === 'SELL_046') {
        toast.error('Brand name already exists. Please choose a different one');
        return;
      }

      if (error.code === 'SELL_045') {
        toast.error('Business name already exists. Please choose a different one');
        return;
      }
    }
  });
  return {
    updateBusinessInfoAsync,
    isDataUploading,
    isDataSuccessfullyUploaded
  };
};
