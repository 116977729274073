import { Button as ButtonMUI, ButtonProps } from '@mui/joy';

interface AdditionalButtonProps {
  minWidth?: string | number | boolean;
}

const ButtonCore = ({ minWidth, ...props }: ButtonProps & AdditionalButtonProps) => {
  props.sx = {
    minWidth: !minWidth ? 'auto' : +minWidth > 1 ? `${minWidth}px` : '120px',
    ...props.sx
  };

  return <ButtonMUI {...props} />;
};

export default ButtonCore;
