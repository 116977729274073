import { useQuery } from '@tanstack/react-query';

import { API_ROUTES } from '../../constants/apiRoutes';
import { RouteConfig } from '../../types';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';

export const usePrivacyPolicy = () => {
  const loadPrivacyPolicyConfig: RouteConfig = {
    path: API_ROUTES.PRIVACY_STATEMENT,
    method: 'GET'
  };
  const { data, isLoading, isError, isSuccess } = useQuery(
    [API_ROUTES.PRIVACY_STATEMENT],
    () => makeRequest<Response<string>>(loadPrivacyPolicyConfig),
    {
      refetchOnWindowFocus: false,
      enabled: true
    }
  );
  return {
    data,
    isLoading,
    isError,
    isSuccess
  };
};
