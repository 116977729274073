import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '../../../constants/apiRoutes';
import { makeRequest } from '../../../utils/axios';
import { Response, RouteConfig } from '../../../types';
import { ICategory } from '../../types/Category';
import useAppCommonHook from '../../../reduxStore/hooks/useAppCommonHook';
import { isEmpty } from 'lodash';

const config: RouteConfig = {
  path: API_ROUTES.GET_CATEGORIES,
  method: 'GET'
};

export const useGetCategories = () => {
  const { storeCategoryDataInfoToRedux } = useAppCommonHook();

  const { data, isLoading, isError } = useQuery(
    ['categories'],
    () => makeRequest<Response<ICategory[]>>(config),
    {
      select: (response) => {
        return response.data;
      },
      onSuccess(data) {
        if (!isEmpty(data)) {
          storeCategoryDataInfoToRedux(data);
        }
      },
      cacheTime: 30 * 1000,
      retry: 3
    }
  );

  return {
    data,
    isLoading,
    isError
  };
};
