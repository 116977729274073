export const checkAnyErrorsPresent = (errors: object) => {
  return Object.values(errors).some((eachValue) => eachValue.length > 0);
};

export const checkAnyNonEmptyErrorsPresent = (
  errors: object,
  values: Record<string, string | undefined>
) => {
  return Object.entries(errors).some(([key, value]) => value.length > 0 && values[key] !== '');
};
