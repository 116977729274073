import { Box, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Stack } from '@mui/joy';
import { useMediaQuery, Theme, useTheme } from '@mui/material';
import { ButtonCore, TypographyCore } from '../../suneify/components/Core';
import { colors, grey } from '../../suneify/constants/theme';
import SellerAgreement from './SellerAgreement';

const OnboardingConsentModal = ({
  open,
  handleOnClose,
  setSellerAgreedToTerms
}: {
  open: boolean;
  handleOnClose: () => void;
  setSellerAgreedToTerms: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme<Theme>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (open && isSmallScreen) {
    return (
      <Box
        sx={{
          width: '100%',
          padding: 2,
          backgroundColor: 'white',
          borderRadius: 0,
          overflow: 'auto'
        }}
      >
        <TypographyCore level="title-lg" fontSize={16} sx={{ textAlign: 'center' }}>
          Seller Agreement
        </TypographyCore>
        <Box
          sx={{
            height: '80dvh',
            overflowY: 'auto',
            paddingBottom: 10,
            marginTop: 3
          }}
        >
          <SellerAgreement />
        </Box>
      </Box>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100dvh'
      }}
    >
      <ModalDialog sx={{ padding: 0 }}>
        <Box
          sx={{
            width: { xs: '100dvw', sm: '100%' },
            height: '60dvh',
            padding: 6,
            position: 'relative',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }}
        >
          <DialogTitle>Seller Agreement</DialogTitle>
          <DialogContent>Please read and agree to the terms</DialogContent>
          <Box
            sx={{
              maxHeight: '50dvh',
              width: '100%',
              overflowY: 'auto',
              maxWidth: '100%',
              overflowWrap: 'break-word',
              marginTop: 3
            }}
          >
            <SellerAgreement />
          </Box>

          <ModalClose
            data-testid="close-modal"
            size="lg"
            sx={{ width: 40, height: 40 }}
            onClick={handleOnClose}
          />
        </Box>

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          alignItems={'center'}
          width={'100%'}
          height={100}
          sx={{
            borderTop: `1px solid ${grey.level10}`,
            marginTop: 2
          }}
          paddingRight={5}
        >
          <Stack direction="row" gap={1} marginTop={2}>
            <ButtonCore
              variant="outlined"
              sx={{
                width: 100,
                height: 50,
                color: colors.primary,
                borderColor: colors.primary
              }}
              onClick={(e) => {
                setSellerAgreedToTerms(false);
                handleOnClose();
              }}
            >
              Decline
            </ButtonCore>
            <ButtonCore
              variant="solid"
              sx={{ width: 100, height: 50, backgroundColor: colors.primary }}
              onClick={(e) => {
                setSellerAgreedToTerms(true);
                handleOnClose();
              }}
            >
              Agree
            </ButtonCore>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default OnboardingConsentModal;
