import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { API_ROUTES } from '../../constants/apiRoutes';
import { AUTHORIZATION_HEADER } from '../../constants/common';
import { AuthContext } from '../../context/AuthContext';
import { RouteConfig } from '../../types';
import { makeRequest } from '../../utils/axios';

interface IBusinessInfoResponse {
  data: {
    legalBusinessName: string;
    shopName: string;
    apartmentOrSuite: string;
    city: string;
    id: number;
    businessCategory: string;
    businessDescription: string;
    shopCountry: string;
    timezone: string;
    currency: string;
    phoneNumber: string;
    contactEmail: string;
    website: string;
    legalCountry: string;
    address: string;
    other: string;
    state: string;
    zipcode: string;
    businessRegistrationNumber: string;
    employeeIdentificationNumber: string;
    ownerFirstName: string;
    ownerLastName: string;
    sellerAttributeTags: number[];
    sellerCategoryTags: number[];
  };
}

export const useGetBusinessInfo = () => {
  const { accessToken } = useContext(AuthContext); // Extract the token here

  const config: RouteConfig = {
    path: API_ROUTES.GET_BUSINESS_INFO,
    method: 'GET',
    headers: { [AUTHORIZATION_HEADER]: accessToken }
  };

  const { data, isLoading, isError } = useQuery(
    ['business-info'],
    () => makeRequest<IBusinessInfoResponse>(config),
    {
      retry: 1,
      cacheTime: 0,
      select: (response) => response.data
    }
  );

  return {
    data,
    isLoading,
    isError
  };
};
