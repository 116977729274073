import { useState } from 'react';

export function useLocalStorage(key: string, initialValue: unknown) {
  const [storedValue, setStoredValue] = useState(() => {
    let item;
    try {
      item = window?.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return item;
    }
  });

  // ... persists the new value to localStorage.
  const setValue = (value: unknown) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        const value =
          typeof valueToStore !== 'object' ? valueToStore : JSON.stringify(valueToStore);
        window.localStorage.setItem(key, value);
      }
    } catch (error) {
      // Handle the error case
    }
  };
  return [storedValue, setValue];
}
