import './index.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import VerticalNavBar from '../../components/VerticalNavBar';
import { NAVIGATION_TABS_HOST_INFORMATION } from '../../config/navigationTabs';
import { getSelectedTabLabel } from '../../utils/verticalNavBar';

const HostInformationLayout: React.FC = (): JSX.Element => {
  const { t } = useTranslation('hostInformation');
  return (
    <div className="flex host-information-layout-container w-full overflow-x-hidden">
      <div className="flex grow bg-gray-55">
        <VerticalNavBar
          allTabs={NAVIGATION_TABS_HOST_INFORMATION(t)}
          selectedTab={getSelectedTabLabel(
            window.location.pathname,
            NAVIGATION_TABS_HOST_INFORMATION(t)
          )}
        />
      </div>
      <div className="mr-10 lg:mr-36 flex flex-col h-full w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default HostInformationLayout;
