import React from 'react';
import './index.css';

function Unauthorized(): JSX.Element {
  return (
    <div className="error-page-container">
      <p className="text-3.2xl text-black">Error 401</p>
      <p className="mt-2.5 text-2xl text-black">Unauthorized User</p>
    </div>
  );
}

export default Unauthorized;
