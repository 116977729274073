/* eslint-disable no-useless-escape */
/**
 * W3C compliant email address validation regex (RFC 5322 Official Standard)
 *
 * @public
 */
export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // NOSONAR

export const PHONE_NUMBER_REGEX = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
export const US_PHONE_NUMBER_REGEX = /^\+1\s*\((\d{3})\)-(\d{3})-(\d{4})$/;
export const ZIPCODE_REGEX = /^\d{5}(?:-\d{4})?$/;

export const WEBSITE_URL_REGEX =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

export const PHONE_VALID_CHARACTERS_REGEX = /^[0-9() -]{0,14}$/;

export const ZIP_CODE_VALID_CHARACTERS_REGEX = /^[0-9-]*$/;
export const ZIP_CODE_SUFFIX_REGEX = /^-\d{0,4}$/;
export const BUSINESS_REGISTRATION_NUMBER_VALID_CHARACTERS_REGEX = /^[0-9a-zA-Z-]{0,20}$/;
export const EMPLOYER_IDENTIFICATION_NUMBER_VALID_CHARACTERS_REGEX =
  /^([A-Z]{2}-\d{7}|\d{2}-\d{7})$/;

/* eslint-disable no-useless-escape */
/**
 * Password must be between 8-16 characters long
 * Have at least 1 uppercase letter
 * Have at least 1 lowercase letter
 * Have at least 1 number
 * Have at least 1 special character (!, @, #, $, %, ^, &, or *. No other special characters are allowed.)
 * First character of the password should be an alphabet
 *
 * @public
 */
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z][A-Za-z\d!@#$%^&*]{7,15}$/;

export const FORM_TEXT_REGEX = /^[a-z0-9-!&#$%().,'\s\n]+$/i;

export const POLICY_REGEX = /^[0-9a-zA-Z-#.,&!@$%* ]*$/;

export const BUSINESS_DESCRIPTION_REGEX = /^[0-9a-zA-Z-'.,?#$%&!*+()\n ]*$/;

export const ADDRESS_REGEX = /^[0-9a-zA-Z-/#, ]*$/;

export const BUSINESS_REGISTRATION_NUMBER_REGEX = /^[0-9a-zA-Z-]{20}$/;

export const EMPLOYER_IDENTIFICATION_NUMBER_REGEX = /^\d{2}-\d{7}$/;

export const NAME_REGEX = /^[\w'\-,.]*[^_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/;

export const FACEBOOK_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;

export const TWITTER_URL_REGEX = /(https?:\/\/twitter.com\/(?!\w+\/)(\w+))/;

export const TIKTOK_URL_REGEX = /(?:(?:http|https):\/\/)?(?:www.)?(?:tiktok.com)\/@(\w+)/;

export const INSTAGRAM_URL_REGEX =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;

export const YOUTUBE_URL_REGEX =
  /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:\?v=|embed\/|v\/)?)/;

export const NUMBER_REGEX = /^\d*$/;

export const ALPHA_NUMERIC_REGEX = /^[0-9a-zA-Z]*$/;

export const FULL_NAME_REGEX = /^[0-9a-zA-Z- ]*$/;

export const BIO_REGEX = /^[0-9a-zA-Z-'-,.!@#$%*<>?()\n ]*$/;

export const WEBSITE_URL_VALID_PREFIX = /^https:\/\//;

export const PHONE_NUMBER_PREFIX = /^US\+1/;

export const NICK_NAME_REGEX = /^[0-9a-zA-Z-_@+!#$%&*]*$/;

export const UNSUPPORTED_CHARACTERS_PRODUCT_NAME = /-#([A-Fa-f0-9]{6})/g;

export const CITY_REGEX = /^[a-zA-Z. ]*$/;

export const OTP_NUMBER_REGEX = /^\d+$/;

export const VALID_MEDIA_FILE_NAME_REGEX = /^[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)?$/;
