import { Box, Typography } from '@mui/joy';
import { colors, variables } from '../../../constants/theme';

interface TextBoxProps {
  text?: string;
  sx?: { [key: string]: any };
  color?: 'primary' | 'neutral' | 'primaryLight';
  children?: React.ReactNode;
}

const TextBox = ({ sx, color = 'primary', children, ...props }: TextBoxProps) => {
  const backgroundColor = colors[color];
  return (
    <Box
      sx={{ ...sx, backgroundColor, padding: 2, borderRadius: variables.boxBorderRadius }}
      {...props}
    >
      <Typography level="body-md">{children}</Typography>
    </Box>
  );
};

export default TextBox;
