import type { VideoEncoderConfiguration, VideoEncoderConfigurationPreset } from 'agora-rtc-sdk-ng';

export type AgoraVideoProfile = {
  isDefault: boolean;
  key: string;
  label: string;
  value: VideoEncoderConfiguration | VideoEncoderConfigurationPreset;
};
export const VIDEO_PROFILES: AgoraVideoProfile[] = [
  {
    isDefault: false,
    key: '360p_7',
    label: '480×360, 15fps, 320Kbps',
    value: '360p_7'
  },
  {
    isDefault: false,
    key: '360p_8',
    label: '480×360, 30fps, 490Kbps',
    value: '360p_8'
  },
  {
    isDefault: false,
    key: '480p_1',
    label: '640×480, 15fps, 500Kbps',
    value: '480p_1'
  },
  {
    isDefault: false,
    key: '480p_2',
    label: '640×480, 30fps, 1000Kbps',
    value: '480p_2'
  },
  {
    isDefault: false,
    key: '720p_1',
    label: '1280×720, 15fps, 1130Kbps',
    value: '720p_1'
  },
  {
    isDefault: false,
    key: '720p_2',
    label: '1280×720, 30fps, 2000Kbps',
    value: '720p_2'
  },
  {
    isDefault: false,
    key: '1080p_1',
    label: '1920×1080, 15fps, 2080Kbps',
    value: '1080p_1'
  },
  {
    isDefault: false,
    key: '1080p_2',
    label: '1920×1080, 30fps, 3000Kbps',
    value: '1080p_2'
  },
  {
    isDefault: true,
    key: '1080p_5',
    label: '1920×1080, 60fps, 4780Kbps',
    value: '1080p_5'
  },
  {
    isDefault: false,
    key: '200×640',
    label: '200×640, 30fps',
    value: {
      width: 200,
      height: 640,
      frameRate: 30
    }
  },
  {
    isDefault: false,
    key: '1440p_2',
    label: '2560×1440, 30fps, 4850Kbps',
    value: '1440p_2'
  },
  {
    isDefault: false,
    key: '4k_3',
    label: '3840×2160, 30fps, 8910Kbps',
    value: '4k_3'
  },
  {
    isDefault: false,
    key: '1280x720',
    label: '1280x720, 30fps, 600-2000Kbps',
    value: {
      width: 1280,
      height: 720,
      frameRate: 30,
      bitrateMin: 600,
      bitrateMax: 2000
    }
  }
];

export const DEFAULT_VIDEO_PROFILE = VIDEO_PROFILES.find((v) => v.isDefault) ?? VIDEO_PROFILES[0];
