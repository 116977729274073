import instance from '../components/AxiosInterceptor/instance';
import { RouteConfig } from '../types';

export const makeRequest = <T>(routeConfig: RouteConfig): Promise<T> => {
  const { path, method, headers = {}, params, data, options } = routeConfig;
  const url = process.env.REACT_APP_API_URL + path;
  const request = {
    method,
    url,
    data,
    params,
    headers,
    ...options
  };
  return instance(request) as unknown as Promise<T>;
};
