import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SellerInformationType } from '../../../types';

export interface SellerDataState {
  value: SellerInformationType;
}
const BusinessInformationType = {
  businessCategory: '',
  businessDescription: '',
  shopCountry: '',
  timezone: '',
  currency: '',
  phoneNumber: '',
  contactEmail: '',
  website: '',
  legalCountry: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  businessRegistrationNumber: '',
  employeeIdentificationNumber: '',
  ownerFirstName: '',
  ownerLastName: ''
};
const initialState: SellerDataState = {
  value: {
    brandName: '',
    businessName: '',
    businessDescription: '',
    emailAddress: '',
    phoneNumber: '',
    shippingPolicy: '',
    customerServicePolicy: '',
    businessInformation: BusinessInformationType,
    billingInformation: {},
    additionalInformation: [],
    isActive: false,
    sellerId: null
  }
};

export const sellerDataSlice = createSlice({
  name: 'sellerData',
  initialState,
  reducers: {
    storeSellerInfo: (state, action: PayloadAction<SellerInformationType>) => {
      const { payload } = action;

      state.value = payload;
    },
    clearSellerInfo: (state) => {
      // reset value
      state.value = {
        brandName: '',
        businessName: '',
        businessDescription: '',
        emailAddress: '',
        phoneNumber: '',
        shippingPolicy: '',
        customerServicePolicy: '',
        businessInformation: { ...BusinessInformationType },
        billingInformation: {},
        additionalInformation: [],
        isActive: false,
        sellerId: null
      };
    }
  }
});

export const { storeSellerInfo, clearSellerInfo } = sellerDataSlice.actions;

export default sellerDataSlice.reducer;
