import '../../index.css';

import React from 'react';
import { Outlet } from 'react-router-dom';

import { NAVIGATION_TABS_STORE } from '../../config/navigationTabs';
import { getSelectedTabLabel } from '../../utils/verticalNavBar';
import VerticalNavBar from '../VerticalNavBar';
import { useTranslation } from 'react-i18next';

const StoreLayout: React.FC = (): JSX.Element => {
  const { t } = useTranslation('store');
  return (
    <div className="flex w-full layout-container">
      <div className="h-full bg-slate-100">
        <VerticalNavBar
          allTabs={NAVIGATION_TABS_STORE(t)}
          selectedTab={getSelectedTabLabel(window.location.pathname, NAVIGATION_TABS_STORE(t))}
        />
      </div>
      <div className="mr-10 lg:mr-38.5 h-full relative w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default StoreLayout;
