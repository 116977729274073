import { useState } from 'react';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { makeRequest } from '../../utils/axios';
import { RouteConfig } from '../../types';
import { OnboardSellerResponse } from '../../suneify/types/Admin';

const useOnboardSeller = () => {
  const [responseDetails, setResponseDetails] = useState<OnboardSellerResponse | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOnboardSubmit = async (formData: any) => {
    const routeConfig: RouteConfig = {
      path: API_ROUTES.ONBOARD_SUNEIFY_SELLER,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_KEY ?? '',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      data: formData
    };

    toast.info('Submmited');
    setLoading(true);
    setResponseDetails(null);
    setError(null);

    try {
      const apiResponse = await makeRequest<OnboardSellerResponse>(routeConfig);
      setResponseDetails(apiResponse);
      toast.success('Successfully created new seller in the system');
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') {
        toast.error('DEV error verbose: ' + error.message);
      }
      if (error.code === 'COMM_012') {
        toast.error(
          "Sorry, you don't have access to perform this action, please reach out to the Tech team"
        );
      } else if (error.code === 'UNKNOWN') {
        toast.error(
          'It seems like a brand might already have been registered with that name, please reach out to the Tech team'
        );
      } else {
        toast.error('Error from our Backend, please reach out to the Tech team');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return { handleOnboardSubmit, responseDetails, error, loading };
};

export default useOnboardSeller;
