import { DialogContent, Stack } from '@mui/joy';
import { TypographyCore, ButtonCore } from '../Core';
import ModalWrapper from '../ModalWrapper';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const displayText: { [key: string]: string } = {
  Terms: 'Terms and Service',
  Privacy: 'Privacy Statement',
  HelpSupport: ' Help Support'
};

export const HelpSupportModal = ({
  isModalOpen,
  onClose
}: {
  isModalOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ModalWrapper isModalOpen={isModalOpen} onClose={onClose} minWidth="30%" minHeight="0%">
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ position: 'relative', my: 3, width: '100%' }}
      >
        <TypographyCore level="h3">{displayText.HelpSupport}</TypographyCore>
        <ButtonCore
          onClick={onClose}
          variant="plain"
          sx={{ position: 'absolute', top: -5, right: 0 }}
        >
          <CloseRoundedIcon sx={{ width: 30, height: 30, color: 'common.black' }} />
        </ButtonCore>
      </Stack>
      <DialogContent>
        <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
          <TypographyCore> Email Sune</TypographyCore>
          <TypographyCore sx={{ textDecoration: 'underline' }}>
            <a href="mailto: onboarding@sune.com">onboarding@sune.com</a>
          </TypographyCore>
        </Stack>
        <TypographyCore level="body-xs"> 9:00 AM - 5:00 PM E.T Monday - Friday</TypographyCore>
      </DialogContent>
    </ModalWrapper>
  );
};
