import { FirebasePerformance } from 'firebase/performance';
import { createContext, useContext } from 'react';
import { performance } from '../firebase';

export const PerformanceContext = createContext({} as FirebasePerformance);
export const PerformanceProvider = ({ children }: { children: JSX.Element }) => {
  return <PerformanceContext.Provider value={performance}>{children}</PerformanceContext.Provider>;
};

export const usePerformance = () => {
  const context = useContext(PerformanceContext);
  if (context === undefined) {
    throw new Error(`performance not found`);
  }
  return context;
};
