import AgoraRTC from 'agora-rtc-sdk-ng';
import React, { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import DefaultLayout from '../../components/layouts';
import StoreLayout from '../../components/layouts/StoreLayout';
import StudioLayout from '../../components/layouts/StudioLayout';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import { ROLE_TYPE, USER_ACTIVE_TYPE, USER_PERMISSIONS } from '../../types';
import CommunityGuidelines from '../CommunityGuidelines';
import DataPrivacy from '../DataPrivacy';
import HostInformationLayout from '../EditHostInformation';
import SellerInformationLayout from '../EditSellerInformation';
import HealthCheck from '../HealthCheck';
import HostDescriptionLayout from '../HostInformation';
import PageNotFound from '../PageNotFound';
import BusinessDescriptionLayout from '../SellerInformation';
import TermsOfServicePage from '../TermsOfService';
import Unauthorized from '../Unauthorized';
import Onboarding from '../Onboarding';
import OnboardingCreate from '../OnboardingCreate';
import {
  SuneifyHome,
  SuneifyStoreProducts,
  SuneifyStoreOrders,
  SuneifyStudioLives,
  SuneifyStudioVideos,
  SuneifyProductPage,
  SuneifyAdmin,
  SuneifySellers,
  SuneifyAdminStoreOrder,
  SuneifyAdminStoreProducts,
  SuneifyOrderSummary,
  SuneifyOnboarding,
  SuneifyAccount,
  SuneifyAccountEdit,
  SuneifyAdminStoreVideos,
  SuneifyAdminSuneTV
} from '../../suneify/pages';
import { useRemoteConfig } from '../../context/RemoteConfigProvider';
import useLegacyToSuneifyRedriect from '../../hooks/useLegacyToSuneifyRedriect';
import * as Sentry from '@sentry/react';
import SellerOnboardForm from '../../suneify/pages/AdminOnboardSeller';
import OnboardingForm from '../OnboardingForm';

const SignUp = React.lazy(() => import('../Signup'));
const Login = React.lazy(() => import('../Login'));
const ResetPasswordRequest = React.lazy(() => import('../ResetPasswordRequest'));
const OTPRequest = React.lazy(() => import('../OTPRequest'));
const UpdatePassword = React.lazy(() => import('../UpdatePassword'));
const LiveStream = React.lazy(() => import('../LiveStream'));
const Videos = React.lazy(() => import('../Studio/Videos'));
const Live = React.lazy(() => import('../Studio/Live'));
const Order = React.lazy(() => import('../Store/Order'));
const Product = React.lazy(() => import('../Store/Product'));
const BusinessInformation = React.lazy(() => import('../SellerInformation/BusinessInformation'));
const HostPersonalInformation = React.lazy(() => import('../HostInformation/PersonalInformation'));
const HostAdditionalInformation = React.lazy(
  () => import('../HostInformation/AdditionalInformation')
);
const AdditionalInformation = React.lazy(() => import('../SellerInformation/Additionalnformation'));
const SellerAccountInformation = React.lazy(
  () => import('../EditSellerInformation/AccountInformation')
);
const SellerBusinessInformation = React.lazy(
  () => import('../EditSellerInformation/BusinessInformation')
);
const SellerAdditionalInformation = React.lazy(
  () => import('../EditSellerInformation/AdditionalInformation')
);
const CopyRight = React.lazy(() => import('../Copyright'));
const HostAccountInformation = React.lazy(
  () => import('../EditHostInformation/AccountInformation')
);
const HostEditPersonalInformation = React.lazy(
  () => import('../EditHostInformation/PersonalInformation')
);
const HostEditAdditionalInformation = React.lazy(
  () => import('../EditHostInformation/AdditionalInformation')
);

const App: React.FC = (): JSX.Element => {
  const {
    isAuthed: isUserLogin,
    roleType,
    isUserActive,
    userPermissions
  } = useContext(AuthContext);
  AgoraRTC.setLogLevel(4);

  const { isSuneifyEnabled } = useRemoteConfig();

  useLegacyToSuneifyRedriect({
    isSuneifyEnabled,
    roleType
  });

  const getStoreRoute = () => {
    if (isUserLogin && ROLE_TYPE.SELLER === roleType) {
      if (isUserActive === USER_ACTIVE_TYPE.Y) {
        return <StoreLayout />;
      }
      if (isUserActive === USER_ACTIVE_TYPE.N) {
        return <Navigate to={ROUTES.BUSINESS_INFORMATION} />;
      }
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const getStudioRoute = () => {
    if (isUserLogin) {
      if (isUserActive === USER_ACTIVE_TYPE.Y) {
        return <StudioLayout />;
      }
      if (isUserActive === USER_ACTIVE_TYPE.N && ROLE_TYPE.SELLER === roleType) {
        return <Navigate to={ROUTES.BUSINESS_INFORMATION} />;
      }
      if (isUserActive === USER_ACTIVE_TYPE.N && ROLE_TYPE.HOST === roleType) {
        return <Navigate to={ROUTES.HOST_PERSONAL_INFORMATION} />;
      }
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const getLivestreamRoute = () => {
    if (isUserLogin) {
      if (isUserActive === USER_ACTIVE_TYPE.Y) {
        return (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <LiveStream />
          </React.Suspense>
        );
      }
      if (isUserActive === USER_ACTIVE_TYPE.N && ROLE_TYPE.SELLER === roleType) {
        return <Navigate to={ROUTES.BUSINESS_INFORMATION} />;
      }
      if (isUserActive === USER_ACTIVE_TYPE.N && ROLE_TYPE.HOST === roleType) {
        return <Navigate to={ROUTES.HOST_PERSONAL_INFORMATION} />;
      }
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const getEditSellerRoute = () => {
    if (isUserLogin && ROLE_TYPE.SELLER === roleType) {
      if (isUserActive === USER_ACTIVE_TYPE.Y) {
        return <SellerInformationLayout />;
      }
      if (isUserActive === USER_ACTIVE_TYPE.N) {
        return <Navigate to={ROUTES.BUSINESS_INFORMATION} />;
      }
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const getEditHostRoute = () => {
    if (isUserLogin && ROLE_TYPE.HOST === roleType) {
      if (isUserActive === USER_ACTIVE_TYPE.Y) {
        return <HostInformationLayout />;
      }
      if (isUserActive === USER_ACTIVE_TYPE.N) {
        return <Navigate to={ROUTES.HOST_PERSONAL_INFORMATION} />;
      }
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const getSellerAdminRoute = () => {
    if (isUserLogin && ROLE_TYPE.SELLER_ADMIN === roleType) {
      return (
        <React.Suspense fallback={<Loader isLoading={true} />}>
          <SuneifyAdmin />
        </React.Suspense>
      );
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const mainRoutes = {
    path: '/',
    element: <DefaultLayout isUserLogin={isUserLogin} />,
    children: [
      { path: ROUTES.HOME, element: <Navigate to={isUserLogin ? ROUTES.SUNEIFY : ROUTES.LOGIN} /> },
      { path: ROUTES.HEALTH_CHECK, element: <HealthCheck /> },
      { path: ROUTES.TERMS_OF_SERVICE, element: <TermsOfServicePage /> },
      { path: ROUTES.COMMUNITY_GUIDELINES, element: <CommunityGuidelines /> },
      { path: ROUTES.PRIVACY_STATEMENT, element: <DataPrivacy /> },
      { path: ROUTES.UNAUTHORIZED, element: <Unauthorized /> },
      { path: ROUTES.NOT_FOUND, element: <PageNotFound /> },
      {
        path: ROUTES.LOGIN,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <Login />
          </React.Suspense>
        )
      },
      // Comment out for future usage
      // {
      //   path: ROUTES.SIGNUP,
      //   element: (
      //     <React.Suspense fallback={<Loader isLoading={true} />}>
      //       <SignUp />
      //     </React.Suspense>
      //   )
      // },
      {
        path: ROUTES.RESET_PASSWORD_REQUEST,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <ResetPasswordRequest />
          </React.Suspense>
        )
      },
      {
        path: ROUTES.RESET_PASSWORD_OTP_VERIFICATION,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <OTPRequest />
          </React.Suspense>
        )
      },
      {
        path: ROUTES.RESET_PASSWORD_UPDATE,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <UpdatePassword />
          </React.Suspense>
        )
      },
      // stores page for seller
      {
        path: ROUTES.STORE,
        element: getStoreRoute(),
        children: [
          { path: ROUTES.STORE, element: <Navigate to={ROUTES.PRODUCT} /> },
          {
            path: ROUTES.PRODUCT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <Product />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.ORDER,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <Order />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: ROUTES.STUDIO,
        element: getStudioRoute(),
        children: [
          { path: ROUTES.STUDIO, element: <Navigate to={ROUTES.LIVE} /> },
          {
            path: ROUTES.LIVE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <Live />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.VIDEOS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <Videos />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: ROUTES.LOGIN_CREDENTIALS,
        element:
          isUserLogin && roleType === ROLE_TYPE.SELLER ? (
            <BusinessDescriptionLayout />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          ),
        children: [
          {
            path: ROUTES.LOGIN_CREDENTIALS,
            element: <Navigate to={ROUTES.BUSINESS_INFORMATION} />
          },
          {
            path: ROUTES.BUSINESS_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <BusinessInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.ADDITIONAL_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <AdditionalInformation />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: ROUTES.SELLER_ONBOARDING,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <Onboarding />
          </React.Suspense>
        )
      },
      {
        path: ROUTES.SELLER_ONBOARDING_CREATE,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <OnboardingCreate />
          </React.Suspense>
        )
      },
      {
        path: ROUTES.SELLER_ONBOARDING_FORM,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <OnboardingForm />
          </React.Suspense>
        )
      },
      {
        path: ROUTES.SELLER,
        element: getEditSellerRoute(),
        children: [
          { path: ROUTES.SELLER, element: <Navigate to={ROUTES.SELLER_ACCOUNT_INFORMATION} /> },
          {
            path: ROUTES.SELLER_ACCOUNT_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SellerAccountInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SELLER_BUSINESS_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SellerBusinessInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SELLER_ADDITIONAL_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SellerAdditionalInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SELLER_COPYRIGHT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <CopyRight />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SELLER_TERMS_OF_SERVICE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <TermsOfServicePage />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: ROUTES.LOGIN_CREDENTIALS,
        element:
          isUserLogin && roleType === ROLE_TYPE.HOST ? (
            <HostDescriptionLayout />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          ),
        children: [
          {
            path: ROUTES.LOGIN_CREDENTIALS,
            element: <Navigate to={ROUTES.HOST_PERSONAL_INFORMATION} />
          },
          {
            path: ROUTES.HOST_PERSONAL_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <HostPersonalInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.HOST_ADDITIONAL_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <HostAdditionalInformation />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: ROUTES.HOST,
        element: getEditHostRoute(),
        children: [
          { path: ROUTES.HOST, element: <Navigate to={ROUTES.HOST_ACCOUNT_INFORMATION} /> },
          {
            path: ROUTES.HOST_ACCOUNT_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <HostAccountInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.HOST_EDIT_PERSONAL_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <HostEditPersonalInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.HOST_EDIT_ADDITIONAL_INFORMATION,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <HostEditAdditionalInformation />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.HOST_COPYRIGHT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <CopyRight />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.HOST_TERMS_OF_SERVICE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <TermsOfServicePage />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: `${ROUTES.LIVE}/:eventId`,
        element: getLivestreamRoute()
      },
      // SUNEIFY App
      {
        path: ROUTES.SUNEIFY,
        element: (
          <React.Suspense fallback={<Loader isLoading={true} />}>
            <SuneifyHome />
          </React.Suspense>
        ),
        children: [
          { path: ROUTES.SUNEIFY, element: <Navigate to={ROUTES.SUNEIFY_PRODUCTS} /> },
          {
            path: ROUTES.SUNEIFY_PRODUCTS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyStoreProducts />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_PRODUCTS_CREATE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyProductPage />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_PRODUCTS_VIEW_EDIT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyProductPage />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ORDERS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyStoreOrders />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_LIVE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyStudioLives />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_VIDEOS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyStudioVideos />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_SELLER_INFO,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAccount />
              </React.Suspense>
            )
          },
          {
            path: `${ROUTES.SUNEIFY_ORDER_SUMMARY}/:orderId`,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyOrderSummary />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ONBOARDING,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyOnboarding showHeader showFooter />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_SELLER_INFO_EDIT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAccountEdit />
              </React.Suspense>
            )
          }
        ]
      },
      // admin
      {
        path: ROUTES.SUNEIFY_ADMIN,
        element: getSellerAdminRoute(),
        children: [
          {
            path: ROUTES.SUNEIFY_ADMIN,
            element: <Navigate to={ROUTES.SUNEIFY_ADMIN_MAKERS} />
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_MAKERS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifySellers />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_SUNE_TV,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAdminSuneTV />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_SUNE_TV_EPISODES,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAdminSuneTV />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_PRODUCTS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAdminStoreProducts />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_PRODUCTS_CREATE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyProductPage />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_PRODUCTS_VIEW_EDIT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyProductPage />
              </React.Suspense>
            )
          },

          {
            path: ROUTES.SUNEIFY_ADMIN_ORDERS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAdminStoreOrder />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_LIVE,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyStoreProducts />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_VIDEOS,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAdminStoreVideos />
              </React.Suspense>
            )
          },
          {
            path: `${ROUTES.SUNEIFY_ADMIN_ORDER_SUMMARY}/:orderId`,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                {/* TODO maybe refactor the ui */}
                <SuneifyOrderSummary />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_INFO,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAccount />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_INFO_EDIT,
            element: (
              <React.Suspense fallback={<Loader isLoading={true} />}>
                <SuneifyAccountEdit />
              </React.Suspense>
            )
          },
          {
            path: ROUTES.SUNEIFY_ADMIN_ONBOARD_SELLER,
            element:
              isUserLogin && userPermissions.includes(USER_PERMISSIONS.ADMIN) ? (
                <React.Suspense fallback={<Loader isLoading={true} />}>
                  <SellerOnboardForm />
                </React.Suspense>
              ) : (
                <Navigate to={ROUTES.LOGIN} />
              )
          }
        ]
      },
      { path: '*', element: <Navigate to={ROUTES.NOT_FOUND} /> }
    ]
  };

  const routing = useRoutes([mainRoutes]);

  return (
    <>
      <Sentry.ErrorBoundary fallback={<Loader isLoading={true} />}>
        {routing}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Sentry.ErrorBoundary>
    </>
  );
};

export default App;
