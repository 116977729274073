import '../../index.css';

import { useTranslation } from 'react-i18next';

import CopyRight from '../../components/CopyRights';
import ShimmerLoader from '../../components/Shimmer';
import Typography from '../../components/Typography';
import { PrivacyStatementsShimmerConfig } from '../../config/shimmerSetup';
import { usePrivacyPolicy } from '../../hooks/usePrivacyPolicy';

const DataPrivacy = () => {
  const { t } = useTranslation('form');
  const { data: renderedHTML, isLoading } = usePrivacyPolicy();

  return (
    <div className="flex flex-col ml-38.5 community-guidelines-custom-class">
      <Typography variant="h3" className="mt-15">
        {t('privacyStatement')}
      </Typography>
      {isLoading ? (
        <div className="w-205">
          <ShimmerLoader shimmerConfig={PrivacyStatementsShimmerConfig} />
        </div>
      ) : (
        <div
          className="mt-5 mb-10 w-163 text-justify gap-y-2.5"
          dangerouslySetInnerHTML={{ __html: renderedHTML?.data as unknown as string }}
        />
      )}
      <CopyRight className="mb-15 w-96" />
    </div>
  );
};

export default DataPrivacy;
