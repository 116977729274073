const SellerAgreement = () => {
  return (
    <div>
      <h2>Live Shop Seller Agreement</h2>
      <h3>Deal Terms Addendum</h3>
      <p>
        This Deal Terms Addendum (this “Addendum”) is made effective as of ___________________ (the
        “Addendum Effective Date”) between Live Shop Ventures, LLC (“Live Shop”), and
        _________________________________ (“Seller”). This Addendum is part of and subject to the
        Live Shop Seller Agreement between Live Shop and Seller (the “Agreement”). Any capitalized
        term not defined in this Order Form shall have the meaning set forth in the Agreement.
      </p>

      <h3>1. Commission</h3>
      <p>
        “Commission” means the ten percent (10%) fee, calculated based on the price paid by a
        Customer for each Product (excluding any taxes, and Return Costs), earned by Live Shop in
        connection with each Product purchased by a Customer.
      </p>

      <h3>2. Shipping and Handling</h3>
      <p>
        “Shipping and Handling Costs” means all costs to process and ship an Order from Seller to a
        Customer for which Seller is responsible. Shipping labels will not be provided by Live Shop
        and should be secured by Seller’s choice of courier, so long as tracking information can be
        remitted to Live Shop.
      </p>
      <p>
        All Ordered Products shall be transferred to the parcel shipper within 48 hours of Seller’s
        receipt of an Order.
      </p>

      <h3>3. Returns</h3>
      <p>
        “Return Costs” means all costs to ship an Order from a Customer back to Seller, to be paid
        by Live Shop. Labels for return shipments will be provided by Live Shop to the Customer.
      </p>

      <h3>4. Other Costs and Fees</h3>
      <p>None.</p>

      <h3>5. Seller Onboarding</h3>
      <p>Prior to going live, Seller will complete integration pursuant to the Agreement.</p>

      <h3>6. Products</h3>
      <p>Inventory and pricing will be synced to Seller’s website via direct integration.</p>

      <h3>7. Fulfillment Service Level</h3>
      <p>
        “Fulfillment SLA” means that Seller will ship all Orders within two (2) days of Seller’s
        receipt, unless a different timeline has been previously disclosed to and approved by Live
        Shop. In the event that a Product is ordered which Seller has no inventory to fulfill,
        Seller shall cancel the order within 24 hours.
      </p>

      <h3>8. Returns Process</h3>
      <p>
        Live Shop may provide Customers the ability to return Ordered Products up to thirty (30)
        days from the date of a Customer’s receipt of the Product, unless otherwise agreed between
        Live Shop and Seller. In such cases, Seller will provide Live Shop with the Seller’s return
        policy and acknowledge that Live Shop may override the Seller’s policy at its own expense.
        In the event of a return, Seller shall allow Customers to request (a) a Return for Cause, or
        (b) replacement of any damaged or defective Ordered Product received by a Customer.
      </p>

      <h3>9. Returns Shipping</h3>
      <p>
        Live Shop shall bear the Return Costs for shipping for Discretionary Returns unless
        otherwise provided in Seller’s standard policies.
      </p>

      <h3>10. Compliance</h3>
      <p>
        Sellers that, in any continuous 12-month period during a 24-month period, have (a) entered
        into 200 or more discrete sales or transactions of new or unused consumer products, and (b)
        an aggregate total of $5,000 or more in gross revenues, are considered “High-Volume
        Sellers”. High-Volume Sellers are subject to additional requirements for legal compliance
        purposes.
      </p>
    </div>
  );
};

export default SellerAgreement;
