import classNames from 'classnames';
import * as React from 'react';

interface Props {
  label: string;
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
}
const StepComponent: React.FC<Props> = ({ label, isActive, isFirst, isLast }) => {
  return (
    <div className="flex flex-row justify-start items-center">
      <div
        className={classNames(
          'flex items-center justify-start w-full h-16 p-5',
          isActive
            ? 'from-yellow-150 via-yellow-125 to-yellow-150 bg-gradient-to-br text-stone-850'
            : 'bg-white text-gray-550',
          isFirst && 'rounded-t-2.5xl',
          isLast && 'rounded-b-2.5xl'
        )}
      >
        <p className="text-1.1.lg tracking-normal font-semibold">{label}</p>
      </div>
    </div>
  );
};

export default StepComponent;
