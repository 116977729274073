export type RemoteConfigModel = {
  isSuneifyEnabled: boolean;
  isDraftsEnabled: boolean;
  isSuneTVEnabled: boolean;
  isOneAccountEnabled: boolean;
};

// Give the flag a default value.
export const DEFAULT_FLAGS: RemoteConfigModel = {
  isSuneifyEnabled: false,
  isDraftsEnabled: false,
  isSuneTVEnabled: false,
  isOneAccountEnabled: false
};
