export interface IOrderReportResponse {
  id: number;
  startDate: string;
  endDate: string;
  channel: string;
  status: OrderReportStatus;
}

export interface IOrderJobRespose extends IOrderReportResponse {
  url: string;
  createdAt: string;
  updatedAt: string;
  error: string;
}

export enum OrderReportStatus {
  IN_PROGRESS = 'IN-PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
