import { useQuery } from '@tanstack/react-query';

import { API_ROUTES, BASE_ROUTES } from '../../constants/apiRoutes';
import { INTERCEPTOR_SKIP_HEADER } from '../../constants/common';
import { IHealthCheck, Response, RouteConfig } from '../../types';
import { makeRequest } from '../../utils';

export const useHealthCheck = () => {
  const config: RouteConfig = {
    path: `${BASE_ROUTES.MANAGEMENT}${API_ROUTES.HEALTH_CHECK}`,
    headers: { [INTERCEPTOR_SKIP_HEADER]: 'true' },
    method: 'GET'
  };

  const { data, isLoading, isError } = useQuery(
    [BASE_ROUTES.MANAGEMENT, API_ROUTES.HEALTH_CHECK],
    () => makeRequest<Response<IHealthCheck[]>>(config),
    {
      refetchInterval: 5000,
      cacheTime: 0,
      select: (response) => {
        return response.data || [];
      }
    }
  );

  return {
    data,
    isLoading,
    isError
  };
};
