import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { TFunction } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { errorCode } from '../../config/login';
import { API_ROUTES } from '../../constants/apiRoutes';
import {
  AUTHORIZATION_HEADER,
  INTERCEPTOR_SKIP_HEADER,
  REFRESH_TOKEN_HEADER,
  USER_CHANNEL
} from '../../constants/common';
import { TOAST_INFORMATION_TIME, USER_STATE } from '../../constants/login';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import {
  EmptyObject,
  requiredAdminPermissions,
  ROLE_TYPE,
  RouteConfig,
  SignupResponseType,
  USER_PERMISSIONS
} from '../../types';
import { Response, ResponseError } from '../../types/APIResponseType';
import { LoginResponse } from '../../types/LoginResponseType';
import { IProvideAuth } from '../../types/ProvideAuthType';
import { useRemoteConfig } from '../../context/RemoteConfigProvider';
import { makeRequest } from '../../utils/axios';
import { addSecondsToDate } from '../../utils/formatTime';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';

interface JWTPayload {
  'https://qlive.com/role'?: string;
  'https://qlive.com/userId'?: string;
  'https://qlive.com/roleSpecificId'?: string;
  iss?: string;
  sub?: string;
  aud?: string[];
  iat?: number;
  exp?: number;
  scope?: string;
  gty?: string;
  azp?: string;
  permissions?: string[];
}
export const useProvideAuth = (
  {
    loginForm,
    signupForm,
    resetPasswordRequestForm,
    otpVerificationForm,
    updatePasswordForm
  }: IProvideAuth,
  t?: TFunction
) => {
  const {
    setIsAuthed,
    setAccessToken,
    setRefreshToken,
    setRoleSpecificId,
    setRoleType,
    setUserId,
    refreshToken,
    setIsUserActive,
    setTokenExpiryTime,
    accessToken,
    setUserPermissions
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const { isSuneifyEnabled } = useRemoteConfig();
  const loginRequest: RouteConfig = {
    path: API_ROUTES.LOGIN,
    method: 'POST',
    headers: { [INTERCEPTOR_SKIP_HEADER]: 'true' },
    data: { ...loginForm, userChannel: USER_CHANNEL }
  };

  const signUpRequest: RouteConfig = {
    path: API_ROUTES.SIGNUP,
    method: 'POST',
    headers: { [INTERCEPTOR_SKIP_HEADER]: 'true' },
    data: { ...signupForm, userChannel: USER_CHANNEL }
  };

  const logoutRequest: RouteConfig = {
    path: API_ROUTES.LOGOUT,
    method: 'POST',
    headers: {
      [REFRESH_TOKEN_HEADER]: refreshToken
    }
  };

  const resetPasswordRequest: RouteConfig = {
    path: API_ROUTES.RESET_PASSWORD_REQUEST,
    method: 'POST',
    headers: { [INTERCEPTOR_SKIP_HEADER]: 'true' },
    data: { ...resetPasswordRequestForm, userChannel: USER_CHANNEL }
  };

  const resetPasswordOTPVerificationRequest: RouteConfig = {
    path: API_ROUTES.RESET_PASSWORD_OTP_VERIFICATION,
    method: 'POST',
    headers: { [INTERCEPTOR_SKIP_HEADER]: 'true' },
    data: { ...otpVerificationForm, userChannel: USER_CHANNEL }
  };

  const updatePasswordRequest: RouteConfig = {
    path: API_ROUTES.RESET_PASSWORD_UPDATE,
    method: 'PATCH',
    headers: { [INTERCEPTOR_SKIP_HEADER]: 'true' },
    data: { ...updatePasswordForm, userChannel: USER_CHANNEL }
  };

  const {
    mutate: login,
    isLoading: isLoginLoading,
    error: loginError
  } = useMutation<Response<LoginResponse>, ResponseError>(() => makeRequest(loginRequest), {
    onSuccess: (res) => {
      localStorage.clear();
      setIsAuthed(true);
      const decoded: JWTPayload = jwtDecode(res.data?.accessToken);
      const isAdmin = requiredAdminPermissions.every(
        (permission) => decoded && decoded?.permissions?.includes(permission)
      );
      const userRole = isAdmin
        ? ROLE_TYPE.SELLER_ADMIN
        : decoded?.permissions?.some(
            (singlePermission) => singlePermission === USER_PERMISSIONS.HOST
          )
        ? ROLE_TYPE.HOST
        : ROLE_TYPE.SELLER;

      if (decoded?.permissions && !isEmpty(decoded?.permissions)) {
        setUserPermissions(decoded?.permissions);
      }
      setRoleType(userRole);
      // TODO this should be in cookie
      setAccessToken(res.data?.accessToken);
      setRefreshToken(res.data?.refreshToken);
      setRoleSpecificId(res.data?.roleSpecificId);
      setUserId(res.data?.userId);
      setIsUserActive(res.data?.isUserActive);
      setTokenExpiryTime(addSecondsToDate(res.data?.expiresIn));
      // try {
      //   const addSellerToBraze: RouteConfig = {
      //     path: API_ROUTES.SELLER_ADD_TO_BRAZE,
      //     method: 'POST',
      //     headers: { [AUTHORIZATION_HEADER]: accessToken },
      //     data: { userId: res.data?.userId, emailAddress: loginForm?.email }
      //   };
      //   makeRequest(addSellerToBraze);
      // } catch (error) {
      //   console.error('Error e: ' + error);
      // }
      // skip userAdmin, not active.
      if (userRole === ROLE_TYPE.SELLER_ADMIN) {
        navigate(ROUTES.SUNEIFY_ADMIN);
      } else if (res.data.isUserActive === USER_STATE.userNotActive && t) {
        toast.info(t('toastInformationMessage'), {
          autoClose: TOAST_INFORMATION_TIME
        });
        navigate(
          res.data.roleType === ROLE_TYPE.SELLER ? ROUTES.SELLER_ONBOARDING_FORM : ROUTES.SUNEIFY
        );
      } else {
        navigate(userRole === ROLE_TYPE.SELLER ? ROUTES.SUNEIFY : ROUTES.STUDIO);
      }
    },
    onError: (error) => {
      if (error.code === errorCode.AUTH_005) {
        t && toast.error(t('invalidCredentialError', { ns: 'httpMessage' }));
      } else {
        toast.error(error.message);
      }
    }
  });

  const { mutate: loginAfterSignup } = useMutation<Response<LoginResponse>, ResponseError>(
    () => makeRequest(loginRequest),
    {
      onSuccess: (res) => {
        setIsAuthed(true);
        setAccessToken(res?.data?.accessToken);
        const decoded: JWTPayload = jwtDecode(res.data?.accessToken);

        if (decoded?.permissions && !isEmpty(decoded?.permissions)) {
          setUserPermissions(decoded?.permissions);
        }
        setRefreshToken(res?.data?.refreshToken);
        setRoleSpecificId(res.data?.roleSpecificId);
        setRoleType(res.data?.roleType);
        setUserId(res.data?.userId);
        setIsUserActive(res.data?.isUserActive);
        setTokenExpiryTime(addSecondsToDate(res.data?.expiresIn));
        Sentry.setUser({
          id: res?.data?.userId
        });
        if (isSuneifyEnabled) {
          navigate(
            signupForm?.roleType === ROLE_TYPE.SELLER
              ? ROUTES.SUNEIFY_ONBOARDING
              : ROUTES.HOST_PERSONAL_INFORMATION
          );
        } else {
          navigate(
            signupForm?.roleType === ROLE_TYPE.SELLER
              ? ROUTES.BUSINESS_INFORMATION
              : ROUTES.HOST_PERSONAL_INFORMATION
          );
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const {
    mutate: register,
    isLoading: isRegisterLoading,
    error: registerError
  } = useMutation<Response<SignupResponseType>, ResponseError>(() => makeRequest(signUpRequest), {
    onSuccess: () => {
      loginAfterSignup();
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const logout = () => {
    logoutMutate();
  };

  const {
    mutate: logoutMutate,
    isLoading: isLogoutLoading,
    error: logoutError
  } = useMutation<Response<EmptyObject>, ResponseError>(() => makeRequest(logoutRequest), {
    onSuccess: () => {
      localStorage.clear();
      setIsAuthed(false);
      navigate(ROUTES.LOGIN);
    },
    onError: (error) => {
      localStorage.clear();
      setIsAuthed(false);
      navigate(ROUTES.LOGIN);
      toast.error(error.message);
    }
  });

  const {
    mutate: resetPassword,
    isLoading: isResetPasswordLoading,
    error: resetPasswordRequestError
  } = useMutation<Response<EmptyObject>, ResponseError>(() => makeRequest(resetPasswordRequest), {
    onSuccess: () => {
      navigate(ROUTES.RESET_PASSWORD_OTP_VERIFICATION, {
        state: {
          email: resetPasswordRequestForm?.email
        }
      });
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const {
    mutate: resetPasswordOTPVerification,
    isLoading: isResetPasswordOTPVerificationLoading,
    error: resetPasswordOTPVerificationError
  } = useMutation<Response<EmptyObject>, ResponseError>(
    () => makeRequest(resetPasswordOTPVerificationRequest),
    {
      onSuccess: () => {
        navigate(ROUTES.RESET_PASSWORD_UPDATE, {
          state: {
            email: otpVerificationForm?.email,
            otp: otpVerificationForm?.otp
          }
        });
      },
      onError: (error) => {
        toast.error(error.message);
        if (['AUTH_037', 'AUTH_038'].includes(error.code)) {
          navigate(ROUTES.RESET_PASSWORD_REQUEST);
        }
      }
    }
  );

  const {
    mutate: updatePassword,
    isLoading: isUpdatePasswordLoading,
    error: updatePasswordError
  } = useMutation<Response<EmptyObject>, ResponseError>(() => makeRequest(updatePasswordRequest), {
    onSuccess: () => {
      navigate(ROUTES.LOGIN);
      toast.success(t && t('forgotPasswordSuccessMsg', { ns: 'forgotPassword' }));
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const { mutateAsync: fetchRefreshToken, isLoading: isRefreshingToken } = useMutation<
    Response<LoginResponse>,
    ResponseError
  >(
    () => {
      const refreshTokenRequest: RouteConfig = {
        path: API_ROUTES.REFRESH_TOKEN,
        method: 'POST',
        headers: {
          [REFRESH_TOKEN_HEADER]: localStorage.getItem('refreshToken') ?? '',
          [INTERCEPTOR_SKIP_HEADER]: 'true',
          ['Ocp-Apim-Subscription-Key']: process.env.REACT_APP_OCP_API_KEY ?? ''
        },
        data: {
          accessToken: accessToken
        }
      };
      return makeRequest(refreshTokenRequest);
    },
    {
      onSuccess: (res) => {
        setAccessToken(res.data?.accessToken);
        setRefreshToken(res.data?.refreshToken);
        setTokenExpiryTime(addSecondsToDate(res.data?.expiresIn));
      },
      onError: () => {
        setIsAuthed(false);
        localStorage.clear();
        navigate(ROUTES.LOGIN);
      }
    }
  );

  return {
    login,
    isLoginLoading,
    loginError,
    register,
    isRegisterLoading,
    registerError,
    logout,
    isLogoutLoading,
    logoutError,
    resetPassword,
    isResetPasswordLoading,
    resetPasswordRequestError,
    resetPasswordOTPVerification,
    isResetPasswordOTPVerificationLoading,
    resetPasswordOTPVerificationError,
    updatePassword,
    isUpdatePasswordLoading,
    updatePasswordError,
    fetchRefreshToken,
    isRefreshingToken
  };
};
