import { useAppDispatch, useAppSelector } from '..';
import { ICategory } from '../../suneify/types/Category';
import { ICategoryListItem } from '../../suneify/types/CategoryList';
import { ITagForm } from '../../suneify/types/TagForm';
import { ITagResponse } from '../../suneify/types/TagResponse';
import { State } from '../../types';
import {
  storeBusinessCategoryInfo,
  storeCategoryInfo,
  storeUSStateInfo,
  storeTagsInfo
} from '../slices/appCommonSlice/appCommonSlice';

interface AppCommonHook {
  categoryData: ICategoryListItem[];
  usStateData: State[];
  businessCategoryData: string[];
  tagsData: ITagForm[];
  storeCategoryDataInfoToRedux: (categoryData: ICategory[]) => void;
  storeUSStateDataInfoToRedux: (stateData: State[]) => void;
  storeBusinessCategoryDataInfoToRedux: (businessCategoryData: string[]) => void;
  storeTagsDataIntoRedux: (tagsData: ITagResponse[]) => void;
}
const useAppCommonHook = (): AppCommonHook => {
  const dispatch = useAppDispatch();
  const { categoryData, businessCategoryData, usStateData, tagsData } = useAppSelector(
    (state) => state?.appCommonData?.value
  );

  const storeCategoryDataInfoToRedux = (categoryData: ICategory[]) =>
    dispatch(storeCategoryInfo(categoryData));

  const storeTagsDataIntoRedux = (tagsData: ITagResponse[]) => dispatch(storeTagsInfo(tagsData));

  const storeUSStateDataInfoToRedux = (stateData: State[]) => dispatch(storeUSStateInfo(stateData));

  const storeBusinessCategoryDataInfoToRedux = (businessCategoryData: string[]) =>
    dispatch(storeBusinessCategoryInfo(businessCategoryData));

  return {
    storeTagsDataIntoRedux,
    storeCategoryDataInfoToRedux,
    storeUSStateDataInfoToRedux,
    storeBusinessCategoryDataInfoToRedux,
    categoryData,
    usStateData,
    businessCategoryData,
    tagsData
  };
};

export default useAppCommonHook;
