import OnboardingForm from '../../components/OnboardingForm/OnboardingForm';
import usePrefetch from '../../suneify/hooks/useAppHook/usePrefetchAppInfo';

const OnboardingFormPage = () => {
  const { sellerInfoLoading, stateInfoLoading, businessCategoryLoading } = usePrefetch();
  if (!sellerInfoLoading && !stateInfoLoading && !businessCategoryLoading) {
    return <OnboardingForm showHeader showFooter />;
  }
  return <></>;
};

export default OnboardingFormPage;
