import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '../../constants/apiRoutes';
import { QUERY_TIME } from '../../constants/queryTime';
import { RouteConfig, State, Timezone } from '../../types';
import { Response } from '../../types/APIResponseType';
import { makeRequest } from '../../utils';
import useAppCommonHook from '../../reduxStore/hooks/useAppCommonHook';
import { isEmpty } from 'lodash';

export const fetchBusinessCategoryInformation = async (): Promise<Response<string[]>> => {
  const routeconfig: RouteConfig = {
    method: 'GET',
    path: `${API_ROUTES.BUSINESS_CATEGORY}`
  };
  return makeRequest<Response<string[]>>(routeconfig);
};

export const useFetchBusinessCategoryInformation = () => {
  const accessToken = localStorage.getItem('accessToken');
  const { storeBusinessCategoryDataInfoToRedux } = useAppCommonHook();
  const {
    data: businessCategoryInformationData,
    error: businessCategoryInformationError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(['businessCategoryInformation'], fetchBusinessCategoryInformation, {
    select: (businessCategoryData) => {
      return businessCategoryData.data;
    },
    onSuccess(data) {
      if (!isEmpty(data)) {
        storeBusinessCategoryDataInfoToRedux(data);
      }
    },
    retry: 3,
    enabled: accessToken !== null,
    refetchOnWindowFocus: false
  });

  return {
    businessCategoryInformationData,
    businessCategoryInformationError,
    isLoading,
    isFetching,
    refetch
  };
};

export const fetchStateInformation = async (): Promise<Response<State[]>> => {
  const routeconfig: RouteConfig = {
    method: 'GET',
    path: `${API_ROUTES.STATES}`
  };
  return makeRequest<Response<State[]>>(routeconfig);
};

export const useFetchStateInformation = () => {
  const accessToken = localStorage.getItem('accessToken');

  const { storeUSStateDataInfoToRedux } = useAppCommonHook();

  const {
    data: stateInformationData,
    error: stateInformationError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(['stateInformation'], fetchStateInformation, {
    select: (stateData) => {
      // Make a shallow copy of the array before sorting
      const sortedData = [...stateData?.data]?.sort((a, b) =>
        a?.stateName > b?.stateName ? 1 : b?.stateName > a?.stateName ? -1 : 0
      );
      return sortedData;
    },
    onSuccess(data) {
      if (!isEmpty(data)) {
        storeUSStateDataInfoToRedux(data);
      }
    },
    retry: 3,
    enabled: accessToken !== null,
    staleTime: QUERY_TIME.STALE_TIME,
    cacheTime: QUERY_TIME.CACHE_TIME
  });

  return {
    stateInformationData,
    stateInformationError,
    isLoading,
    isFetching,
    refetch
  };
};

export const fetchTimezoneInformation = async (): Promise<Response<Timezone[]>> => {
  const routeconfig: RouteConfig = {
    method: 'GET',
    path: `${API_ROUTES.TIMEZONES}`
  };
  return makeRequest<Response<Timezone[]>>(routeconfig);
};

export const useFetchTimezoneInformation = () => {
  const accessToken = localStorage.getItem('accessToken');
  const {
    data: timezoneInformationData,
    error: timezoneInformationError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(['timezoneInformation'], fetchTimezoneInformation, {
    select: (timezoneData) => {
      return timezoneData.data;
    },
    enabled: accessToken !== null,
    staleTime: QUERY_TIME.STALE_TIME,
    cacheTime: QUERY_TIME.CACHE_TIME
  });

  return {
    timezoneInformationData,
    timezoneInformationError,
    isLoading,
    isFetching,
    refetch
  };
};
