import axios, { AxiosError, AxiosHeaders } from 'axios';
import { logEvent } from 'firebase/analytics';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { INTERCEPTOR_SKIP_HEADER, TOKEN_REFRESH_THRESHOLD } from '../../constants/common';
import { REFRESH_TOKEN, TOKEN_EXPIRY_TIME } from '../../constants/localStorage';
import { ROUTES } from '../../constants/routes';
import { useAnalytics } from '../../context/AnalyticsProvider';
import { AuthContext } from '../../context/AuthContext';
import { useProvideAuth } from '../../hooks/useProvideAuth';
import { getDifferencBetweenTimestampInSec } from '../../utils';
import { extractErrorMessage } from '../../utils/firebaseInitializer';
import instance from './instance';

const AxiosInterceptor = () => {
  const navigate = useNavigate();
  const { setIsAuthed } = useContext(AuthContext);
  const analytics = useAnalytics();
  const { fetchRefreshToken } = useProvideAuth({});

  let failedQueue: { resolve: (value?: unknown) => void; reject: (reason?: unknown) => void }[] =
    [];
  let isUpdating = false;

  const processQueue = (error: unknown) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve();
      }
    });
    failedQueue = [];
  };

  function responseInterceptor() {
    const interceptor = instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err: AxiosError) => {
        logEvent(analytics, 'SELLER_API_ERROR', {
          method: err.config?.method,
          status: err.response?.status,
          api_url: err.config?.url,
          error_message: extractErrorMessage(err?.response?.data as Record<string, string>)
        });

        if (err.response?.status === 401) {
          setIsAuthed(false);
          localStorage.clear();
          navigate(ROUTES.LOGIN);
        }
        return Promise.reject(err.response?.data);
      }
    );
    return interceptor;
  }

  useEffect(() => {
    const interceptor = responseInterceptor();
    return () => {
      instance.interceptors.response.eject(interceptor);
    };
  }, []);

  function requestInterceptor() {
    const reqInterceptor = instance.interceptors.request.use(
      async (config) => {
        const tokenExpiryTime = localStorage.getItem(TOKEN_EXPIRY_TIME) || 0;
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        const remainingTokenExpiryTime = getDifferencBetweenTimestampInSec(
          Date.now(),
          +tokenExpiryTime
        );
        if (
          refreshToken &&
          remainingTokenExpiryTime < TOKEN_REFRESH_THRESHOLD &&
          config.headers &&
          !config.headers[INTERCEPTOR_SKIP_HEADER]
        ) {
          if (!isUpdating) {
            isUpdating = true;
            await fetchRefreshToken();
            processQueue(null);
            isUpdating = false;
          } else {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then(() => {
                return instance(config);
              })
              .catch((err) => {
                return err;
              });
          }
        }
        if (config.headers) {
          config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_OCP_API_KEY ?? '';
        } else {
          config.headers = new AxiosHeaders({
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_KEY ?? ''
          });
        }
        const token = localStorage.getItem('accessToken');
        if (token && config.headers && !config.headers[INTERCEPTOR_SKIP_HEADER]) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return reqInterceptor;
  }

  useEffect(() => {
    const reqInterceptor = requestInterceptor();
    return () => {
      instance.interceptors.request.eject(reqInterceptor);
    };
  }, []);

  return null;
};

export default AxiosInterceptor;
