export const NAVIGATION_TABS = {
  STORE: 'storeLabel',
  STUDIO: 'studioLabel',
  PRODUCT: 'productLabel',
  ORDER: 'orderLabel',
  LIVE: 'liveLabel',
  VIDEOS: 'videosLabel',
  SCHEDULED: 'scheduledLabel',
  LIVESTREAMED: 'livestreamedLabel',
  CANCELLED: 'cancelledLabel',
  SELLER_ACCOUNT_INFORMATION: 'myAccountLabel',
  SELLER_BUSINESS_INFORMATION: 'myBusinessLabel',
  SELLER_PAYMENT_INFORMATION: 'myPaymentLabel',
  SELLER_ADDITIONAL_INFORMATION: 'additionalInformationLabel',
  TERMS_OF_SERVICE: 'termsOfServiceLabel',
  COPYRIGHT: 'copyrightLabel',
  HOST_ACCOUNT_INFORMATION: 'myAccountLabel',
  HOST_PERSONAL_INFORMATION: 'personalInformationLabel',
  HOST_ADDITIONAL_INFORMATION: 'additionalInformationLabel',
  SELLER_LOGIN: 'sellerLabel',
  HOST_LOGIN: 'hostLabel'
};
