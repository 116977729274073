import { VariantType } from '../../types/VariantType';

const permutations = (
  categories: VariantType[],
  index: number,
  current: Record<string, string>,
  result: Record<string, string>[]
): void => {
  if (index === categories.length) {
    result.push({ ...current });
    return;
  }

  const category = categories[index];
  if (category.values.length === 0) {
    permutations(categories, index + 1, current, result);
    return;
  }

  for (const value of category.values) {
    current[category.name] = value;
    permutations(categories, index + 1, current, result);
    delete current[category.name];
  }
};

const generatePermutations = (data: VariantType[]): Record<string, string>[] => {
  const result: Record<string, string>[] = [];
  permutations(data, 0, {}, result);
  return result;
};

export default generatePermutations;
