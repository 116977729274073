export enum ROLE_TYPE {
  CUSTOMER = 'Customer',
  HOST = 'Host',
  SELLER = 'Seller',
  ADMIN = 'Admin',
  SELLER_ADMIN = 'SellerAdmin'
}

export enum HOST_TYPE {
  SELLER = 'SELLER',
  HOST = 'HOST',
  CUSTOMER = 'CUSTOMER'
}

export enum USER_PERMISSIONS {
  SELLER_ADMIN = 'SellerAdmin',
  //for cortina user
  SELLER = 'Seller',
  HOST = 'Host',
  // for non cortina user
  SUNEIFY_SELLER = 'SuneifySeller',
  // sune admin
  ADMIN = 'Admin'
}

export const requiredAdminPermissions = [
  'create:Seller',
  'read:AllOrders',
  'read:AllProducts',
  'read:AllSeller',
  'read:AllVideos',
  'update:AllSeller',
  'update:AllVideos'
];
