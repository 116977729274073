import React, { useState } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import LandingImages from './LandingImages';
import { customTheme } from '../../constants/theme';
import { CopyRightAction } from '../../../constants/common';
import LegalStatement from '../LegalStatement';
import ModalWrapper from '../ModalWrapper';
import { ButtonCore, TypographyCore } from '../Core';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Footer from './Footer';
import { displayText, HelpSupportModal } from '../HelpSupportModal/HelpSupportModal';

interface LandingPageLayout {
  children: React.ReactNode;
}

const LandingPageLayout = ({ children }: LandingPageLayout): JSX.Element => {
  const [isPopUpOpen, setDialogOpen] = useState(false);
  const [displayedPopup, setDisplayedPopup] = useState('');

  const setDisplayInnerHTML = (page: string) => {
    setDialogOpen(true);
    setDisplayedPopup(page);
  };
  const handleCloseModal = () => {
    setDialogOpen(false);
  };
  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange theme={customTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s' // set to `none` to disable transition
          }
        }}
      />
      <Box component={'main'}>
        <LandingImages />

        <Box
          sx={() => ({
            width: { xs: '100%', md: '60vw' },
            transition: 'width var(--Transition-duration)',
            transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            backgroundColor: 'common.white'
          })}
        >
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              minHeight: '90dvh',
              m: 'auto',
              width: 400,
              '& form': {
                gap: 2
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden'
              }
            }}
          >
            {children}
          </Stack>
          <Footer
            handleLinkClick={(action) => {
              setDisplayInnerHTML(action);
            }}
          />
        </Box>
      </Box>
      {displayedPopup === CopyRightAction.HelpSupport && (
        <HelpSupportModal isModalOpen={isPopUpOpen} onClose={handleCloseModal} />
      )}
      {displayedPopup !== CopyRightAction.HelpSupport && displayedPopup && (
        <ModalWrapper
          isModalOpen={isPopUpOpen}
          onClose={handleCloseModal}
          minWidth="30%"
          minHeight="0%"
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ position: 'relative', my: 3 }}
          >
            <TypographyCore level="h3">{displayText[`${displayedPopup}`]}</TypographyCore>
            <ButtonCore
              onClick={handleCloseModal}
              variant="plain"
              sx={{ position: 'absolute', top: -5, right: 0 }}
            >
              <CloseRoundedIcon sx={{ width: 30, height: 30, color: 'common.black' }} />
            </ButtonCore>
          </Stack>
          <LegalStatement displayedPopup={displayedPopup} />
        </ModalWrapper>
      )}
    </CssVarsProvider>
  );
};

export default LandingPageLayout;
