import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { AUTHORIZATION_HEADER } from '../../constants/common';

interface ICreateBusinessInfo {
  legalBusinessName: string;
  taxIdentificationNumber: string;
  shopName: string;
  businessCategory: string;
  streetAddress: string;
  apartmentOrSuite: string;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  preferredContactEmail: string;
  cellPhoneNumber: string;
}

const postBusinessInfo = async (
  businessInfoData: Partial<ICreateBusinessInfo>,
  accessToken: string
): Promise<Response<any>> => {
  return await makeRequest({
    path: API_ROUTES.CREATE_BUSINESS_INFO,
    method: 'POST',
    headers: { [AUTHORIZATION_HEADER]: accessToken },
    data: businessInfoData
  });
};

export const useCreateBusinessInfo = () => {
  const { accessToken } = useContext(AuthContext);

  const {
    mutateAsync: createBusinessInfoAsync,
    isLoading: isDataUploading,
    isSuccess: isDataSuccessfullyUploaded
  } = useMutation({
    mutationFn: (businessInfoData: Partial<ICreateBusinessInfo>) =>
      postBusinessInfo(businessInfoData, accessToken),
    onError: (error: unknown) => {
      console.error(error);
    }
  });

  return {
    createBusinessInfoAsync,
    isDataUploading,
    isDataSuccessfullyUploaded
  };
};
