export function convertDurationToTime(time: number) {
  if (time > 0) {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }
}

export function convertTimeFormatToString(time: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) {
  return `${time.hours.toString().padStart(2, '0')}:${time.minutes
    .toString()
    .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`;
}

export function getRemainingDays(time: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) {
  return `${time.days.toString().padStart(2, '0')}`;
}

export function addSecondsToDate(sec: number) {
  return Date.now() + sec * 1000;
}

export function getDifferencBetweenTimestampInSec(startTimestamp: number, endTimestamp: number) {
  return Math.floor((endTimestamp - startTimestamp) / 1000);
}

export function convertTimeFromESTToLocal(datetime: string) {
  const date = new Date(datetime);
  // Convert from EST (-0500) to local timezone
  const localTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localTime.toISOString().slice(0, 16); // Return only the datetime part without seconds and timezone
}
