import { FormControl, FormLabel, Input } from '@mui/joy';
import { SxProps } from '@mui/material';
import { FieldValues, UseFormRegister, Path } from 'react-hook-form';
import { TypographyCore } from '../../suneify/components/Core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Status } from '../OnboardingForm/OnboardingForm';

interface TextInputProps<T extends FieldValues> {
  label: string;
  placeholder: string;
  register: UseFormRegister<T>;
  name: Path<T>;
  error?: string;
  width?: string | number;
  sx?: SxProps;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autosaved?: Status;
  dataTestId?: string;
}

const FormTextInput = <T extends FieldValues>({
  label,
  placeholder,
  register,
  name,
  error,
  width,
  sx,
  type = 'text',
  onChange,
  autosaved,
  dataTestId
}: TextInputProps<T>) => {
  const { onChange: registerOnChange, ...registerRest } = register(name);
  return (
    <FormControl>
      <FormLabel>
        <TypographyCore fontWeight={600}>{label}</TypographyCore>
      </FormLabel>
      <Input
        data-testid={dataTestId}
        onChange={(e) => {
          if (registerOnChange) registerOnChange(e);
          if (onChange) onChange(e);
        }}
        {...registerRest}
        type={type}
        placeholder={placeholder}
        variant="plain"
        sx={{
          '&:focus-within': {
            borderColor: error ? 'red' : 'primary.500',
            boxShadow: error ? '0 0 0 2px red' : '0 0 0 2px #1976d2',
            outline: 'none'
          },
          '&.css-p1vwp9-JoyInput-root:not([data-skip-inverted-colors])': {
            '--Input-focusedHighlight': error ? 'red' : 'none',
            '--Input-focusedThickness': '0px'
          },
          '&::before, &::after': {
            content: 'none',
            border: 'none !important'
          },
          backgroundColor: 'white',
          borderWidth: '1px',
          borderColor: error ? 'danger.400' : 'black',
          height: 50,
          width: width || { xs: '100%', sm: '100%', md: 400 },
          '&.MuiInput-input, & .css-1gw9vc6-JoyInput-input': {
            outline: 'none',
            boxShadow: 'none !important'
          },
          '& .MuiInput-input': {
            outline: 'none',
            boxShadow: 'none'
          },
          '& .MuiInput-root:before, & .MuiInput-root:after': {
            display: 'none'
          },
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px white inset',
            WebkitTextFillColor: 'inherit'
          },
          ...sx
        }}
        endDecorator={
          autosaved === 'success' ? (
            <CheckCircleIcon
              color="success"
              sx={{ position: 'absolute', right: -25, fontSize: 20 }}
            />
          ) : autosaved === 'error' ? (
            <ErrorIcon color="error" sx={{ position: 'absolute', right: -25, fontSize: 20 }} />
          ) : null
        }
      />
      {error && (
        <TypographyCore color="danger" fontSize={12}>
          {error}
        </TypographyCore>
      )}
    </FormControl>
  );
};

export default FormTextInput;
