import { Box, Grid } from '@mui/joy';
import images from '../../../assets';
const landingImg = [images.landingImg1, images.landingImg2, images.landingImg3, images.landingImg4];
const LandingImages = () => {
  return (
    <Box
      sx={() => ({
        height: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        width: { xs: '100%', md: '40vw' },
        transition:
          'background-image var(--Transition-duration), left var(--Transition-duration) !important',
        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        backgroundColor: 'background.level1'
      })}
    >
      <Grid container sx={{ width: '100%' }}>
        {landingImg.map((singleImg, idx) => {
          return (
            <Grid xs={6} key={idx}>
              <img src={singleImg} alt="landing" loading="lazy" className="landingImg" />
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          top: { xs: '25%', md: '32%' },
          left: { xs: '10%', md: '25%' },
          right: { xs: '10%', md: '25%' },
          bottom: { xs: '25%', md: '30%' },
          width: { xs: '80vw', md: 'calc(50vw/2.5)' },
          height: { xs: '50vh', md: 'calc(100vh/2.5)' },
          backgroundImage: `url(${images.suneLandingLogo})`
        }}
      />
    </Box>
  );
};

export default LandingImages;
