import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { ROLE_TYPE } from '../../types';
const LEGACY_TO_SUNEIFY = {
  [ROUTES.PRODUCT]: ROUTES.SUNEIFY_PRODUCTS,
  [ROUTES.VIDEOS]: ROUTES.SUNEIFY_VIDEOS,
  [ROUTES.LIVE]: ROUTES.SUNEIFY_LIVE,
  [ROUTES.ORDER]: ROUTES.SUNEIFY_ORDERS,
  [ROUTES.BUSINESS_INFORMATION]: ROUTES.SUNEIFY_ONBOARDING,
  [ROUTES.SELLER_ACCOUNT_INFORMATION]: ROUTES.SUNEIFY_SELLER_INFO,
  [ROUTES.SELLER_BUSINESS_INFORMATION]: ROUTES.SUNEIFY_SELLER_INFO
};

const useLegacyToSuneifyRedriect = ({
  isSuneifyEnabled,
  roleType
}: {
  isSuneifyEnabled: boolean;
  roleType: ROLE_TYPE;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuneifyEnabled && LEGACY_TO_SUNEIFY[location.pathname] && roleType === ROLE_TYPE.SELLER) {
      navigate(LEGACY_TO_SUNEIFY[location.pathname]);
    }
  }, [location, isSuneifyEnabled, roleType]);
};

export default useLegacyToSuneifyRedriect;
