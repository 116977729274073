import { useQuery } from '@tanstack/react-query';

import { API_ROUTES } from '../../constants/apiRoutes';
import { RouteConfig } from '../../types';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';

interface ILegalResponse {
  SettingsPage: {
    [key: string]: {
      html: string;
    };
  };
}

export const useGetLegalStatement = () => {
  const config: RouteConfig = {
    path: API_ROUTES.LEGAL_STATEMENT,
    method: 'GET'
  };
  const { data, isLoading, isError, isSuccess } = useQuery(
    [API_ROUTES.LEGAL_STATEMENT],
    () => makeRequest<Response<ILegalResponse>>(config),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      select(response) {
        return response?.data;
      },
      cacheTime: 50000
    }
  );
  return {
    data,
    isLoading,
    isError,
    isSuccess
  };
};
