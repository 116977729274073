/* istanbul ignore file */
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';

export const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIRE_BASE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const performance = getPerformance(app);
if (localStorage.getItem('userId') && localStorage.getItem('roleType')) {
  setUserId(analytics, `${localStorage.getItem('roleType')}_${localStorage.getItem('userId')}`);
}
export const remoteConfig = getRemoteConfig(app);

export default app;
