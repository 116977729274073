import { lazy } from 'react';

const SuneifyHome = lazy(() => import('./Home'));
const SuneifyOnboarding = lazy(() => import('./Onboarding'));
const SuneifyAccount = lazy(() => import('./Account'));
const SuneifyAccountEdit = lazy(() => import('./Account/AccountEdit'));
const SuneifyStoreProducts = lazy(() => import('./Products'));
const SuneifyStoreOrders = lazy(() => import('./Order/StoreOrders'));

const SuneifyProductPage = lazy(() => import('./ProductCatalogForm/ProductPage'));
const SuneifyStudioVideos = lazy(() => import('./Videos'));
const SuneifyStudioLives = lazy(() => import('./Lives'));

const SuneifyAdmin = lazy(() => import('./Admin'));
const SuneifySellers = lazy(() => import('./Seller'));
const SuneifyAdminStoreOrder = lazy(() => import('./Order/AdminStoreOrders'));
const SuneifyAdminStoreProducts = lazy(() => import('./Products/AdminStoreProducts'));
const SuneifyAdminStoreVideos = lazy(() => import('./Videos/AdminStudioVideos'));
const SuneifyOrderSummary = lazy(() => import('./Order/OrderSummary'));
const SuneifyAdminSuneTV = lazy(() => import('./SuneTV/SuneTV'));
const SuneifyOnboardSeller = lazy(() => import('./AdminOnboardSeller/index'));

export {
  SuneifyHome,
  SuneifyStoreProducts,
  SuneifyStoreOrders,
  SuneifyStudioLives,
  SuneifyStudioVideos,
  SuneifyProductPage,
  SuneifyAdmin,
  SuneifySellers,
  SuneifyAdminStoreOrder,
  SuneifyAdminStoreProducts,
  SuneifyOrderSummary,
  SuneifyAdminStoreVideos,
  SuneifyOnboarding,
  SuneifyAccount,
  SuneifyAccountEdit,
  SuneifyAdminSuneTV,
  SuneifyOnboardSeller
};
