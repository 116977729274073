import '../../index.css';

import React from 'react';
import { Outlet } from 'react-router-dom';

import { NAVIGATION_TABS_STUDIO } from '../../config/navigationTabs';
import { ROUTES } from '../../constants/routes';
import VerticalNavBar from '../VerticalNavBar';
import { useTranslation } from 'react-i18next';

const StudioLayout: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['studio', 'live', 'videos', 'liveScheduler']);
  return (
    <div className="flex layout-container w-full">
      <div className="h-full bg-slate-100">
        <VerticalNavBar
          allTabs={NAVIGATION_TABS_STUDIO(t)}
          selectedTab={
            NAVIGATION_TABS_STUDIO(t)[window.location.pathname === ROUTES.LIVE ? 0 : 1].label
          }
        />
      </div>
      <div className="mr-10 lg:mr-38.5 h-full relative flex flex-col w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default StudioLayout;
