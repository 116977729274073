import { useFetchSellerInformation } from '../../../hooks/useProfileInformation';
import { useGetCategories } from '../useGetCategories/useGetCategories';
import {
  useFetchBusinessCategoryInformation,
  useFetchStateInformation
} from '../../../hooks/useDropdown';
import { useGetTags } from '../useGetTags/useGetTags';

const usePrefetch = () => {
  const { isLoading: sellerInfoLoading } = useFetchSellerInformation();
  const { isLoading: stateInfoLoading } = useFetchStateInformation();
  const { isLoading: businessCategoryLoading } = useGetCategories();
  useGetTags();
  useFetchBusinessCategoryInformation();

  return {
    sellerInfoLoading,
    stateInfoLoading,
    businessCategoryLoading
  };
};

export default usePrefetch;
