import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Checkbox, Stack } from '@mui/joy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProfileImageUpload } from '../../components/ProfileImageUpload';
import { ButtonCore, TypographyCore } from '../../suneify/components/Core';
import { colors } from '../../suneify/constants/theme';
import { IAsset, State } from '../../types';
import { ROLE_TYPE } from '../../types/RoleType';
import useAppCommonHook from '../../reduxStore/hooks/useAppCommonHook';
import { useNavigate } from 'react-router-dom';
import OnboardingConsentModal from '../../components/OnboardingConsentModal';
import React from 'react';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import debounce from 'lodash/debounce';
import { getZipCodeError } from '../../utils';
import { useMetaTag } from '../../hooks/useMetaTag';
import { toast } from 'react-toastify';
import { useCreateBusinessInfo } from '../../hooks/useSuneifyOnboarding/useCreateBusinessInfo';
import { useUpdateBusinessInfo } from '../../hooks/useSuneifyOnboarding/useUpdateBusinessInfo';
import { useGetBusinessInfo } from '../../hooks/useSuneifyOnboarding/useGetBusinessInfo';
import { ROUTES } from '../../constants/routes';
import { getOnboardingValidationSchema } from '../../pages/OnboardingForm/onboardingValidationSchema';
import { useAuth } from '../../context/AuthContext';
import useSellerHook from '../../reduxStore/hooks/useSellerHook';
import AutocompleteInput from '../../components/AutoCompleteInput/AutoCompleteInput';
import FormTextInput from '../FormTextInput/FormTextInput';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { HelpSupportModal } from '../../suneify/components/HelpSupportModal/HelpSupportModal';

export interface IOnboardingForm {
  legalBusinessName: string;
  taxIdentificationNumber: string;
  shopName: string;
  businessCategory: string;
  streetAddress: string;
  apartmentOrSuite: string;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  preferredContactEmail: string;
  cellPhoneNumber: string;
}
interface IAutoSavedFields {
  shopName: Status;
  legalBusinessName: Status;
  taxIdentificationNumber: Status;
  businessCategory: Status;
  streetAddress: Status;
  apartmentOrSuite: Status;
  city: Status;
  state: Status;
  zipCode: Status;
  firstName: Status;
  lastName: Status;
  preferredContactEmail: Status;
  cellPhoneNumber: Status;
}
export interface CustomError extends Error {
  code?: string;
}

export type Status = 'idle' | 'success' | 'error';

const OnboardingForm = ({
  showHeader,
  showFooter
}: {
  showHeader: boolean;
  showFooter: boolean;
}) => {
  const { sellerInfo } = useSellerHook();
  const { roleSpecificId } = useAuth();
  const [isCreated, setIsCreated] = useState(false);
  const [sellerProfileIconUrl, setSellerProfileIconUrl] = useState('');
  const { t } = useTranslation('businessInformation');
  const navigate = useNavigate();
  const { usStateData, categoryData } = useAppCommonHook();
  const { data: metaTagList } = useMetaTag();
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [selectedSellerCategories, setSelectedSellerCategories] = useState<number[]>([]);
  const previousDataRef = useRef<any>(null);
  const [sellerAgreedToTerms, setSellerAgreedToTerms] = useState(false);
  const [showOnboardingConsentModal, setShowOnboardingConsentModal] = useState(false);
  const [isHelpSupportModalOpen, setHelpSupportModalOpen] = useState(false);

  const handleOpenHelpSupportModal = () => {
    setHelpSupportModalOpen(true);
  };

  const handleCloseHelpSupportModal = () => {
    setHelpSupportModalOpen(false);
  };

  const handleCheckboxChange = (tagId: number) => {
    setSelectedTagIds((prevSelected) =>
      prevSelected.includes(tagId)
        ? prevSelected.filter((id) => id !== tagId)
        : [...prevSelected, tagId]
    );
  };

  const handleSellerCategoriesCheckboxChange = (tagId: number) => {
    setSelectedSellerCategories((prevSelected) =>
      prevSelected.includes(tagId)
        ? prevSelected.filter((id) => id !== tagId)
        : [...prevSelected, tagId]
    );
  };

  const sellerAttributes = useMemo(() => {
    return metaTagList?.filter((tag) => tag.type == 'SELLER_ATTRIBUTE');
  }, [metaTagList]);

  const sellerCategories = useMemo(() => {
    return metaTagList?.filter((tag) => tag.type == 'SELLER_CATEGORY');
  }, [metaTagList]);

  const imageUploadSuccessHandler = (imageUploadResponse: IAsset) => {
    setSellerProfileIconUrl(imageUploadResponse.contentUrl);
  };

  const businessCategoryDataMemo = useMemo(() => {
    return categoryData?.map((singleCategory) => {
      return {
        value: singleCategory.name,
        label: singleCategory.name
      };
    });
  }, [categoryData]);

  const stateData = useMemo(() => {
    return usStateData?.map((singleState) => {
      return {
        value: singleState.stateName,
        label: singleState.stateName
      };
    });
  }, [usStateData]);

  const { data: businessInfo } = useGetBusinessInfo();

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger
  } = useForm<IOnboardingForm>({
    mode: 'onBlur',
    resolver: zodResolver(getOnboardingValidationSchema(t)),
    defaultValues: {
      shopName: sellerInfo?.brandName ?? '',
      legalBusinessName: sellerInfo?.businessName ?? '',
      taxIdentificationNumber: sellerInfo?.businessInformation?.businessRegistrationNumber ?? '',
      businessCategory: sellerInfo?.businessInformation?.businessCategory ?? '',
      streetAddress: sellerInfo?.businessInformation?.address ?? '',
      apartmentOrSuite: sellerInfo?.businessInformation?.apartmentOrSuite ?? '',
      city: sellerInfo?.businessInformation?.city ?? '',
      state: sellerInfo?.businessInformation?.state ?? '',
      zipCode: sellerInfo?.businessInformation?.zipcode ?? '',
      firstName: sellerInfo?.businessInformation?.ownerFirstName ?? '',
      lastName: sellerInfo?.businessInformation?.ownerLastName ?? '',
      preferredContactEmail: sellerInfo?.businessInformation?.contactEmail ?? '',
      cellPhoneNumber: sellerInfo?.businessInformation?.phoneNumber ?? ''
    }
  });

  useEffect(() => {
    setAutosavedFields({
      shopName: sellerInfo?.brandName ? 'success' : 'idle',
      legalBusinessName: sellerInfo?.businessName ? 'success' : 'idle',
      taxIdentificationNumber: sellerInfo?.businessInformation?.businessRegistrationNumber
        ? 'success'
        : 'idle',
      businessCategory: sellerInfo?.businessInformation?.businessCategory ? 'success' : 'idle',
      streetAddress: sellerInfo?.businessInformation?.address ? 'success' : 'idle',
      apartmentOrSuite: sellerInfo?.businessInformation?.apartmentOrSuite ? 'success' : 'idle',
      city: sellerInfo?.businessInformation?.city ? 'success' : 'idle',
      state: sellerInfo?.businessInformation?.state ? 'success' : 'idle',
      zipCode: sellerInfo?.businessInformation?.zipcode ? 'success' : 'idle',
      firstName: sellerInfo?.businessInformation?.ownerFirstName ? 'success' : 'idle',
      lastName: sellerInfo?.businessInformation?.ownerLastName ? 'success' : 'idle',
      preferredContactEmail: sellerInfo?.businessInformation?.contactEmail ? 'success' : 'idle',
      cellPhoneNumber: sellerInfo?.businessInformation?.phoneNumber ? 'success' : 'idle'
    });
  }, [sellerInfo]);

  const [autosavedFields, setAutosavedFields] = useState<IAutoSavedFields>({
    shopName: 'idle',
    legalBusinessName: 'idle',
    taxIdentificationNumber: 'idle',
    businessCategory: 'idle',
    streetAddress: 'idle',
    apartmentOrSuite: 'idle',
    city: 'idle',
    state: 'idle',
    zipCode: 'idle',
    firstName: 'idle',
    lastName: 'idle',
    preferredContactEmail: 'idle',
    cellPhoneNumber: 'idle'
  });

  useEffect(() => {
    if (businessInfo) {
      setSelectedSellerCategories(businessInfo?.sellerCategoryTags ?? []);
      setSelectedTagIds(businessInfo?.sellerAttributeTags ?? []);
    }
  }, [businessInfo]);

  const businessCategory = watch('businessCategory');
  const state = watch('state');

  const validateZipCode = (zipCodeValue: string, onChangeState?: string) => {
    const selectedState = onChangeState || getValues('state');

    const selectedStateObj = usStateData?.find(
      (singleState) => singleState.stateName === selectedState
    );

    if (!selectedState || !selectedStateObj) {
      setError('state', { type: 'custom', message: 'Please select a state first.' });
      return;
    }
    const zipcodeError = getZipCodeError(selectedStateObj as State, zipCodeValue, t);

    if (zipcodeError) {
      setError('zipCode', { type: 'custom', message: zipcodeError });
      return false;
    }

    clearErrors('zipCode');
    return true;
  };

  const { createBusinessInfoAsync } = useCreateBusinessInfo();
  const { updateBusinessInfoAsync } = useUpdateBusinessInfo();

  const setAutoSavedFieldsOnUpdate = async () => {
    try {
      const shopNameValid = await trigger('shopName');
      const legalBusinessNameValid = await trigger('legalBusinessName');
      const taxIdentificationNumberValid = await trigger('taxIdentificationNumber');
      const businessCategoryValid = await trigger('businessCategory');
      const streetAddressValid = await trigger('streetAddress');
      const apartmentOrSuiteValid = await trigger('apartmentOrSuite');
      const cityValid = await trigger('city');
      const stateValid = await trigger('state');
      const zipCodeValid = await trigger('zipCode');
      const firstNameValid = await trigger('firstName');
      const lastNameValid = await trigger('lastName');
      const preferredContactEmailValid = await trigger('preferredContactEmail');
      const cellPhoneNumberValid = await trigger('cellPhoneNumber');
      const formState = watch();

      setAutosavedFields({
        shopName:
          (dirtyFields.shopName || formState.shopName) && shopNameValid ? 'success' : 'idle',
        legalBusinessName:
          (dirtyFields.legalBusinessName || formState.legalBusinessName) && legalBusinessNameValid
            ? 'success'
            : 'idle',
        taxIdentificationNumber:
          (dirtyFields.taxIdentificationNumber || formState.taxIdentificationNumber) &&
          taxIdentificationNumberValid
            ? 'success'
            : 'idle',
        businessCategory:
          (dirtyFields.businessCategory || formState.businessCategory) && businessCategoryValid
            ? 'success'
            : 'idle',
        streetAddress:
          (dirtyFields.streetAddress || formState.streetAddress) && streetAddressValid
            ? 'success'
            : 'idle',
        apartmentOrSuite:
          (dirtyFields.apartmentOrSuite || formState.apartmentOrSuite) && apartmentOrSuiteValid
            ? 'success'
            : 'idle',
        city: (dirtyFields.city || formState.city) && cityValid ? 'success' : 'idle',
        state: (dirtyFields.state || formState.state) && stateValid ? 'success' : 'idle',
        zipCode: (dirtyFields.zipCode || formState.zipCode) && zipCodeValid ? 'success' : 'idle',
        firstName:
          (dirtyFields.firstName || formState.firstName) && firstNameValid ? 'success' : 'idle',
        lastName:
          (dirtyFields.lastName || formState.lastName) && lastNameValid ? 'success' : 'idle',
        preferredContactEmail:
          (dirtyFields.preferredContactEmail || formState.preferredContactEmail) &&
          preferredContactEmailValid
            ? 'success'
            : 'idle',
        cellPhoneNumber:
          (dirtyFields.cellPhoneNumber || formState.cellPhoneNumber) && cellPhoneNumberValid
            ? 'success'
            : 'idle'
      });
    } catch (error) {
      console.error(error);
      toast.error('something went wrong. Please try again later.');
    }
  };

  const setAutoSaveErrors = () => {
    const errorKeys = Object.keys(errors) as Array<keyof IOnboardingForm>;
    const newErrors: Partial<IAutoSavedFields> = {};

    errorKeys.forEach((error: string) => {
      newErrors[error as keyof IOnboardingForm] = 'error';
    });

    setAutosavedFields((prevState) => {
      return {
        ...prevState,
        ...newErrors
      };
    });
  };

  const isFormValid = async () => {
    const fieldsToValidate = Object.keys(dirtyFields) as Array<keyof IOnboardingForm>;
    return await trigger(fieldsToValidate);
  };

  const handleCustomError = (error: CustomError) => {
    switch (error.code) {
      case 'SELL_045':
        setError('legalBusinessName', {
          type: 'manual',
          message: 'Business name already exists. Please select a different one.'
        });
        setAutoSaveErrors();
        break;
      case 'SELL_046':
        setError('shopName', {
          type: 'manual',
          message: 'Shop name already exists. Please select a different one.'
        });
        break;
      default:
        console.error('Unhandled error code:', error.code);
    }
  };

  const attemptUpdateBusinessInfo = async (payload: any) => {
    const isValid = await isFormValid();
    if (!isValid) {
      setAutoSaveErrors();
      return;
    }

    await updateBusinessInfoAsync(payload);
    setAutoSavedFieldsOnUpdate();
  };

  const saveProgress = useCallback(
    async (data: Partial<IOnboardingForm>) => {
      const payload = {
        businessCategory: data.businessCategory,
        address: data.streetAddress,
        state: data.state,
        city: data.city,
        apartmentOrSuite: data.apartmentOrSuite,
        zipcode: data.zipCode,
        ownerLastName: data.lastName,
        ownerFirstName: data.firstName,
        phoneNumber: data.cellPhoneNumber,
        contactEmail: data.preferredContactEmail,
        businessName: data.legalBusinessName,
        brandName: data.shopName,
        businessRegistrationNumber: data.taxIdentificationNumber,
        sellerAttributeTags: selectedTagIds,
        sellerCategoryTags: selectedSellerCategories,
        // following fields are needed to submit to the api. no data for these are needed
        employeeIdentificationNumber: '',
        businessDescription: '',
        other: '',
        shopCountry: '',
        legalCountry: '',
        currency: '',
        website: '',
        timezone: ''
      };

      try {
        if (!isCreated) {
          const isValid = await isFormValid();
          if (!isValid) {
            setAutoSaveErrors();
            return;
          }

          const response = await createBusinessInfoAsync(payload);
          if (response.status === 200) {
            setIsCreated(true);
          }
          setAutoSavedFieldsOnUpdate();
          return;
        }

        const isValid = await isFormValid();
        if (!isValid) {
          setAutoSaveErrors();
          return;
        }

        await updateBusinessInfoAsync(payload);
        setAutoSavedFieldsOnUpdate();
      } catch (error) {
        try {
          if ((error as CustomError).code === 'SELL_045') {
            handleCustomError(error as CustomError);
          } else if ((error as CustomError).code === 'SELL_012') {
            setIsCreated(true);
            try {
              await attemptUpdateBusinessInfo(payload);
            } catch (updateError) {
              handleCustomError(updateError as CustomError);
            }
          } else {
            await attemptUpdateBusinessInfo(payload);
          }
        } catch (error) {
          toast.error('An error occurred while saving your progress. Please try again later.');
          console.error(error);
        }
      }
    },
    [
      isCreated,
      createBusinessInfoAsync,
      updateBusinessInfoAsync,
      selectedTagIds,
      selectedSellerCategories,
      dirtyFields
    ]
  );

  const theme = useTheme<Theme>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const selectedCategory = useMemo(() => {
    return businessCategoryDataMemo.find((category) => category.value === businessCategory) || null;
  }, [businessCategory, businessCategoryDataMemo]);

  const selectedState = useMemo(() => {
    return stateData.find((stateOption) => stateOption.value === state) || null;
  }, [state, stateData]);

  const handleSameAsLegalBusinessNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const legalBusinessName = getValues('legalBusinessName');
      setValue('shopName', legalBusinessName, { shouldDirty: true });
    }
  };

  const debouncedSaveProgress = useMemo(() => debounce(saveProgress, 1000), [saveProgress]);

  const formValues = watch();

  const combinedData = useMemo(() => ({ ...formValues }), [formValues]);

  const handleSaveIfChanged = useCallback(() => {
    const hasChanged = JSON.stringify(previousDataRef.current) !== JSON.stringify(combinedData);
    if (hasChanged) {
      debouncedSaveProgress(combinedData);
      previousDataRef.current = combinedData;
    }
  }, [combinedData, debouncedSaveProgress]);

  useEffect(() => {
    if (previousDataRef.current === null) {
      previousDataRef.current = combinedData;
      return;
    }
    handleSaveIfChanged();
  }, [combinedData, debouncedSaveProgress]);

  const onSubmit = async (data: IOnboardingForm) => {
    const payload = {
      businessCategory: data.businessCategory,
      address: data.streetAddress,
      state: data.state,
      city: data.city,
      apartmentOrSuite: data.apartmentOrSuite,
      zipcode: data.zipCode,
      ownerLastName: data.lastName,
      ownerFirstName: data.firstName,
      phoneNumber: data.cellPhoneNumber,
      contactEmail: data.preferredContactEmail,
      businessName: data.legalBusinessName,
      brandName: data.shopName,
      businessRegistrationNumber: data.taxIdentificationNumber,
      sellerAttributeTags: selectedTagIds,
      sellerCategoryTags: selectedSellerCategories,
      // following fields are needed to submit to the api. no data for these are needed
      employeeIdentificationNumber: '',
      businessDescription: '',
      other: '',
      shopCountry: '',
      legalCountry: '',
      currency: '',
      website: '',
      timezone: ''
    };

    const isZipCodeValid = validateZipCode(data.zipCode);
    if (!isZipCodeValid) {
      toast.error('Zip code is invalid');
      return;
    }

    if (!sellerAgreedToTerms && showHeader) {
      toast.warning('Please agree to the seller agreement');
      return;
    }

    try {
      const response = await updateBusinessInfoAsync(payload);
      if (response.status === 200) {
        toast.success('Your business information has been saved successfully.');
        navigate(ROUTES.SUNEIFY);
      }
    } catch (error: unknown) {
      console.error(error);
      const errorMessage =
        (error as Error).message || 'Something went wrong, please try again later.';
      toast.error(`${errorMessage}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OnboardingConsentModal
        setSellerAgreedToTerms={setSellerAgreedToTerms}
        open={showOnboardingConsentModal}
        handleOnClose={() => {
          setShowOnboardingConsentModal(false);
        }}
      />

      {!showOnboardingConsentModal && (
        <Stack
          sx={{
            margin: 'auto',
            height: '100%',
            width: '80%',
            marginTop: 10
          }}
          gap={5}
        >
          {showHeader && (
            <Box>
              <TypographyCore level="title-lg" fontSize={32}>
                Create Your Sune Storefront
              </TypographyCore>
              <TypographyCore sx={{ color: colors.grey }}>
                Please describe your business to set up your storefront in Sune{' '}
              </TypographyCore>
            </Box>
          )}
          <Stack>
            <TypographyCore fontSize={24} fontWeight={700} marginBottom={3}>
              Business Details
            </TypographyCore>
            <TypographyCore fontSize={16}>
              Upload your logo which will appear on your Sune storefront
            </TypographyCore>

            <TypographyCore level="title-sm" fontWeight={800} marginTop={2}>
              Business Logo
            </TypographyCore>
            <ProfileImageUpload
              entityId={roleSpecificId}
              entityType={ROLE_TYPE.SELLER}
              profileIconUrl={sellerProfileIconUrl}
              imageUploadSuccessHandler={imageUploadSuccessHandler}
              t={t}
              className="mt-5"
            />
            <Stack gap={2}>
              <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
                <FormTextInput
                  label="Legal Business Name*"
                  placeholder="Ex: Nina's Jewlery LLC"
                  register={register}
                  name="legalBusinessName"
                  error={errors.legalBusinessName?.message}
                  autosaved={autosavedFields.legalBusinessName}
                />
                <FormTextInput
                  label="Provide your Tax Identification Number"
                  placeholder="11-1111111"
                  register={register}
                  name="taxIdentificationNumber"
                  error={errors.taxIdentificationNumber?.message}
                  autosaved={autosavedFields.taxIdentificationNumber}
                />
              </Stack>
              <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
                <Stack direction="column">
                  <FormTextInput
                    label="Shop Name*"
                    placeholder="Ex: Nina's Jewlery LLC"
                    register={register}
                    name="shopName"
                    error={errors.shopName?.message}
                    autosaved={autosavedFields.shopName}
                  />
                  <Checkbox
                    label="Same as legal business name"
                    sx={{ marginTop: 2 }}
                    onChange={handleSameAsLegalBusinessNameChange}
                  />
                </Stack>
                <AutocompleteInput
                  label="Business Category*"
                  value={selectedCategory}
                  placeholder="Select a category"
                  options={businessCategoryDataMemo}
                  onChange={(event, newValue) => {
                    setValue('businessCategory', newValue?.value || '', {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                  }}
                  error={errors.businessCategory?.message}
                  autosaved={autosavedFields.businessCategory}
                />
              </Stack>
              <TypographyCore marginTop={2}>What is your business address?</TypographyCore>
              <Stack gap={2}>
                <FormTextInput
                  label="Street Address*"
                  placeholder="Street Address"
                  register={register}
                  name="streetAddress"
                  error={errors.streetAddress?.message}
                  sx={{ width: { xs: '100%', md: 835 } }}
                  autosaved={autosavedFields.streetAddress}
                />
                <FormTextInput
                  label="Apt/Suite*"
                  placeholder="Apt/Suite"
                  register={register}
                  name="apartmentOrSuite"
                  error={errors.apartmentOrSuite?.message}
                  sx={{ width: { xs: '100%', md: 835 } }}
                  autosaved={autosavedFields.apartmentOrSuite}
                />
                <FormTextInput
                  label="City*"
                  placeholder="City"
                  register={register}
                  name="city"
                  error={errors.city?.message}
                  sx={{ width: { xs: '100%', md: 835 } }}
                  autosaved={autosavedFields.city}
                />
                <Stack
                  flexDirection={{ xs: 'column', md: 'row' }}
                  gap={{ xs: 2, sm: 4.5 }}
                  justifyContent={{ xs: 'space-between', sm: 'normal' }}
                >
                  <AutocompleteInput
                    label="State*"
                    value={selectedState}
                    placeholder="Select a category"
                    options={stateData}
                    onChange={(event, newValue) => {
                      setValue('state', newValue?.value || '', {
                        shouldDirty: true,
                        shouldValidate: true
                      });
                      const currentZipCode = getValues('zipCode');
                      validateZipCode(currentZipCode, newValue?.value);
                    }}
                    error={errors.businessCategory?.message}
                    autosaved={autosavedFields.state}
                  />
                  <FormTextInput
                    label="Zip Code*"
                    placeholder="Zip Code"
                    register={register}
                    name="zipCode"
                    error={errors.zipCode?.message}
                    autosaved={autosavedFields.zipCode}
                  />
                </Stack>
              </Stack>
            </Stack>

            <TypographyCore marginTop={5}>
              Which of these maker categories best describes your products? Please select all that
              apply*
            </TypographyCore>
            <Stack gap={1} marginTop={2}>
              {sellerAttributes?.map((attribute) => {
                return (
                  <Stack direction="row" alignItems={'center'} gap={1}>
                    <Checkbox
                      label={attribute.name}
                      key={attribute.tagId}
                      checked={selectedTagIds.includes(attribute.tagId)}
                      onChange={() => handleCheckboxChange(attribute.tagId)}
                    />
                    {attribute.name !== 'None of the above' && (
                      <Box sx={{ cursor: 'pointer' }}>
                        <Tooltip title={attribute.description} arrow placement="right">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </Box>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Stack gap={2} sx={{ pb: '200px', mt: 2 }}>
            <TypographyCore fontSize={24} fontWeight={700} marginBottom={3}>
              Primary Contact
            </TypographyCore>
            <TypographyCore fontSize={16}>
              Who is the primary owner of this business? (Write information as it appears on your
              Government I.D.)
            </TypographyCore>
            <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={4.5} marginTop={2}>
              <FormTextInput
                label="First Name*"
                placeholder="First name"
                register={register}
                name="firstName"
                error={errors.firstName?.message}
                sx={{ width: { xs: '100%', sm: '100%', md: 400 } }}
                autosaved={autosavedFields.firstName}
              />
              <FormTextInput
                label="Last Name*"
                placeholder="Last name"
                register={register}
                name="lastName"
                error={errors.lastName?.message}
                sx={{ width: { xs: '100%', sm: '100%', md: 400 } }}
                autosaved={autosavedFields.lastName}
              />
            </Stack>
            <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={4.5}>
              <FormTextInput
                label="Preferred Contact Email*"
                placeholder="Ex: someone@gmail.com"
                register={register}
                name="preferredContactEmail"
                error={errors.preferredContactEmail?.message}
                autosaved={autosavedFields.preferredContactEmail}
              />
              <FormTextInput
                label="Cell Phone #*"
                placeholder="123-456-7890"
                register={register}
                name="cellPhoneNumber"
                error={errors.cellPhoneNumber?.message}
                autosaved={autosavedFields.cellPhoneNumber}
              />
            </Stack>
            <TypographyCore marginTop={2}>
              Help us celebrate and promote diversity on our platforms. Do you identify with any of
              the following:{' '}
            </TypographyCore>
            <Stack flexDirection={'column'} gap={1}>
              {sellerCategories?.map((attribute) => {
                return (
                  <Checkbox
                    label={attribute.name}
                    key={attribute.tagId}
                    checked={selectedSellerCategories.includes(attribute.tagId)}
                    onChange={() => handleSellerCategoriesCheckboxChange(attribute.tagId)}
                  />
                );
              })}
            </Stack>
            {!showFooter && (
              <Stack
                width={'78%'}
                height={50}
                justifyContent={'flex-end'}
                direction="row"
                gap={1}
                marginTop={2}
              >
                <ButtonCore
                  data-testid="cancel-btn-mobile"
                  variant="outlined"
                  sx={{
                    width: 125,
                    height: 40,
                    borderColor: colors.primary,
                    color: colors.primary
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </ButtonCore>
                <ButtonCore
                  variant="solid"
                  type="submit"
                  sx={{ width: 150, height: 40, backgroundColor: colors.primary }}
                >
                  Save changes
                </ButtonCore>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      {showFooter && (
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            borderTop: '1px solid #e0e0e0',
            width: '100%',
            zIndex: 1000
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ marginBottom: { xs: 2, sm: 0 } }}
            >
              <Checkbox
                sx={{ padding: 0 }}
                checked={sellerAgreedToTerms}
                onChange={(e) => {
                  setSellerAgreedToTerms(e.target.checked);
                }}
              />

              {isSmallScreen ? (
                <TypographyCore fontSize={14}>
                  I have read and agree to the seller agreement
                </TypographyCore>
              ) : (
                <TypographyCore component="span">
                  Agree to the{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOnboardingConsentModal(true);
                    }}
                  >
                    Seller Agreement
                  </span>
                </TypographyCore>
              )}
            </Stack>
            <Stack direction="row" spacing={2} alignItems={'center'} sx={{ position: 'relative' }}>
              <Stack
                onClick={handleOpenHelpSupportModal}
                justifyContent={'center'}
                sx={{
                  borderRadius: 60,
                  backgroundColor: colors.primary,
                  position: 'absolute',
                  bottom: { xxs: 140, xs: 120, sm: 100 },
                  right: 10,
                  height: 60,
                  width: 60,
                  cursor: 'pointer'
                }}
                alignItems={'center'}
              >
                <TypographyCore sx={{ color: 'white', margin: 'auto' }}>Help</TypographyCore>
              </Stack>
              {isSmallScreen ? (
                <ButtonCore
                  data-testid="cancel-btn-mobile"
                  variant="outlined"
                  sx={{
                    width: 150,
                    height: 50,
                    borderColor: colors.primary,
                    color: colors.primary
                  }}
                  onClick={() => {
                    navigate(ROUTES.SUNEIFY);
                  }}
                >
                  Cancel
                </ButtonCore>
              ) : (
                <Box
                  onClick={() => {
                    navigate(ROUTES.SUNEIFY);
                  }}
                >
                  <TypographyCore sx={{ cursor: 'pointer' }}>Cancel</TypographyCore>
                </Box>
              )}

              <ButtonCore
                variant="solid"
                type="submit"
                sx={{ width: 150, height: 50, backgroundColor: colors.primary }}
              >
                Create Shop
              </ButtonCore>
            </Stack>
          </Stack>
          <HelpSupportModal
            isModalOpen={isHelpSupportModalOpen}
            onClose={handleCloseHelpSupportModal}
          />
        </Box>
      )}
    </form>
  );
};

export default OnboardingForm;
