export const PrivacyStatementsShimmerConfig = [
  {
    count: 4.2,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.3,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 0.7,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.6,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 2.6,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.8,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 2.6,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 mb-10 block',
    className: '',
    circle: false
  }
];

export const TermsOfServiceShimmerConfig = [
  {
    count: 1,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.9,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 11.1,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 0.1,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.5,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 2.8,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 mb-10 block',
    className: '',
    circle: false
  }
];

export const CommunityGuidelinesShimmerConfig = [
  {
    count: 2.3,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.7,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 8.6,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 block',
    className: '',
    circle: false
  },
  {
    count: 1.8,
    height: 18,
    width: '100%',
    containerClassName: 'mt-5 mb-10 block',
    className: '',
    circle: false
  }
];
