import React, { FC } from 'react';
import Placeholder from '../Placeholder';
export interface Props {
  label: string;
  detail?: string;
}
const Banner: FC<Props> = ({ label, detail }) => {
  return (
    <div className="fixed mt-15 mb-10 mx-5 lg:mx-20 xl:mx-38.5">
      <Placeholder
        label={label}
        detail={detail}
        classNameContainer="gap-2.5"
        classNameLabel="font-work-sans font-medium text-3.2xl tracking-tighter text-stone-850"
        classNameDetail="font-normal text-lg tracking-normal text-stone-850"
      ></Placeholder>
    </div>
  );
};

export default Banner;
