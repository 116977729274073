import { Stack } from '@mui/joy';
import { Link } from 'react-router-dom';
import { CopyRightAction } from '../../../constants/common';
import { TypographyCore } from '../Core';

const footerLink = [
  {
    linkId: 'privacyLink',
    linkAction: CopyRightAction.PrivacyPolicy,
    linkText: 'Privacy Policy'
  },
  {
    linkId: 'tosLink',
    linkAction: CopyRightAction.Terms,
    linkText: 'Sune Terms of Service'
  },
  {
    linkId: 'helpLink',
    linkAction: CopyRightAction.HelpSupport,
    linkText: ' Help '
  }
];

const Footer = ({ handleLinkClick }: { handleLinkClick: (action: string) => void }) => {
  return (
    <Stack
      component="footer"
      sx={{
        py: 3,
        zIndex: 1
      }}
      direction={'row'}
      justifyContent={'center'}
    >
      <TypographyCore level="body-xs" textAlign="center" sx={{ mx: 1 }}>
        © {new Date().getFullYear()}, sune, LLC.
      </TypographyCore>
      {footerLink?.map((singleLink) => {
        return (
          <Link
            to="#"
            onClick={(event) => {
              event.preventDefault();
              handleLinkClick(singleLink.linkAction);
            }}
            key={singleLink.linkId}
            data-testid={singleLink.linkId}
          >
            <TypographyCore level="body-xs" textAlign="center" sx={{ mx: 1 }}>
              {singleLink.linkText}
            </TypographyCore>
          </Link>
        );
      })}
    </Stack>
  );
};

export default Footer;
