import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { DEFAULT_FLAGS, RemoteConfigModel } from '../constants/flagsValue';
import {
  fetchAndActivate,
  getAll,
  getString,
  getNumber,
  getBoolean,
  Value
} from 'firebase/remote-config';
import { remoteConfig } from '../firebase';
import { isBoolean, isNumber, isObject, isString } from 'lodash';
import { validate } from 'compare-versions';

type flagsLoadingType = 'initial' | 'loading' | 'loaded';

export type RemoteConfigReturn = RemoteConfigModel & {
  flagsLoading: flagsLoadingType;
  syncConfig: () => void;
};

export const RemoteConfigContext: React.Context<RemoteConfigReturn> = createContext(
  DEFAULT_FLAGS as RemoteConfigReturn
);

export const RemoteConfigProvider = ({ children }: { children: JSX.Element }) => {
  const fetchedRemoteConfig: RemoteConfigReturn = useRemoteConfigSetup();
  return (
    <RemoteConfigContext.Provider value={fetchedRemoteConfig}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = () => {
  const context = useContext(RemoteConfigContext);
  if (context === undefined) {
    throw new Error('RemoteConfig should be used inside RemoteConfigProvider');
  }
  return context;
};

const REMOTE_CONFIG_FETCH_INTERVAL = 360000;

const useRemoteConfigSetup = (): RemoteConfigReturn => {
  const [remoteConfigState, setRemoteConfigState] = useState<RemoteConfigModel>(DEFAULT_FLAGS);

  const [flagsLoading, setFlagsLoading] = useState<flagsLoadingType>('initial');

  const handleBooleanFeatureFlag = (featureFlag: string): boolean => {
    const lowerCaseFlag = featureFlag.toLowerCase();
    return lowerCaseFlag === 'true' || (lowerCaseFlag !== 'false' && validate(featureFlag));
  };
  function isKeyOfRemoteConfigModel(key: string): key is keyof RemoteConfigModel {
    return key in DEFAULT_FLAGS;
  }
  function updateConfigObject<T extends keyof RemoteConfigModel>(
    configObject: RemoteConfigModel,
    key: T,
    value: RemoteConfigModel[T]
  ) {
    configObject[key] = value;
  }

  const syncConfig = useCallback(async () => {
    if (flagsLoading !== 'initial') {
      return;
    }

    setFlagsLoading('loading');
    try {
      remoteConfig.settings.minimumFetchIntervalMillis = REMOTE_CONFIG_FETCH_INTERVAL;
      remoteConfig.defaultConfig = DEFAULT_FLAGS;

      await fetchAndActivate(remoteConfig);
      // TODO  activated always return false and would not update the value,comment out for now
      // if (activated) {
      const configObject: RemoteConfigModel = { ...DEFAULT_FLAGS };
      const remoteObj = getAll(remoteConfig);
      const configKeys = isObject(remoteObj) ? Object.keys(remoteObj) : [];

      handleRemoteObjectUpdate(configKeys, configObject, remoteObj);
      setRemoteConfigState(configObject);
      // } else {
      //   // In this else case, we will use the default values in flags.ts.
      //   //    No error should be thrown as to not interrupt user experience.
      //   console.debug(
      //     'No configs were fetched from the backend, and the local configs were already activated'
      //   );
      // }
    } catch (err) {
      console.error(err);
      //   TODO if added sentry into the project, comment back
      //   Sentry.captureException(
      //     new Error(`'Error on getting the remote flag from firebase:', ${JSON.stringify(err)}`)
      //   );
    } finally {
      setFlagsLoading('loaded');
    }

    function handleRemoteObjectUpdate(
      configKeys: string[],
      configObject: RemoteConfigModel,
      remoteObj: Record<string, Value>
    ) {
      for (const key of configKeys) {
        if (isKeyOfRemoteConfigModel(key)) {
          const currentItem = configObject[key];
          let newValue: typeof currentItem = currentItem;
          if (isBoolean(currentItem)) {
            if (remoteObj[key].getSource() === 'remote') {
              newValue = handleBooleanFeatureFlag(
                getString(remoteConfig, key)
              ) as typeof currentItem;
            } else {
              newValue = getBoolean(remoteConfig, key) as typeof currentItem;
            }
          } else if (isString(currentItem)) {
            newValue = getString(remoteConfig, key) as typeof currentItem;
          } else if (isNumber(currentItem)) {
            newValue = getNumber(remoteConfig, key) as typeof currentItem;
          }
          updateConfigObject(configObject, key, newValue);
        }
      }
    }
  }, [flagsLoading]);

  useEffect(() => {
    if (flagsLoading === 'initial') {
      syncConfig();
    }
  }, [flagsLoading, syncConfig]);

  return { ...remoteConfigState, flagsLoading, syncConfig };
};
