import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ProgressBannerState } from '../../types';
import StepComponent from '../Step';

interface Props {
  progressBanner: ProgressBannerState[];
}

const ProgressBanner: React.FC<Props> = ({ progressBanner }) => {
  const { pathname } = useLocation();
  return (
    <div className="fixed flex flex-col bg-white border border-solid border-gray-350 rounded-2.5xl mb-5 h-min w-40 sm:w-67 items-left">
      {progressBanner.map(({ key, label, route }, index) => (
        <StepComponent
          key={key}
          label={label}
          isActive={pathname === route}
          isFirst={index === 0}
          isLast={index === progressBanner.length - 1}
        />
      ))}
    </div>
  );
};

export default ProgressBanner;
