import { TFunction } from 'i18next';
import { NAVIGATION_TABS } from '../constants/navigationTabs';
import { ROUTES } from '../constants/routes';
import { NavigationTab, NestedNavigationTab } from '../types';

export const NAVIGATION_TABS_HEADER = (t: TFunction): NavigationTab[] => [
  { label: t(NAVIGATION_TABS.STORE), route: ROUTES.PRODUCT },
  { label: t(NAVIGATION_TABS.STUDIO), route: ROUTES.LIVE }
];

export const NAVIGATION_TABS_HEADER_SELLER: NavigationTab[] = [
  { label: NAVIGATION_TABS.STUDIO, route: ROUTES.LIVE }
];

export const NAVIGATION_TABS_STORE = (t: TFunction): NestedNavigationTab[] => [
  {
    label: t(NAVIGATION_TABS.PRODUCT),
    route: ROUTES.PRODUCT,
    subTabs: []
  },
  {
    label: t(NAVIGATION_TABS.ORDER),
    route: ROUTES.ORDER,
    subTabs: []
  }
];

export const NAVIGATION_TABS_STUDIO = (t: TFunction): NestedNavigationTab[] => [
  {
    label: t(NAVIGATION_TABS.LIVE),
    route: ROUTES.LIVE,
    subTabs: [
      t(NAVIGATION_TABS.SCHEDULED),
      t(NAVIGATION_TABS.LIVESTREAMED),
      t(NAVIGATION_TABS.CANCELLED)
    ]
  },
  {
    label: t(NAVIGATION_TABS.VIDEOS),
    route: ROUTES.VIDEOS,
    subTabs: []
  }
];

export const NAVIGATION_TABS_SELLER_INFORMATION = (t: TFunction): NavigationTab[] => [
  {
    label: t(NAVIGATION_TABS.SELLER_ACCOUNT_INFORMATION),
    route: ROUTES.SELLER_ACCOUNT_INFORMATION
  },
  {
    label: t(NAVIGATION_TABS.SELLER_BUSINESS_INFORMATION),
    route: ROUTES.SELLER_BUSINESS_INFORMATION
  },
  {
    label: t(NAVIGATION_TABS.SELLER_ADDITIONAL_INFORMATION),
    route: ROUTES.SELLER_ADDITIONAL_INFORMATION
  },
  {
    label: t(NAVIGATION_TABS.TERMS_OF_SERVICE),
    route: ROUTES.SELLER_TERMS_OF_SERVICE
  },
  {
    label: t(NAVIGATION_TABS.COPYRIGHT),
    route: ROUTES.SELLER_COPYRIGHT
  }
];

export const NAVIGATION_TABS_HOST_INFORMATION = (t: TFunction): NavigationTab[] => [
  { label: t(NAVIGATION_TABS.HOST_ACCOUNT_INFORMATION), route: ROUTES.HOST_ACCOUNT_INFORMATION },
  {
    label: t(NAVIGATION_TABS.HOST_PERSONAL_INFORMATION),
    route: ROUTES.HOST_EDIT_PERSONAL_INFORMATION
  },
  {
    label: t(NAVIGATION_TABS.HOST_ADDITIONAL_INFORMATION),
    route: ROUTES.HOST_EDIT_ADDITIONAL_INFORMATION
  },
  {
    label: t(NAVIGATION_TABS.TERMS_OF_SERVICE),
    route: ROUTES.HOST_TERMS_OF_SERVICE
  },
  {
    label: t(NAVIGATION_TABS.COPYRIGHT),
    route: ROUTES.HOST_COPYRIGHT
  }
];

export const LOGIN_TABS = (t: TFunction) => [
  t(NAVIGATION_TABS.SELLER_LOGIN),
  t(NAVIGATION_TABS.HOST_LOGIN)
];
