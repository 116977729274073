import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import { useAuth } from '../../context/AuthContext';
import { ROLE_TYPE } from '../../types';

interface Props {
  isUserLogin: boolean;
}

const DefaultLayout: FC<Props> = ({ isUserLogin }) => {
  const { roleType } = useAuth();
  return (
    <>
      {roleType === ROLE_TYPE.HOST ? <Header isUserLogin={isUserLogin} /> : null}
      <Outlet />
    </>
  );
};

export default DefaultLayout;
