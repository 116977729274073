import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { useHealthCheck } from '../../hooks/HealthCheck';
import { IHealthCheck } from '../../types';

const HealthCheck = () => {
  const navigate = useNavigate();

  const { data, isError, isLoading } = useHealthCheck();

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.NOT_FOUND);
    }
  }, [isError]);

  return isLoading ? (
    <Loader isLoading={isLoading}></Loader>
  ) : (
    <div className="m-16">
      <div className="flex justify-start flex-wrap gap-x-2 gap-y-6 w-full text-white">
        {(data || []).map((service: IHealthCheck) => {
          return (
            <div
              className={classNames(
                'w-[24.5%] p-8 text-center font-bold rounded text-2xl',
                service.ok ? 'bg-success' : 'bg-error'
              )}
              key={service.serviceName}
            >
              {service.serviceName}
              {service.subServices?.map((subService: IHealthCheck) => (
                <div
                  className={classNames(
                    'w-full my-4 p-1 border',
                    subService.ok ? 'bg-success' : 'bg-error'
                  )}
                  key={subService.serviceName}
                >
                  {subService.serviceName}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HealthCheck;
