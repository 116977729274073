import { Accept } from 'react-dropzone';

export const IMAGE_SUPPORTED_FORMAT: Accept = {
  'images/jpeg': ['.jpeg'],
  'images/jpg': ['.jpg'],
  'images/png': ['.png']
};

export const PRODUCT_IMAGE_MAX_SIZE = 10485760;

export const PROFILE_ICON_MAX_SIZE = 10485760;

export const FILE_TO_LARGE_ERROR = 'file-too-large';

export const INVALID_FILE_TYPE_ERROR = 'file-invalid-type';

export const DEFAULT_ERROR = 'File reading has failed';

export const PRODUCT_IMAGE_MAX_SIZE_MB = 10;

export const INVALID_FILE_NAME_ERROR = 'invalid-file-name';
