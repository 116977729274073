import leftArrow from './arrow-left.svg';
import avatar from './avatar.svg';
import close from './close.svg';
import deleteTableRow from './deleteTableRow.svg';
import dropdown from './dropdown.svg';
import horizontalView from './horizontalView.svg';
import Product1 from './Images/Image.png';
import Product2 from './Images/image1.png';
import sampleCover from './Images/Sample.png';
import imageUpload from './imageUpload.svg';
import logo from './logo.svg';
import plus from './plus.svg';
import rectangle from './rectangle.png';
import title from './title.png';
import avatarUpload from './avatarUpload.svg';
import sampleCover2 from './Images/lipstick.png';
import pencil from './pencil.svg';
import save from './save.svg';
import discard from './discard.svg';
import verticalView from './verticalView.svg';
import videoUpload from './videoUpload.svg';
import profileUpload from './profileUpload.svg';
import loginBackground from './login-background.svg';
import unpublishIcon from './unpublishIcon.svg';
import suneApp from './suneApp.svg';
import xWhite from './x-white.svg';
import plus1 from './plus1.svg';
import check from './check.svg';
import image from './Image.svg';
import thumbDown from './thumb-down.svg';
import thumbUp from './thumb-up.svg';
import suneLogo from './SuneLogo.svg';
import landingImg1 from './Images/landingPage/landingImg_1.png';
import landingImg2 from './Images/landingPage/landingImg_2.png';
import landingImg3 from './Images/landingPage/landingImg_3.png';
import landingImg4 from './Images/landingPage/landingImg_4.png';
import suneLandingLogo from './Images/landingPage/sune_logo.png';
export default {
  logo,
  videoUpload,
  avatar,
  rectangle,
  dropdown,
  deleteTableRow,
  leftArrow,
  close,
  plus,
  imageUpload,
  sampleCover,
  title,
  avatarUpload,
  sampleCover2,
  pencil,
  save,
  discard,
  Product1,
  Product2,
  verticalView,
  horizontalView,
  loginBackground,
  unpublishIcon,
  profileUpload,
  suneApp,
  xWhite,
  plus1,
  check,
  image,
  thumbDown,
  thumbUp,
  suneLogo,
  landingImg1,
  landingImg2,
  landingImg3,
  landingImg4,
  suneLandingLogo
};
