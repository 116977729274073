import { FormControl, FormLabel, Autocomplete, Stack } from '@mui/joy';
import { TypographyCore } from '../../suneify/components/Core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Status } from '../OnboardingForm/OnboardingForm';
import ErrorIcon from '@mui/icons-material/Error';

interface AutocompleteInputProps {
  label: string;
  value: any;
  placeholder: string;
  options: { label: string; value: string }[];
  onChange: (event: any, newValue: any) => void;
  error?: string;
  width?: string | number;
  autosaved?: Status;
}

const AutocompleteInput = ({
  label,
  value,
  placeholder,
  options,
  onChange,
  error,
  width,
  autosaved
}: AutocompleteInputProps) => {
  return (
    <FormControl>
      <FormLabel>
        <TypographyCore fontWeight={600}>{label}</TypographyCore>
      </FormLabel>
      <Stack sx={{ position: 'relative', width: width || { xs: '100%', sm: '100%', md: 400 } }}>
        <Autocomplete
          options={options}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          sx={{
            backgroundColor: 'white',
            borderWidth: '1px',
            borderColor: error ? 'danger.400' : 'common.black',
            height: 50,
            width: '100%',
            '&:focus-within': {
              borderColor: error ? 'red' : 'primary.500',
              boxShadow: error ? '0 0 0 2px red' : '0 0 0 2px #1976d2',
              outline: 'none'
            },
            '&.css-p1vwp9-JoyInput-root:not([data-skip-inverted-colors])': {
              '--Input-focusedHighlight': error ? 'red' : 'none',
              '--Input-focusedThickness': '0px'
            },
            '&::before, &::after': {
              content: 'none',
              border: 'none !important'
            }
          }}
        />
        {autosaved === 'success' && (
          <CheckCircleIcon
            color="success"
            sx={{
              position: 'absolute',
              right: -25,
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: 20
            }}
          />
        )}
        {autosaved === 'error' && (
          <ErrorIcon
            color="error"
            sx={{
              position: 'absolute',
              right: -25,
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: 20
            }}
          />
        )}
      </Stack>
      {error && (
        <TypographyCore color="danger" fontSize={12}>
          {error}
        </TypographyCore>
      )}
    </FormControl>
  );
};

export default AutocompleteInput;
