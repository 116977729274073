import { ROLE_TYPE } from './RoleType';

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  roleType: ROLE_TYPE;
  roleSpecificId: number;
  userId: number;
  isUserActive: USER_ACTIVE_TYPE;
}

export interface RefreshTokenResponse {
  accessToken?: string;
  refreshToken?: string;
}

export enum USER_ACTIVE_TYPE {
  Y = 'Y',
  N = 'N'
}
