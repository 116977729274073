import React from 'react';
import classNames from 'classnames';

interface IButton {
  children: React.ReactNode;
  btnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?:
    | 'noStyle'
    | 'transparent'
    | 'contained'
    | 'text'
    | 'dropdown'
    | 'dropdownOption'
    | 'autocomplete'
    | 'image'
    | 'upload';
  className?: string;
  disabled?: boolean;
  testId?: string;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({
  children,
  btnClick,
  disabled,
  testId,
  variant = 'contained',
  className = '',
  id,
  type,
  ...rest
}: IButton) => {
  return (
    <button
      data-testid={testId}
      className={classNames(
        variant === 'noStyle' &&
          'outline-none border-none focus-visible:outline-dotted focus-visible:outline-1 focus-visible:rounded-none',
        variant === 'transparent' &&
          'py-2.5 rounded-full text-stone-850 leading-4 font-semibold text-sm focus-visible:outline-dotted focus-visible:outline-1 focus-visible:rounded-none',
        variant === 'upload' &&
          'px-4 py-2 mb-4 text-white disabled:bg-gray-450 active:opacity-90 disabled:opacity-100',
        variant === 'contained' &&
          !disabled &&
          'px-4 py-2.5 mb-4 text-stone-850 text-sm bg-interactive rounded-2.5xl focus-visible:outline-dotted focus-visible:outline-1 focus-visible:rounded-none',
        variant === 'contained' &&
          disabled &&
          'px-4 py-2.5 mb-4 bg-gray-150 rounded-2.5xl text-gray-350',
        variant === 'text' && 'text-1.1lg tracking-normal pl-4 pr-6 py-2 text-left font-medium',
        variant === 'dropdown' &&
          'px-5 text-1.2sm tracking-tight text-left bg-gray-55 py-2.5 border text-slate-550 border-gray-650 outline outline-0 font-normal focus:outline-0 focus:border',
        variant === 'dropdownOption' &&
          'text-sm tracking-tight font-normal text-gray-550 px-5 text-left bg-gray-55 py-2.5 outline outline-0  hover:text-stone-850 focus:text-stone-850',
        className
      )}
      disabled={disabled}
      onClick={(e) => {
        btnClick && btnClick(e);
      }}
      type={type}
      id={id}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
