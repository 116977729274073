import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';

interface ICreateSellerOutreachInfo {
  sellerFullName: string;
  email: string;
}

const postSellerOutreachInfo = async (
  sellerOutreachInfo: Partial<ICreateSellerOutreachInfo>
): Promise<Response<any>> => {
  return await makeRequest({
    path: API_ROUTES.CREATE_SELLER_OUTREACH_INFO,
    method: 'POST',
    data: sellerOutreachInfo
  });
};

export const useCreateSellerOutreachInfo = () => {
  const {
    mutateAsync: createSellerOutreachInfoAsync,
    isLoading: isDataUploading,
    isSuccess: isDataSuccessfullyUploaded
  } = useMutation({
    mutationFn: (sellerOutreachInfo: Partial<ICreateSellerOutreachInfo>) =>
      postSellerOutreachInfo(sellerOutreachInfo),
    onError: (error: unknown) => {
      console.error(error);
      toast.error((error as Error).message);
    }
  });

  return {
    createSellerOutreachInfoAsync,
    isDataUploading,
    isDataSuccessfullyUploaded
  };
};
