import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from '../../../types';
import { ICategoryListItem } from '../../../suneify/types/CategoryList';
import { ICategory } from '../../../suneify/types/Category';
import { ITagResponse } from '../../../suneify/types/TagResponse';
import { ITagForm } from '../../../suneify/types/TagForm';

export interface appCommonDataState {
  value: {
    usStateData: State[];
    categoryData: ICategoryListItem[];
    businessCategoryData: string[];
    tagsData: ITagForm[];
  };
}

const initialState: appCommonDataState = {
  value: {
    usStateData: [],
    categoryData: [],
    businessCategoryData: [],
    tagsData: []
  }
};

export const appCommonSlice = createSlice({
  name: 'appCommonData',
  initialState,
  reducers: {
    storeUSStateInfo: (state, action: PayloadAction<State[]>) => {
      const { payload } = action;
      state.value.usStateData = payload;
    },

    storeCategoryInfo: (state, action: PayloadAction<ICategory[]>) => {
      const { payload } = action;

      const transformCategoryData = Object.values(payload as ICategory[]).map(
        (category: ICategory) => {
          return {
            name: category.name,
            value: category.categoryId,
            subCategories: category.childCategories
          };
        }
      );
      state.value.categoryData = transformCategoryData;
    },

    storeTagsInfo: (state, action: PayloadAction<ITagResponse[]>) => {
      const { payload } = action;
      const transformTagsData = payload.map((tag: ITagResponse) => {
        return {
          name: tag.name,
          value: tag.id
        };
      });
      state.value.tagsData = transformTagsData;
    },
    storeBusinessCategoryInfo: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;

      state.value.businessCategoryData = payload;
    }
  }
});

export const { storeUSStateInfo, storeCategoryInfo, storeBusinessCategoryInfo, storeTagsInfo } =
  appCommonSlice.actions;

export default appCommonSlice.reducer;
