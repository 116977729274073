import { TFunction } from 'react-i18next';

export const getDefaultBusinessCategory = (t: TFunction) => t('businessCategoryPlaceholder');

export const getDefaultState = (t: TFunction) => ({
  id: 0,
  stateName: t('statePlaceholder'),
  stateAbbreviation: '',
  zipCodeRangeStart: 10000,
  zipCodeRangeEnd: 99999
});

export const getDefaultTimezone = (t: TFunction) => ({
  id: 0,
  name: t('timezonePlaceholder'),
  abbreviation: '',
  standardOffset: 0,
  daylightOffset: 0
});

export const getVisibility = (t: TFunction) => [
  {
    isDefault: false,
    label: t('unlistedLbl'),
    value: t('unlistedLbl')
  },
  {
    isDefault: true,
    label: t('publicLbl'),
    value: t('publicLbl')
  }
];
