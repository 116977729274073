import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FC, useCallback, useMemo, useState } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import images from '../../assets';
import { IMAGE_SUPPORTED_FORMAT, PROFILE_ICON_MAX_SIZE } from '../../constants/images';
import { useAssetUpload } from '../../hooks/useAsset';
import { IAsset, ROLE_TYPE } from '../../types';
import MediaUpload from '../MediaUpload';

interface Props {
  entityId: number;
  entityType: ROLE_TYPE;
  profileIconUrl: string;
  imageUploadSuccessHandler: (imageUploadResponse: IAsset) => void;
  t: TFunction;
  className?: string;
}

export const ProfileImageUpload: FC<Props> = ({
  entityId,
  entityType,
  profileIconUrl,
  imageUploadSuccessHandler,
  t,
  className = ''
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { imageUpload, isUploadingImage } = useAssetUpload(t, true);
  const imageOnLoadHandler = () => {
    setIsImageLoading(false);
  };
  const onFileUpload = useCallback(
    (file: File[]) => {
      imageUpload(
        {
          file: file[0],
          entityId: entityId.toString(),
          entityType,
          isHeroShot: false
        },
        {
          onSuccess: (imageUploadResponse) => {
            const imageUploadResponseData = imageUploadResponse as { data: IAsset };
            imageUploadSuccessHandler(imageUploadResponseData.data);
          }
        }
      );
    },
    [imageUpload, imageUploadSuccessHandler]
  );

  const getImage = useMemo(() => {
    if (profileIconUrl) {
      return profileIconUrl;
    }

    return entityType === ROLE_TYPE.HOST ? images.avatarUpload : images.image;
  }, [profileIconUrl, entityType]);

  return (
    <div data-testid="profileIconPreview" className={classNames('w-27.5 ', className)}>
      {isUploadingImage ? (
        <div className="border w-27.5 h-27.5 border-solid rounded-full border-gray-900 flex items-center justify-center">
          <SpinnerCircularFixed
            size={40}
            thickness={100}
            speed={50}
            color="gray-900"
            data-testid="loader"
            secondaryColor="transparent"
          />
        </div>
      ) : (
        <div className="flex flex-col ">
          <img
            src={getImage}
            className="object-cover rounded-full w-27.5 h-27.5"
            onLoad={imageOnLoadHandler}
            alt={entityType + '-profile-avatar'}
          />
          <MediaUpload
            testid="profileIconUpload"
            acceptedFormat={IMAGE_SUPPORTED_FORMAT}
            maxSize={PROFILE_ICON_MAX_SIZE}
            onAcceptedFiles={onFileUpload}
            maxSizeError={t('maxSizeFileErrorMessage')}
            isButtonNeeded={false}
            containerStyle="border-none cursor-default flex flex-col items-start"
            imageIcon={images.profileUpload}
            imageStyle="w-8.5 h-8.5"
            imageButtonStyle="w-8.5 h-8.5 -mt-17.75 ml-20"
            isImageLoading={isImageLoading}
            setIsImageLoading={setIsImageLoading}
            errorClassName="-mt-10 pt-2 pb-4 min-w-60 w-full"
            isContainerClickable={false}
          />
        </div>
      )}
    </div>
  );
};
