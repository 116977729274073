import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack
} from 'agora-rtc-sdk-ng';
import { AgoraVideoProfile, DEFAULT_VIDEO_PROFILE } from '../constants/agora';

export default class AgoraRtcClass {
  private static instance: AgoraRtcClass;
  public rtcClient: IAgoraRTCClient | undefined;
  private localVideoTrack: ICameraVideoTrack | undefined;
  private localAudioTrack: IMicrophoneAudioTrack | undefined;
  private _activeCamera: MediaDeviceInfo | undefined;
  private _activeVideoProfile: AgoraVideoProfile = DEFAULT_VIDEO_PROFILE;
  private _activeMicrophone: MediaDeviceInfo | undefined;
  private _isMuted = false;

  get isMuted(): boolean {
    return this._isMuted;
  }

  set isMuted(value: boolean) {
    this._isMuted = value;
  }

  get activeCamera(): MediaDeviceInfo | undefined {
    return this._activeCamera;
  }

  set activeCamera(value: MediaDeviceInfo | undefined) {
    this._activeCamera = value;
  }

  get activeVideoProfile(): AgoraVideoProfile {
    return this._activeVideoProfile;
  }

  set activeVideoProfile(value: AgoraVideoProfile) {
    this._activeVideoProfile = value;
  }

  get activeMicrophone(): MediaDeviceInfo | undefined {
    return this._activeMicrophone;
  }

  set activeMicrophone(value: MediaDeviceInfo | undefined) {
    this._activeMicrophone = value;
  }

  public static getInstance(): AgoraRtcClass {
    if (!AgoraRtcClass.instance) {
      AgoraRtcClass.instance = new AgoraRtcClass();
      AgoraRtcClass.instance.rtcClient = AgoraRTC.createClient({ codec: 'vp8', mode: 'live' });
      AgoraRTC.setLogLevel(4);
    }
    return AgoraRtcClass.instance;
  }

  public async getCameraDevices(): Promise<MediaDeviceInfo[]> {
    return AgoraRTC.getCameras(false);
  }

  public async getMicrophoneDevices(): Promise<MediaDeviceInfo[]> {
    (async () => {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await navigator.mediaDevices.enumerateDevices();
    })();
    return AgoraRTC.getMicrophones(false);
  }

  public setLocalVideoTrack(localTrack: ICameraVideoTrack) {
    this.localVideoTrack = localTrack;
  }

  public setLocalAudioTrack(localTrack: IMicrophoneAudioTrack) {
    this.localAudioTrack = localTrack;
  }

  public getLocalVideoTrack() {
    return this.localVideoTrack;
  }

  public getLocalAudioTrack() {
    return this.localAudioTrack;
  }

  public async getCameraFeed({ value: encoderConfig }: AgoraVideoProfile, id?: string) {
    const track = await AgoraRTC.createCameraVideoTrack({ cameraId: id, encoderConfig });

    this.setLocalVideoTrack(track);
    return track;
  }

  public async getMicrophoneFeed(id?: string) {
    const track = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId: id });
    this.setLocalAudioTrack(track);
    return track;
  }

  public async clearLocalAudioTrack() {
    if (this.rtcClient?.uid && this.localAudioTrack) {
      this.localAudioTrack.stop();
      this.localAudioTrack.close();
      await this.rtcClient?.unpublish([this.localAudioTrack]);
      this.localAudioTrack = undefined;
    }
  }

  public async clearLocalVideoTrack() {
    if (this.rtcClient?.uid && this.localVideoTrack) {
      this.localVideoTrack.stop();
      this.localVideoTrack.close();
      await this.rtcClient?.unpublish([this.localVideoTrack]);
      this.localVideoTrack = undefined;
    }
  }

  public async leave(clearMute = true) {
    await this.clearLocalVideoTrack();
    await this.clearLocalAudioTrack();
    await this.rtcClient?.leave();
    if (clearMute) {
      this.isMuted = false;
    }
  }

  public async switchVideoProfile(profile: AgoraVideoProfile) {
    this.activeVideoProfile = profile;
    await this.getLocalVideoTrack()?.setEncoderConfiguration(profile.value);
  }

  public async switchCamera(device: MediaDeviceInfo) {
    this.activeCamera = device;
    await this.getLocalVideoTrack()?.setDevice(device.deviceId);
  }

  public async switchMicrophone(device: MediaDeviceInfo) {
    this.activeMicrophone = device;
    await this.getLocalAudioTrack()?.setDevice(device.deviceId);
  }

  public async setMuted(mute: boolean) {
    this._isMuted = mute;
    await this.getLocalAudioTrack()?.setMuted(mute);
  }
}
