import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API_ROUTES } from '../../constants/apiRoutes';
import { makeRequest } from '../../utils/axios';
import { Response } from '../../types/APIResponseType';

interface ICreateSuneifyAccount {
  email: string;
  password: string;
  confirmPassword: string;
  userChannel: string;
  roleType: string;
  firstName: string;
  lastName: string;
  dob: string;
  brandName: string;
  businessName: string;
}

const postAccount = async (
  accountFormData: Partial<ICreateSuneifyAccount>
): Promise<Response<any>> => {
  return await makeRequest({
    path: API_ROUTES.CREATE_ACCOUNT,
    method: 'POST',
    data: accountFormData
  });
};

export const useCreateSuneifyAccount = () => {
  const {
    mutateAsync: createSuneifyAccountAsync,
    isLoading: isDataUploading,
    isSuccess: isDataSuccessfullyUploaded
  } = useMutation({
    mutationFn: postAccount,
    onError: (error: unknown) => {
      console.error(error);
      toast.error((error as Error)?.message);
    },
    onSuccess: (res) => {
      toast.success('You’ve successfully created your account.');
    }
  });
  return {
    createSuneifyAccountAsync,
    isDataUploading,
    isDataSuccessfullyUploaded
  };
};
