import classnames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyRightAction } from '../../constants/common';
import Button from '../Button';

import Typography from '../Typography';

interface Props {
  className?: string;
  action?: (value: string) => void;
}
const CopyRight: FC<Props> = ({ className, action }) => {
  const { t } = useTranslation('home');
  return (
    <div className={classnames('mt-auto mb-5 justify-between flex flex-row gap-x-10', className)}>
      <Typography variant="p" size="xs">
        {t('trademarkLabel')}
      </Typography>
      <Button
        variant="noStyle"
        btnClick={() => action && action(CopyRightAction.Terms)}
        testId="terms-of-service-btn"
      >
        <Typography variant="p" size="xs" className="cursor-pointer">
          {t('termLabel')}
        </Typography>
      </Button>
    </div>
  );
};

export default CopyRight;
