import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '../../../constants/apiRoutes';
import { RouteConfig } from '../../../types/Route';
import { makeRequest } from '../../../utils/axios';
import { ITag } from '../../../types';
import useAppCommonHook from '../../../reduxStore/hooks/useAppCommonHook';
import { isEmpty } from 'lodash';

interface ITagResponse {
  data: { data: ITag[] };
}

export const useGetTags = () => {
  const { storeTagsDataIntoRedux } = useAppCommonHook();
  const config: RouteConfig = {
    path: API_ROUTES.GET_TAGS,
    method: 'GET'
  };

  const { data, isLoading, isError } = useQuery(['tags'], () => makeRequest<ITagResponse>(config), {
    cacheTime: 60 * 1000, // 60 seconds
    retry: 1,
    select: (response) => response.data.data,
    onSuccess: (data) => {
      if (!isEmpty(data)) {
        storeTagsDataIntoRedux(data);
      }
    }
  });

  return {
    data,
    isLoading,
    isError
  };
};
