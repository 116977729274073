import React from 'react';
import './index.css';

function PageNotFound(): JSX.Element {
  return (
    <div className="error-page-container">
      <p className="text-3.2xl text-black">Error 404</p>
      <p className="mt-2.5 text-2xl text-black">Page Not Found</p>
    </div>
  );
}

export default PageNotFound;
