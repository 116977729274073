import { Chip, ChipProps } from '@mui/joy';
import Close from '@mui/icons-material/Close';
import { colors } from '../../../constants/theme';
import { lighten } from '@mui/material/styles';

interface PillBoxProps {
  label: string;
  onClick?: () => void;
  onDelete?: () => void;
  sx?: any;
}

const PillBox = ({ label, onClick, onDelete, sx, ...props }: ChipProps & PillBoxProps) => {
  const backgroundColor = colors.dark;
  const isClickable = onClick || onDelete;
  let customColorStyles = {};
  if (!props.color) {
    customColorStyles = {
      backgroundColor: backgroundColor,
      '.MuiChip-action': {
        backgroundColor: backgroundColor,
        cursor: isClickable ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor: isClickable && lighten(backgroundColor, 0.2)
        }
      },
      color: 'white'
    };
  }

  return (
    <Chip
      data-testid={`pill-box-${label}`}
      onClick={
        isClickable
          ? () => {
              onClick && onClick();
              onDelete && onDelete();
            }
          : undefined
      }
      sx={{
        ...customColorStyles,
        ...sx
      }}
      endDecorator={
        !!onDelete && (
          <Close data-testid={`delete-${label}`} sx={{ color: 'white', fontSize: 16 }} />
        )
      }
      {...props}
    >
      {label}
    </Chip>
  );
};

export default PillBox;
